import React from 'react';

export function Gallery(){
    return  <div className="flex items-center gap-6 w-full">
        <div className="relative flex-1 translate-y-10">
            <img
            src="/assets/imgs/img4.jpg"
            alt=""
            className="aspect-[4/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
            />
        </div>
        <div className="flex-1 gap-6 flex flex-col">
            <div className="relative">
                <img
                src="/assets/imgs/img2.jpg"
                alt=""
                className="aspect-[4/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
            </div>
            <div className="relative">
                <img
                src="/assets/imgs/img3.jpg"
                alt=""
                className="aspect-[4/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
            </div>
        </div>
    </div>
}