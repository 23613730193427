import React from 'react';

// Components
import { FiCheck } from 'react-icons/fi';
import { NavLink, Link } from 'react-router-dom';
import { Button } from 'components/core/button';

// Contexts
import { TutorFormContext } from 'contexts/tutor-form';

// Hooks
import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation, useParams } from "react-router-dom";

// Utils
import classnames from 'classnames';
// import { useAuth } from 'hooks/useAuth';

export function Steps(){
  const {t} = useTranslation("tutor-form");
  const {value, updateForm} = useContext(TutorFormContext);
  const steps = useMemo(()=>[
    { name: t("nav.info.title"), to: "general-info", 
        completed: !(!value.name || !value.phone || !value.email || !value.street || !value.city || !value.phone), step:1 },
    { name: t("nav.preferences.title"), to: "preferences",
        completed: !(!value.subjectPreferences || !value.schoolPreferences || !value.studentInFrencizationClass || !value.studentWithTroubles) , step:2
    },
    { name: t("nav.review.title"), to: "review", 
    step:3},
  ], [value]);

  const location = useLocation();
  const params = useParams();
  const activeStep = useMemo(()=>matchRoutes(steps.map(d=>({path:d.to, step:d.step, name: d.name})), location, `/inscription-tuteur/${params.period}`), [location]);
  const previousStep = useMemo(()=>(activeStep && activeStep[0].route.step>1)? steps[activeStep[0].route.step-2]: null, [activeStep])
  const nextStep = useMemo(()=>(activeStep && activeStep[0].route.step<steps.length)? steps[activeStep[0].route.step]: null, [activeStep])
  // const {logout, loginWithRedirect, user} = useAuth();
  return <div className="py-4 lg:py-12 px-4 sm:px-6 lg:px-8 space-y-4 lg:space-y-12 border-b lg:border-0 flex flex-col h-full justify-between overflow-auto">
          <div className="flex items-center lg:block justify-between lg:space-y-3">
            <Link to={`/inscription-tuteur/${params.period}`} className="text-xl lg:text-2xl font-bold text-white ">
              <img src="/assets/logo_transparent.png" alt="logo" className="mx-auto h-auto max-h-8 md:max-h-14 max-w-full"/>
            </Link>
            <p  className="text-xl lg:text-2xl font-bold text-white text-right lg:text-left ">{t("steps.title", {period: params.period})}</p>
          </div>
          {activeStep&&
            <div>
              <div className="flex items-center justify-between space-x-3 pb-2">
                <label className="text-white text-sm font-medium ">{activeStep && activeStep[0].route.name}</label>
                <label className="text-white text-sm font-medium  ">{t("steps.progress", {step: activeStep && activeStep[0].route.step, total: steps.length})}</label>
              </div>
              <div className="overflow-hidden rounded-full bg-gray-300">
                <div className="h-2 rounded-full bg-orange-600 transition-[width] duration-500" style={{ width: `${100*(activeStep && activeStep[0].route.step)/steps.length}%` }} />
              </div>
              <div className="flex justify-between mt-3 lg:hidden">
                <Button size="sm" color="darkGray" to={previousStep? previousStep.to: null} disabled={!previousStep}>{t("previous")}</Button>
                <Button size="sm" color="darkGray"  to={nextStep? nextStep.to: null} disabled={!nextStep}>{t("next")}</Button>
              </div>
            </div>
          }
            {location && location.pathname==`/inscription-tuteur/${params.period}` && <Button block color="active" to="general-info" className={"mt-6"} size="xl">{t("start")}</Button>}

          <nav className="space-y-3 hidden lg:block h-full" aria-label="Progress">
            <label className="capitalize text-gray-300 text-sm font-medium ">{t("steps.nav")}</label>
            <ol role="list" className="">
              {steps.map((step, i) => (
                <li key={step.name} className={classnames(i<steps.length-1 && ""," pb-6  ", step.completed? " border-orange-500": " border-gray-400")}>
                    <NavLink to={step.to} className={({isActive})=>(isActive? "text-orange-500 ": step.completed? "text-gray-100": "text-gray-400")}>
                      {()=>
                        <span className="flex items-center ">
                          {
                            step.completed ? 
                              <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center ">
                                <FiCheck
                                  className="h-full w-full text-white mt-1 rounded-full p-0.5 bg-orange-600 group-hover:opacity-70"
                                  aria-hidden="true"
                                />
                              </span>:
                              <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center" aria-hidden="true">
                                <div className="h-5 w-5 rounded-full border-2 border-gray-400 bg-none absolute" />
                                <div className="h-2 w-2 rounded-full bg-gray-400 absolute" />
                              </div>
                          }
                          <span className={classnames("ml-3 font-medium")}>
                            {step.name}
                          </span>
                        </span>
                      }
                    </NavLink>
                </li>
              ))}
            </ol>

          </nav>
          <div className="hidden lg:block">
            {value && <Button className="mt-5" color="inlineLink" to={`/inscription-tuteur/${params.period}`} onClick={()=>updateForm({type: 'reset'})} >{t("reset")}</Button>}
            <br/>
            <Button to="/login" className="mt-5" color="inlineLink">{t("already-tutor")}</Button>
          </div>

        </div>
}