import React from "react";

// Components
import { DashboardLayout } from 'components/core/layouts/dashboard';
import { Outlet } from 'react-router-dom';
import { SEO } from 'components/core/seo';
import { UnseenConvContextProvider } from "contexts/unseen-conv";

// API
// Hooks

export function DashboardPage(){
  return (
    <UnseenConvContextProvider>
    <DashboardLayout contentClassName="pb-0">
      <SEO title={"home.title"} description={"home.description"} />
      <Outlet/>
    </DashboardLayout>
    </UnseenConvContextProvider>
  );
}

