import React from "react";

// Utils
import classNames from "classnames";
import { locale } from 'utils/locale';

// Icons
import { AiOutlineCalendar as CalendarIcon } from 'react-icons/ai';

const colors = {
    "orange": "text-orange-600 bg-orange-100 ring-orange-200",
    "gray": "text-gray-600 bg-gray-100 ring-gray-200",
    "green": "text-green-600 bg-green-100 ring-green-200",
    "blue": "text-blue-600 bg-blue-100 ring-blue-200",
    "red": "text-red-600 bg-red-100 ring-red-200",
    "yellow": "text-yellow-600 bg-yellow-100 ring-yellow-200",
    "purple": "text-purple-600 bg-purple-100 ring-purple-200",
}

export function PillEmpty({children, className, color = "gray", border}){
    return <div className={classNames("inline-flex -mt-1 items-center px-2 py-1 text-sm font-medium rounded-md", className, colors[color], border && "ring-1")}>
        {children}
    </div>
}

export function PillInterval({fromDate, toDate, excludeLastDay, ...props}){
    const lastDate = excludeLastDay && new Date(toDate - 1) || toDate;
    const sameMonth = fromDate.getMonth() === lastDate.getMonth() && fromDate.getFullYear() === lastDate.getFullYear();
    const formatedInterval = sameMonth ? locale.format("%d")(fromDate) + " - " + locale.format("%d %B %Y")(lastDate) : locale.format("%d %B %Y")(fromDate) + " - " + locale.format("%d %B %Y")(lastDate);
    return <Pill.Empty {...props}><CalendarIcon className='mr-2 text-lg'/>{formatedInterval}</Pill.Empty>
}

export const Pill = {
    Empty: PillEmpty,
    Interval: PillInterval
};