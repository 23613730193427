/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useAuth } from 'hooks/useAuth';
// API
import { loginAdmin } from 'api/auth';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error, Heading} from 'components/core/typo'
import { Navigate } from 'react-router-dom';
import { SEO } from 'components/core/seo';

export function AdminLoginPage(){
  const { t } = useTranslation(["auth"]);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const {setUser}= useAuth();
  const [result, {loading, error, execute}] = useAPI(loginAdmin, {email, password},
                                                  {onResult: (({user})=>{user? setUser(user): null}),
                                                  immediate: false});
  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (result){
    return <Navigate to="/" state={result}/>
  }

  return <CenterViewLayout>
            <SEO title={"admin-login.title"} description={"admin-login.description"} />
            <form className="space-y-6" onSubmit={onSubmit}>
            <img src="/assets/logo-HD.png" className="h-auto max-w-full mx-auto max-h-24"/>
            <Heading title={t('admin-login.title')} description={t("admin-login.description")} className={"max-w-xs"}/>
            <TextInput
                label={t("login.email.label")}
                autoComplete="email"
                type="email"
                required
                onChange={setEmail}/>
            <TextInput
                label={t("admin-login.code.label")}
                type="password"
                autoComplete="current-password"
                required
                hideEyeOnPassword={true}
                onChange={setPassword} />

            <Button disabled={!email || !password} color="active" block size="xl" loading={loading}  type="submit">
                {t("login.submit")}
            </Button>
            {error? <Error.Text {...error}/>: null}
            </form>
           
         </CenterViewLayout>
}
