import React from "react";

import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";

import { Link } from 'react-router-dom';

export function Welcome(){
    const { t } = useTranslation("common");
    const { user } = useAuth();
    return <div className="flex w-full px-3 md:px-6 md:justify-end">
        <Link to={"/"} className="md:hidden">
            <img src="/assets/logo-HD.png" className="h-6"/>
        </Link>
        {user && <Link to={"/settings/security"} className="hidden text-orange-500 md:block">{user.name} ({t(user.role)})</Link>}
    </div>
}

export function WelcomeNav(){

    const { t } = useTranslation("common");
    const { user } = useAuth();
    const initiales = user.name.split(" ").map(e=>e[0]).join("");
    return <div className="">
            <Link to={"/"} className="flex justify-left md:hidden">
                <img src="/assets/logo-HD.png" className="h-8 mb-2"/>
            </Link>
            {   user && <div className="bg-gray-200 rounded-md p-2 flex items-start mt-3 ">
                    <div className="flex-shrink-0 !h-8 !w-8 rounded-full bg-orange-500 mr-2 flex items-center justify-center">
                        {initiales? initiales: user.name[0]}
                    </div>
                    <div className="flex-1">
                        <div className="text-black">{user.email}</div>
                        <div className="text-sm text-gray-600">{t(user.role)}</div>
                    </div>
                </div>
            }
        </div>
}