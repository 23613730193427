import React, { useMemo } from 'react';

// Components
import { Container } from 'components/core/container';
import { Heading } from 'components/core/typo';
import { FacebookURLInput } from 'components/settings/facebook';

// Context
import { TutorContextProvider } from 'contexts/tutor';
// Hooks
import { useTranslation } from 'react-i18next';
import { AddressInput } from 'components/settings/address';
import { ContactInput } from 'components/settings/contact';

export function InfoPage(){
    const {t} = useTranslation("settings");
    const fields = useMemo(()=>({fields: ["ni", 
        "facebook_account", 
        "email",
        "phone",
        "subscribed",
        "street", "city", "zipcode"]}), []);
    return <TutorContextProvider fields={fields}>
                <Container className={"pt-6 pb-12"}>
                        <Heading title={t("info.title")} />
                        <FacebookURLInput/>
                        <AddressInput/>
                        <ContactInput/>
                    </Container>
            </TutorContextProvider>
}