import React from 'react';

// Utils
import classnames from 'classnames';
import { TextInput } from 'components/core/inputs';

// Components

// Hooks

export function OptionCell({title, description, Icon, selected}){
  return <div className={classnames("rounded- p-0 md:flex items-center ")}>
          <div className={classnames("flex-1")}>
            <div className={classnames('h-8 w-8  p-1 rounded-md', selected?"text-gray-800": "")}>
              <Icon className=""/>
            </div>
            <p className={classnames("text-gray-800 font-medium", selected? "": "")}>
              {title}
            </p>
            <p className='text-gray-500'>{description}</p>
          </div>
         </div>
}

export function SelectOptionsForm({ options, values, onClick, onChange }){
    return <div className="grid gap-3 gris-cols-1 lg:grid-cols-2 md:gap-3">
              {options.map(d=>
                {
                const selected = (values||[]).filter(e=>e.label===d.label).length>0;
                return (<div key={d.label}
                          className={classnames("rounded-md flex flex-col justify-between shadow-sm col-span-1 p-2 py-2.5",
                          selected? "ring-[2pt] ring-orange-500 bg-orange-50 ": "ring-1 ring-gray-300")}
                          >
                          <div className={classnames(selected?"text-white": "", " flex items-center cursor-pointer")} onClick={()=>onClick(d)}>
                            <div className="w-6 text-center " >
                              <input type="checkbox" className="text-orange-500 " id={d.label} name={"option"} checked={selected} readOnly />
                            </div>
                          <div className="flex-1 ml-3">
                            <OptionCell selected={selected} onChange={onChange} {...d}/>
                          </div>
                        </div>
                        {selected &&
                          <TextInput className={"mt-3"} multiLines onChange={comments=>onChange({...d, comments})} placeholder={d.placeholder}/>
                        }

                    </div>)})}
           </div>

  }

  export function SelectOptionsFormFullWidth({ options, values, onClick, onChange }){
    return <div className="grid gap-3 gris-cols-1">
              {options.map(d=>
                {
                const selected = (values||[]).filter(e=>e.label===d.label).length>0;
                const defaultValue = (values||[]).filter(e=>e.label===d.label)[0]?.comments;
                return (<div key={d.label}
                          className={classnames("rounded-md flex flex-col justify-between shadow-sm col-span-1 p-2 py-2.5",
                          selected? "ring-[2pt] ring-orange-500 bg-orange-50 ": "ring-1 ring-gray-300")}
                          >
                          <div className={classnames(selected?"text-white": "", " flex items-center cursor-pointer")} onClick={()=>onClick(d)}>
                            <div className="w-6 text-center " >
                              <input type="checkbox" className="text-orange-500 " id={d.label} name={"option"} checked={selected} readOnly />
                            </div>
                          <div className="flex-1 mb-2 ml-3">
                            <OptionCell selected={selected} onChange={onChange} {...d}/>
                          </div>
                        </div>
                        {selected &&
                          <TextInput defaultValue={defaultValue} className={"mt-3"} multiLines onChange={comments=>onChange({...d, comments})} placeholder={d.placeholder}/>
                        }

                    </div>)})}
           </div>

  }
