
import React, { useEffect } from "react";

// Components
import { Button } from 'components/core/button';
import { Error } from "components/core/typo";
import Markdown from 'markdown-to-jsx';
import { options } from 'mdx';

// API
import { signCodeEthique } from "api/tutor";

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from "hooks/useAPI";
import { CenterViewLayout } from "components/core/layouts/center";
import { useNavigate } from "react-router";

const markdown = 
`
# Code d'éthique du tuteur·rice bénévole

Dans le respect des valeurs de collaboration, d'équité, d'engagement et de fierté de l'organisme **Le Diplôme avant la Médaille (DAM)**, il est demandé aux tuteur∙rices bénévoles de:
- Traiter les élèves-athlètes soutenu∙es par DAM, les employé∙es de l’organisme, le personnel des écoles ainsi que les autres tuteur∙rices bénévoles **avec respect et considération.**
- Respecter les **règles inhérentes** au service de tutorat.
- Effectuer les tâches assignées efficacement et avec **bienveillance**.
- Respecter la **confidentialité des informations** en cohérence avec la politique de gestion des conditions de travail relatives aux élèves-athlètes qu’ils ou elles aident. Aucun renseignement de quelque nature que ce soit ne peut être divulgué en dehors des interactions avec le personnel de DAM.
- Faire preuve de **jugement dans leur approche** avec les élèves-athlètes **en classe**: 
  - Éviter d’accepter les invitations à devenir «ami∙es» sur les médias sociaux afin de maintenir une distance professionnelle. 
  - Maintenir une distance physique jugée nécessaire avec les élèves-athlètes.
  - Éviter d’être seul∙es dans un local fermé lors de la séance de tutorat.
- Faire preuve de **jugement dans leur approche** avec les élèves-athlètes **en ligne**: 
  - Éviter d’accepter les invitations à devenir «ami∙es» sur les médias sociaux afin de maintenir une distance professionnelle. 
  - Être préparé∙es et disposé∙es à effectuer la rencontre en ligne (outils nécessaires à proximité, endroit propre, calme et confortable, appareils branchés, etc.). 
- Exprimer au/à la coordonnateur∙rice des interventions attitré∙e leurs **satisfactions et insatisfactions** par rapport à leurs tâches et suggérer des améliorations ou des changements, au besoin.
- Aviser le/la coordonnateur∙rice des interventions attitré∙e aussitôt que possible s’ils ou elles sont dans **l’incapacité de remplir leurs fonctions** selon l’horaire fixé.
En tout temps, **respecter toutes les lois et réglementations applicables** à la société et aux citoyen∙nes de manière générale.

Dans le cas d'un manquement présumé ou confirmé, la direction générale est désignée responsable de l'application du code d'éthique. Le ou la tuteur∙rice bénévole à qui l'on reproche des manquements à l'éthique peut être relevé∙e provisoirement de ses fonctions.
`

export function EthicFormPage(){
  const {t} = useTranslation("tutor-form");
  const [result, {error, execute, loading}] = useAPI(signCodeEthique, {}, {immediate: false});
  const navigate = useNavigate();
  useEffect(()=>{
    if(result){
      navigate("/sign-required-documents");
    }
  }, [result]);

  return <CenterViewLayout size="3xl">
            <Markdown options={options}>{markdown}</Markdown>
             
                <div className=" p-3 mt-6 mb-3 border shadow-md rounded-md">
                  <div className="flex items-start pb-4">
                    <label htmlFor="accept-ethic-code" className="ml-2 block ">
                      En tant que futur·e tuteur·rice bénévole, je reconnais avoir pris connaissance du code d’éthique ci-dessus et de l’avoir compris. Je m’engage à le respecter pour toute la durée de mon implication bénévole.
                    </label>
                  </div>
                  <Button block 
                    color="black" 
                    loading={loading}
                    disabled={loading}
                    size="lg"
                    onClick={execute}
                  >{t("accept-ethic-code")}</Button>

                  {error && <Error.Text {...error}/>}
                </div>
         </CenterViewLayout>
}
