import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button';

export function Error404(){
  return <CenterViewLayout>
          <label className="text-orange-500 text-sm font-medium">{`Erreur 404`}</label>
          <h1 className=" text-3xl font-bold">{`Page non trouvée`}</h1>
          <p className="my-3">{`Désolé, la page que vous cherchez n'existe pas.`}</p>
          <Button color="default" size="lg" block to="/">{`Retour à l'accueil du portail`}</Button>
          <Button color="inlineLink" className="mt-4" href="https://diplomeavantlamedaille.org">{`Visiter le site de DAM`}</Button>
         </CenterViewLayout>
}