import React from 'react'

// Components
import { SelectMenu } from 'components/core/inputs'

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectQuestion({value, setValue, options, label, id, ...props}){
  const {t} = useTranslation("tutor-form");
  return <SelectMenu placeholder={t(`${id}.placeholder`)} 
                    indexKey="name" 
                    labelClassName="text-gray-800 text-base "
                    values={options || t(`${id}.options`)} 
                    value={value} 
                    setValue={setValue} 
                    label={label || t(`${id}.label`)}
                    {...props}/>
}