import React from 'react';

// Hooks
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';

// Components
import { SelectPairingForm } from 'components/select/select-pairing';
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';

// API
import { searchPairings } from 'api/pairings';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';

export function SelectPairing(){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);
    const [pairings, {loading, error}] = useSearch(searchPairings);

    useEffect(()=>{
        if (pairings && pairings.length===1) updateStudySession({type: 'pairing', value: pairings[0]});
    }, [pairings])

    return  <div>
                <Heading mediumTitle={t("select-pairing.title")}
                        description={t("select-pairing.description")}
                />
                {loading?
                <Skeleton className={"h-40"}/>:
                !pairings || pairings.length==0?
                    <EmptyState title={t("select-pairing.empty.title")} description={t("select-pairing.empty.description")}/>:
                    <SelectPairingForm value={studySession && studySession.pairing} setValue={(value)=>updateStudySession({type: 'pairing', value})} pairings={pairings}/>
                }
                {error? <Error.Text {...error}/>: null}
            </div>
}
