import classNames from 'classnames';
import { Skeleton } from 'components/core/skeleton';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { locale, parseWithTimeZone } from 'utils/locale';

function Message({content, seen, created_at, author}){

    const initials = author && author.firstname && author.lastname && `${author.firstname[0]}${author.lastname[0]}`;
    const {t} = useTranslation();
    if (!content){
        return null;
    }
    const isSystem = content.startsWith("<SYS>");
    const cleanContent = content.replace("<SYS>", "");
    const time = locale.format("%d %B à %H:%M")(parseWithTimeZone(new Date(created_at)));
    return <div className='flex items-end w-full gap-3'>
            {!isSystem &&<div className='rounded-full h-8 w-8 bg-orange-500 uppercase flex items-center justify-center text-white'>
                {initials}
            </div>}
            <div className='w-full'>
            {!isSystem && <p className='text-gray-500 text-sm'>{t("messages.from" ,{time, user: author && author.firstname})}</p>}
                <div className={classNames("p-3 rounded-xl transition-colors ease-in-out delay-150 w-full",
                        !seen?'bg-gray-200 text-black' :
                        isSystem? 'bg-white text-black' :
                        'bg-gray-100 ')}>
                    {isSystem && <p className='text-sm'>{time}</p>}
                    <p>{cleanContent}</p>

                </div>
        </div>
        </div>
}

export function MessageList({messages, loading}){
    const sortedMessages = useMemo(() => messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)), [messages]);
    if (loading){
        return <Skeleton className='h-64 w-full'/>
    }
    if (!messages || messages.length===0){
        return null;
    }
    return <div className='px-3 pb-8 pt-3'>
        <div className='space-y-1'>
        {sortedMessages.map((m) => <Message key={m.id} {...m}/>)}
        </div>
        
    </div>
}