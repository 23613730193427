import React from 'react';
import classnames from 'classnames';
import { useRef } from 'react';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function FlexNav({ children, className, breakdown="xl"}){

  return <div className={classnames(" xl:space-y-0 xl:flex h-full w-full", breakdown==="xl"? "xl:space-y-0 xl:flex xl:space-x-6": breakdown==="lg"? "lg:space-y-0 lg:flex lg:space-x-6": "md:space-y-0 md:flex md:space-x-6",  className)}>
          {children}
         </div>
}

export function FlexNavBar({ children, className, breakdown="xl" }){

  return <div className={classnames("w-full", breakdown==="xl"? "xl:max-w-xs": breakdown==="lg"? "lg:max-w-sm": "md:max-w-xs", className)}>
          {children}
         </div>
}

export function FlexNavContent({ children, className, breakdown="xl" }){

  return <div className={classnames("w-full", breakdown==="xl"? "xl:flex-auto": breakdown==="lg"? "lg:flex-auto": "md:flex-auto",  className)}>
          {children}
         </div>
}

FlexNav.Bar = FlexNavBar
FlexNav.Content = FlexNavContent

export function FullFlexNav({ children, className, breakdown="xl"}){

  return <div className={classnames(" xl:space-y-0 xl:flex h-full w-full", breakdown==="xl"? "xl:space-y-0 xl:flex xl:space-x-6": breakdown==="lg"? "lg:space-y-0 lg:flex lg:space-x-6": "md:space-y-0 md:flex md:space-x-6",  className)}>
          {children}
         </div>
}

export function FullFlexNavBar({ children, className, breakdown="xl" }){

  return <div className={classnames("w-full", breakdown==="xl"? "xl:max-w-xs": breakdown==="lg"? "lg:max-w-sm": breakdown==="none"? "":"md:max-w-xs", className)}>
          {children}
         </div>
}

export function FullFlexNavContent({ children, className, breakdown="xl", scrollOnChange }){
  const ref = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    if (!scrollOnChange) return;
    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return <div ref={ref} className={classnames("w-full", breakdown==="xl"? "xl:flex-auto": breakdown==="lg"? "lg:flex-auto": "md:flex-auto",  className)}>
          {children}
         </div>
}

FullFlexNav.Bar = FullFlexNavBar
FullFlexNav.Content = FullFlexNavContent