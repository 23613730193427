import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';

export function Paginate({page, setPage, numPageResults, numResults, totalPages}){
  const {t} = useTranslation("common");
  return <div className="flex items-center justify-between mt-3">
            <div className="hidden sm:block">
              <p className="text-sm text-gray-500">
                Page <span className="font-medium text-gray-600">{page}</span> de{' '}
                <span className="font-medium text-gray-600">{totalPages}</span> ({numPageResults} de {numResults} résultat{numResults>1? "s": ""})
              </p>
            </div>
            <div className="flex items-center justify-end space-x-3">
              <Button color="paginate" size="xs" onClick={()=>setPage(d=>Math.max(1, d-1))} disabled={page<=1}>{t("previous")}</Button>
              <Button color="paginate" size="xs" onClick={()=>setPage(d=>Math.min(d+1, totalPages))} disabled={page>=totalPages}>{t("next")}</Button>
            </div>
          </div>
}

export function PaginateCompact({page, setPage, numPageResults, numResults, totalPages}){
  const {t} = useTranslation("common");
  return <div className="flex items-center justify-between mt-3 py-1 bg-gray-100 border-y px-2">
            <div className="hidden sm:block">
              <p className="text-sm text-gray-500">
                Page <span className="font-medium text-gray-600">{page}</span> de{' '}
                <span className="font-medium text-gray-600">{totalPages}</span> ({numPageResults}/{numResults})
              </p>
            </div>
            <div className="flex items-center justify-end space-x-3">
              <Button color="paginate" size="xs" onClick={()=>setPage(d=>Math.max(1, d-1))} disabled={page<=1}>{t("previous")}</Button>
              <Button color="paginate" size="xs" onClick={()=>setPage(d=>Math.min(d+1, totalPages))} disabled={page>=totalPages}>{t("next")}</Button>
            </div>
          </div>
}

export function PaginateCard({page, setPage, limit, numPageResults, numResults, totalPages}){
  const {t} = useTranslation("common");
  return <div className="bg-gray-100 m-3 p-3 rounded-xl">
          <EmptyState noMargin description={t("too-many-results", {numPageResults, limit, numResults, totalPages})}/>
             
            <div className="flex items-center justify-around space-x-3">
              <Button color="paginate" size="xs" onClick={()=>setPage(d=>Math.max(1, d-1))} disabled={page<=1}>{t("previous")}</Button>
              <Button color="paginate" size="xs" onClick={()=>setPage(d=>Math.min(d+1, totalPages))} disabled={page>=totalPages}>{t("next")}</Button>
            </div>
          </div>
}