import React from "react";

// Hooks
import { useTranslation } from "react-i18next";
// import { useSearch } from "hooks/useSearch";
// import { useMemo } from "react";

// Components
import { Heading } from "components/core/typo";
import { email, phone } from "const/contact-infos";
// import { TextError } from "components/core/typo";
// import { Skeleton } from "components/core/skeleton";

// Icons
import { MdOutlineAlternateEmail as EmailIcon } from 'react-icons/md';
import { PhoneIcon } from '@heroicons/react/24/outline'

// API
// import { searchContacts } from "api/ressources";

export function Team() {
    const { t } = useTranslation("common");
    // const params = useMemo(()=>({}), []);
    // const [team, { error }] = useSearch(searchContacts, params);
    return (
        <div>
                <Heading title={t("contact.title")}  description={t("contact.responsable-description")}/>
        <div className="flex text-lg items-center my-2 text-orange-500 hover:underline"><EmailIcon className="mt-0.5 mr-2 text-xl" /><a href={"mailto:" + email}>{email}</a></div>
            <div className="flex text-lg items-center my-2 text-orange-500 hover:underline"><PhoneIcon className="mt-0.5 mr-2 text-xl h-5" /><a href={"tel:" + phone}>{phone}</a></div>

                {/* { !team ? <Skeleton className={"h-20"}/> : <TeamGrid team={team} />} */}
            {/* { error && <TextError className={"mt-4"} {...error}></TextError>} */}
        </div>
    )
}

export function TeamGrid({ team }) {
    const { t } = useTranslation("common");

    return <ul role="list" className=" pt-12 space-y-6  divide-gray-200">
                <Heading mediumTitle={t("contact.responsable")}/>
        {team.map(({name, description}) => (
            <li key={name} className="">
                <div className="">
                    <h3 className="text-lg font-semibold  tracking-tight text-gray-900">{name}</h3>
                    <p className="text-base text-gray-600">{description}</p>
                </div>
            </li>
        ))}
    </ul>
}