
import React from "react";

// Components
import { Button } from 'components/core/button';
import Markdown from 'markdown-to-jsx';
import { options } from 'mdx';

// Hooks
import { useTranslation } from 'react-i18next';
import { Gallery } from "components/tutor-form/imgs";

// Contexts

const markdown = 
`# Devenez tuteur·rice bénévole

Pour devenir tuteur·rice bénévole, veuillez répondre à ce court questionnaire afin que nous puissions effectuer un jumelage adéquat. Veuillez noter que l'implication minimale des tuteur·rices est d'octobre à avril et idéalement jusqu’à juin. Les plages horaires sont les suivantes:


|               | Midi              | Soir          | 
|---------------|-------------------|---------------|
| Vanier        | 11h30 à 12h30     | 15h45 à 20h00 |
| Camaradière   | 12h00 à 13h00     | 16h15 à 20h00 |
| Charlesbourg  | 12h15 à 13h15     | 16h30 à 20h00 |
| Roger-Comtois | 12h15 à 13h15     | 16h30 à 20h00 |
| Pointe-Lévy   |                   | 16h30 à 20h00 |

Veuillez noter que les nouveaux tuteurs doivent suivre une formation de 2 heures avec l'organisme. Nous vous contacterons une fois votre formulaire envoyé pour plus de détails.

Si vous avez des questions supplémentaires, voici quelques informations utiles qui pourront vous éclairer : 

- <a href="https://diplomeavantlamedaille.org/site/TuteursBenevoles" target="_blank" rel="noopener noreferrer">Information pour les tuteur·rices bénévoles</a>
- <a href="https://diplomeavantlamedaille.org/site/FaqTuteurs" target="_blank" rel="noopener noreferrer">FAQ</a>

Si ces informations ne sont pas suffisantes, n'hésitez pas à joindre un responsable des services à [interventions@diplomeavantlamedaille.org](mailto:interventions@diplomeavantlamedaille.org) ou en laissant un message au 418-431-0115. Il nous fera plaisir de répondre à vos questionnements!
`

export function InfoPage(){
  const {t} = useTranslation("tutor-form");
  return <div className="max-w-3xl px-4 ">
            <Gallery/>
            <Markdown options={options}>{markdown}</Markdown>
            <Button block color="black" to="general-info" className={"mt-6"} size="xl">{t("start")}</Button>
         </div>
}
