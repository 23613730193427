import React from 'react';

// Components
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Button } from 'components/core/button';

// Hooks
import { useMemo } from 'react';

// Utils
import classnames from 'classnames';
import { locale } from 'utils/locale';
import { timeDays, timeDay, timeTicks, timeHour, timeWeek, timeMonday} from 'd3-time';

const weekDayFormat = locale.format("%a");
const dayFormat = locale.format("%d");
const fullDayFormat = locale.format("%d %B %Y");
const hourFormat = locale.format("%H:%M");

export function CompactWeek({date, numTicks=8, events, onPreviousWeek, onNextWeek, onClick, abstract, minHour=6, maxHour=22}){
  const days = useMemo(()=>{
    return timeDays(timeMonday.floor(date), timeDay.offset(timeMonday.floor(date), 5))
  }, [date])
  const hours = useMemo(()=>{
    return days.map(d=>timeTicks(timeHour.offset(timeDay.floor(d), minHour), timeHour.offset(timeDay.floor(d), maxHour), numTicks));
  }, [days]);

  return <div className="flex flex-col h-full overflow-y-auto ">
          <div className="sticky top-0 flex flex-col bg-white ">
            <div className="sticky top-0 flex items-center justify-start flex-none px-3 bg-white ring-1 ring-black ring-opacity-5">
              {!abstract &&
                <div className="flex items-center">
                  {onPreviousWeek? <Button className="px-2 py-2 rounded-full hover:bg-gray-100 flex-0" color="none" layout="none" onClick={onPreviousWeek}><FiChevronLeft/></Button>:null}
                  <div className="py-2 text-lg text-left ">
                    {fullDayFormat(date)}
                  </div>
                  {onNextWeek? <Button className="px-2 py-2 rounded-full hover:bg-gray-100 flex-0" color="none" layout="none" onClick={onNextWeek}><FiChevronRight/></Button>:null}

                </div>
              }
            </div>
            <div style={{ width: '165%' }} className="flex flex-col flex-none max-w-full">
              <div className="sticky top-0 z-10 flex-none bg-gray-50 ring-1 ring-black ring-opacity-5 ">

                {/* Large week days*/}
                <div className="grid grid-cols-5 text-sm leading-6 text-gray-500 border-r border-gray-100 divide-x divide-gray-100">
                    <div className="col-end-1 w-14" />
                    {days.map((day,i)=>
                        <div className="flex items-center justify-center py-1" key={i}>
                        <span>
                          {weekDayFormat(day)} {!abstract&&<span className="items-center justify-center font-semibold text-gray-900">{dayFormat(day)}</span>}
                        </span>
                      </div>
                    )}
                    </div>
                </div>
              </div>
            </div>

            <div className="flex flex-auto bg-white">
              <div className="sticky left-0 z-10 flex-none w-14 ring-1 ring-gray-100" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1 ">
                  {/* Horizontal lines */}
                  <div
                    className="grid col-start-1 col-end-2 row-start-1 divide-y divide-gray-100 "
                    style={{ gridTemplateRows: `repeat(${hours[0].length}, minmax(3.5rem, 1fr)) auto` }}
                    >
                    {/*<div className="row-end-1 h-7"></div>*/}
                      {hours[0].map((d,i)=>
                          <div key={i}>
                            <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              {i>0?hourFormat(d):null}
                            </div>
                          </div>
                        )}
                  </div>

                  {/* Vertical lines */}
                  <div className="grid grid-cols-5 col-start-1 col-end-2 grid-rows-1 row-start-1 divide-x divide-gray-100">
                    <div className="col-start-1 row-span-full" />
                    <div className="col-start-2 row-span-full" />
                    <div className="col-start-3 row-span-full" />
                    <div className="col-start-4 row-span-full" />
                    <div className="col-start-5 row-span-full" />
                    <div className="col-start-6 row-span-full" />
                    <div className="col-start-7 row-span-full" />
                    {/*<div className="w-8 col-start-8 row-span-full" />*/}
                  </div>
                  <ol
                    className="grid grid-cols-5 col-start-1 col-end-2 row-start-1 gap-0"
                    style={{ gridTemplateRows: `repeat(${12*(hours[0].length)-1}, minmax(0, 1fr)) auto` }}
                  > 
                   {events&& events.map((d,i)=>
                    
                      (fullDayFormat(d.date)===fullDayFormat(timeDay.offset(timeWeek.floor(date), d.date.getDay()))) && <li key={i} className={`relative flex`} 
                          style={{ gridColumnStart: d.date.getDay()-1 , 
                                  gridRow: `${d.date.getHours()*12 + 1+parseInt(d.date.getMinutes()/5) - (hours[0][0].getHours())*12} / span ${parseInt(d.duration/5)}` }}>
                      <div
                        onClick={onClick? (()=>onClick(d)): null}
                        href={d.href}
                        className={classnames("group absolute inset-1 flex flex-col overflow-y-auto rounded-md ", 
                              d.className? d.className: "bg-orange-600 hover:bg-orange-500 hover:cursor-pointer"
                                )}
                      >
                      </div>
                    </li>
                    )}

                    {/**/}
                   
                  </ol>
                </div>
                </div>
        </div>
}
