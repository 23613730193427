import React, { useRef } from 'react';

// Utils
import classnames from "classnames";

// Components
import { FiAlertCircle, FiSearch, FiEye, FiEyeOff, FiCheck } from "react-icons/fi";
import { Spinner } from 'components/core/spinner';
import { Button } from 'components/core/button';

// Hooks
import { useFocus } from 'hooks/useFocus';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'hooks/useDebounce';

const sizes = {
  sm: 'px-2 py-1.5 w-full',
  md: 'px-2 py-2 lg:py-3 w-full',
  empty: 'px-0 py-2 lg:py-3',
}

const colors = {
  default: 'border border-gray-300 text-warm-gray-900 placeholder-gray-300 shadow-sm rounded-md outline-none ring-0 focus:ring-2 focus:ring-orange-500 focus:border-orange-500',
  none: 'bg-white focus:ring-0 focus:outline-none border-0 placeholder-gray-300',
  transparent: 'bg-none focus:ring-0 focus:outline-none border-0 placeholder-gray-300',
}

export function TextInput({label,
                          description,
                          optional,
                          error,
                          success,
                          warning,
                          useLeftIcon,
                          loading,
                          hideEyeOnPassword,
                          search,
                          className,
                          labelClassName,
                          required,
                          multiLines,
                          size="md",
                          type="text",
                          color="default",
                          errorOnBlur,
                          warningOnBlur,
                          reformatOnBlur,
                          defaultValue="",
                          onChange,
                          shouldClear,
                          ...props}){
  const {t} = useTranslation("common");
  const [value, setValue] = useState(defaultValue);
  const [asPassword, setAsPassword] = useState(type==="password");
  const [ref, onFocus] = useFocus();

  useEffect(()=>{
    if (shouldClear) setValue("");
  }, [shouldClear]);

  const _error = useMemo(()=>{
    if (errorOnBlur && !onFocus) return errorOnBlur(value);
  }, [value, onFocus]);

  const _warning = useMemo(()=>{
    if (warningOnBlur && !onFocus) return warningOnBlur(value);
  }, [value, onFocus]);

  const handleChange = useCallback((e)=>{
    setValue(e.currentTarget.value);
    onChange(e.currentTarget.value);
  }, [])

  return (<div className={className} >
            <div className="flex ">
              {label?
                <label htmlFor="title" className={classnames("block text-sm font-medium text-gray-700", labelClassName)}>
                  {label}
                </label>:null}
              {optional?
                <span className="ml-auto text-sm text-gray-500">
                  {t("optional")}
                </span>: null}
            </div>
            <div className="flex items-center w-full max-w-full mt-0">
              {useLeftIcon?
                <div className="flex-shrink-0 w-6">
                  {_error || error? <FiAlertCircle className="text-red-600"/>:
                    success? <FiCheck  className="text-green-500"/>:
                  <FiCheck/>}
                </div>: null
              }
              {multiLines?
              <textarea
                ref={ref}
                required={required}
                onChange={handleChange}
                value={!onFocus && reformatOnBlur? reformatOnBlur(value): value}
                type={!hideEyeOnPassword&&type=="password"&& !asPassword? "text": type}
                {...props}
                className={classnames("flex-1",
                            sizes[size],
                            colors[color],
                            _error || error? "border-red-600 text-red-600 placeholder-red-300": "")}
              />:
              <input
                ref={ref}
                required={required}
                onChange={handleChange}
                value={!onFocus && reformatOnBlur? reformatOnBlur(value): value}
                type={!hideEyeOnPassword&&type=="password"&& !asPassword? "text": type}
                {...props}
                className={classnames("flex-1",
                            sizes[size],
                            colors[color],
                            _error || error? "border-red-600 text-red-600 text placeholder-red-300": "")}
              />}
              {!hideEyeOnPassword && type==="password"?
                <div className="flex-shrink-0 ml-2">
                  <Button color={!asPassword?"default":"inactive"}
                    size="toolbarIcon"
                    onClick={(e)=>{e.preventDefault(); setAsPassword(!asPassword)}}>{asPassword? <FiEye/>: <FiEyeOff/>}</Button>
                </div>
                :null}
              {loading || search  && (!useLeftIcon )?
                <div className={classnames('px-2 flex space-x-2 items-center ')}>
                  {loading?
                    <Spinner className='my-auto ml-auto text-gray-500'/>:
                    search? <FiSearch className='my-auto ml-auto text-gray-500'/>: null}

                  { !useLeftIcon && (_error || error) ?
                  <div className="flex items-center pointer-events-none ">
                    <FiAlertCircle className="w-5 h-5 text-red-500" aria-hidden="true" />
                  </div>: null
                  }
                </div>:null}
            </div>
            {_error || error?
            <p className="mt-2 text-red-600 text-sm">
              {_error || error}
            </p>: null}
            {_warning?
            <p className="mt-2 text-yellow-600 text-sm">
              {_warning}
            </p>: null}
            {description?
            <p className="mt-2 text-sm text-gray-500">
              {description}
            </p>: null}
            {success?
            <p className="mt-2 text-base text-green-500">
              {success}
            </p>: null}
            {warning?
            <p className="mt-2 text-base text-yellow-600">
              {warning}
            </p>: null}
          </div>)
}


export function TextInputDebounce({delay, onChange, ...props}){
  const [value, setValue] = useState(props.defaultValue || "");
  const initial = useRef(0);
  const debouncedValue = useDebounce(value, delay);
  useEffect(()=>{
    if (initial.current===0){
      initial.current = 1;
      return;
    }
    onChange(debouncedValue);
  }, [debouncedValue]);

  return <TextInput {...props} defaultValue={value} onChange={setValue}/>
}