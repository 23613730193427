import React, { useState, useMemo } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';

// API
import { searchStudents } from 'api/students';

// Components
import { Button } from 'components/core/button';
import { MagnifyingGlassIcon, InformationCircleIcon, XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react';
import { Error } from 'components/core/typo';
import { Spinner } from 'components/core/spinner';

export function SearchStudentsCommand({ values, onClick }) {
    const { t } = useTranslation("common");
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");
    const debounceQuery = useDebounce(query, 500);

    const params = useMemo(() => ({ search: query }), [debounceQuery]);
    const [students, { loading, error }] = useSearch(searchStudents, params, { limit: 10 });

    return <>
        <Button layout="lgBlock" size="md" color="gray" onClick={() => setOpen(d => !d)}><MagnifyingGlassIcon className='w-4 h-4 mr-2 text-gray-500' />{t("search-students.label")}</Button>
        <Transition.Root show={open} as={Fragment} appear>
            <Dialog as="div" className="relative z-20" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-20 p-4 overflow-y-auto sm:p-6">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="max-w-xl mx-auto transition-all transform bg-white divide-y divide-gray-100 shadow-2xl md:my-[10vh] rounded-xl ring-1 ring-black ring-opacity-5">
                            <Combobox value={values} onChange={onClick} multiple by={(a,b)=>a.ni===b.ni}>
                                <div className="relative">
                                    {loading ?
                                        <Spinner className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" /> :
                                        <MagnifyingGlassIcon
                                            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />}
                                    <Combobox.Input
                                        className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                        placeholder={t("search-students.placeholder")}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                </div>

                                {error ? <Error.Text {...error} /> : null}

                                <Combobox.Options static className="text-sm text-gray-800 ">
                                    <div className="p-2 overflow-y-auto max-h-72 scroll-py-2">
                                        {query !== '' && (students || []).length === 0 && (
                                            <p className="p-4 text-sm text-gray-500">{t("search-students.no-results")}</p>
                                        )}
                                        {(students || []).map((student) => (
                                            <Combobox.Option
                                                key={student.ni}
                                                value={student}
                                                className={({ active, selected }) =>
                                                    classnames('cursor-default  rounded-md select-none px-4 py-2', active && 'bg-orange-600  text-white', selected && '')
                                                }
                                            >
                                                {({ active, selected }) => (
                                                    <div className="flex items-center">
                                                        {selected ? <CheckIcon className={classnames('w-5 h-5 mr-2', active?'text-white' :'text-orange-500')} /> :
                                                            <div className='w-5 h-5 mr-2' />}
                                                        <div>
                                                            <span className={classnames(selected && "font-semibold")}>{student.name}</span>
                                                            <span className={classnames('opacity-80 ml-2')}>{student.notebook_number}</span>
                                                            <p className={classnames(!active&& 'opacity-80')}>{(student.teams || []).map(d=>(d || {}).team.name).join(', ')}</p>
                                                        </div>
                                                    </div>
                                                )}


                                            </Combobox.Option>
                                        ))}
                                    </div>
                                    <div className='mr-1'>
                                        {(values || []).map((student) => (
                                            <Combobox.Option
                                                key={`selected-${student.ni}`}
                                                value={student}
                                                className={() =>
                                                    classnames('group cursor-pointer bg-gray-200 hover:bg-gray-100 mb-2 ml-2  inline-flex rounded-md select-none px-3 py-1.5')
                                                }
                                            >
                                                <span className='flex items-center '>
                                                    {student.name}
                                                    <XMarkIcon className='w-4 h-4 ml-2 text-gray-500 group-hover:text-orange-500' />
                                                </span>
                                            </Combobox.Option>
                                        ))}
                                    </div>
                                </Combobox.Options>




                                <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700 rounded-b-2xl">
                                    <span className='inline-flex'><InformationCircleIcon className='inline-flex w-5 h-5 mr-2' />{t("search-students.help")}</span>
                                </div>
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}