import React from "react";

// Components
import { SEO } from 'components/core/seo';
import { FAQ } from "components/faq";

// Hooks


export function FAQPage(){
    
    return <div className="px-6">
                <SEO title={"faq.title"} description={"faq.description"} />
                <FAQ />
            </div>
}