import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function ExtraQuestionContainer({children, className}){
    const {t} = useTranslation("tutor-form");
    return <div className={classNames("bg-gray-50 shadow border ml-5 mb-8 sm:rounded-lg p-3 space-y-3 transition-transform", className)}>
                <p className='text-sm text-gray-500'>{t("optional-personal-section")}</p>
                {children}
            </div>
}