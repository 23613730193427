import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonFR from 'i18n/locales/fr/common.json';
import authFR from 'i18n/locales/fr/auth.json';
import errorFR from 'i18n/locales/fr/errors.json';
import callbackFR from 'i18n/locales/fr/callback.json';
import settingFR from 'i18n/locales/fr/settings.json';
import tutorFormFR from 'i18n/locales/fr/tutor-form.json';
import addStudySessionFR from 'i18n/locales/fr/add-study-session.json';
import seo from 'i18n/locales/fr/seo.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    common: commonFR,
    auth: authFR,
    errors: errorFR,
    callback: callbackFR,
    settings: settingFR,
    "tutor-form": tutorFormFR,
    "add-study-session": addStudySessionFR,
    seo: seo
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['common', 'auth', 'errors', 'callback', 'settings', 'tutor-form', 'seo'],
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    keySeparator: ".",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    returnObjects: true
  });

  export default i18n;