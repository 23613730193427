import React from 'react';

// Components
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Button } from 'components/core/button';

// Hooks
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';
import { locale } from 'utils/locale';
import { timeMonth, timeDays, timeDay, timeWeek, timeWeeks } from 'd3-time';
import { group } from 'd3-array';

const dayFormat = locale.format("%d");
const fullDayFormat = locale.format("%d-%m-%Y");
const monthCalendarFormat = locale.format("%Y");

export function CompactCalendarMonth({date, events, interval, onPreviousMonth, onNextMonth, minDateSelection, maxDateSelection, sparkline=false, onClick, className}){
  const { t } = useTranslation("common");
  const weekDays = [t("short-week.sunday"), 
                    t("short-week.monday"), 
                    t("short-week.tuesday"), 
                    t("short-week.wednesday"), 
                    t("short-week.thursday"), 
                    t("short-week.friday"), 
                    t("short-week.saturday")];

  const days = useMemo(()=>{
    const d1 = timeWeek.offset(timeMonth.floor(date),-1);
    const d2 = timeMonth.ceil(timeWeek.offset(date,1));
    const weeks = timeWeeks(d1, d2);
    return weeks.map(w=>timeDays(w, timeWeek.offset(w, 1)));
  }, [date])

  const groupedEvents = useMemo(()=>{
    const groupedEvents =  group(events || [], d=>fullDayFormat(d.date));
    return groupedEvents
  }, [events])

  return <div className={classnames("", className)}>
            {!sparkline&&<div className="flex items-center">
              {onPreviousMonth? <Button className="px-2 py-2 hover:bg-gray-100 rounded-full" color="none" layout="none" onClick={onPreviousMonth}><FiChevronLeft/></Button>:null}
              <div className={classnames("flex-1 text-center capitalize font-semibold", sparkline? "text-base": "text-lg")}>
                {t(`month.${timeMonth.floor(date).getMonth()}`)} {monthCalendarFormat(timeMonth.floor(date))}
              </div>
              {onNextMonth? <Button className="px-2 py-2 hover:bg-gray-100 rounded-full" color="none" layout="none" onClick={onNextMonth}><FiChevronRight/></Button>:null}
            </div>}
            <div className={classnames("isolate grid grid-cols-7 text-sm", sparkline? "mt-0": "mt-4")}>
              {weekDays.map((weekDay,i)=><div className="font-normal uppercase text-xs text-center text-gray-500" key={i}>{weekDay}</div>)}
            </div>
            <div className="isolate mt-2 grid grid-cols-7 rounded-lg gap-px bg-gray-200 shadow  text-sm ring-1 ring-gray-200 ">
            {days.map((week,i)=>
                week.map((day,j)=>
                  <div 
                    className={classnames("focus:z-10 relative",
                            events && "",
                            (i===0 &&j===0)? "rounded-tl-lg":"",
                            (i===0 &&j===week.length-1)? "rounded-tr-lg":"",
                            (i===days.length-1 &&j===0)? "rounded-bl-lg ":"",
                            (i===days.length-1 &&j===week.length-1)? "rounded-br-lg":"",

                              (interval && interval.fromDate && (!interval.toDate? (day===interval.fromDate) : (day>=interval.fromDate && day<=interval.toDate))? 
                                (interval.light? "bg-gray-100 hover:bg-gray-200": "bg-orange-500 text-white font-medium hover:bg-orange-600"):
                                timeMonth.floor(date).getMonth()===timeMonth.floor(day).getMonth()? 
                                "bg-white hover:bg-gray-100"
                              : "bg-gray-50 text-gray-400 hover:bg-gray-100 "),
                            onClick? ((minDateSelection && day<=minDateSelection) || (maxDateSelection && day>=maxDateSelection)?"cursor-not-allowed ":"cursor-pointer"):""

                      )} 
                      onClick={(onClick && (!minDateSelection || day>minDateSelection) &&  (!maxDateSelection || day<maxDateSelection))? (()=>onClick(day)): null}
                    key={`${i}-${j}`}>
                     <div className={classnames("mx-auto flex items-center justify-center rounded-full",
                              sparkline? "w-2 text-xxs": "w-full aspect-square",
                              !sparkline && fullDayFormat(new Date())===fullDayFormat(timeDay.floor(day))? 
                              ((interval && interval.fromDate && (day>=interval.fromDate && day<=interval.toDate))? "bg-orange-600 text-white font-bold": "bg-gray-200 text-gray-700 font-bold")
                              :"",
                        )}
                      >
                        {!sparkline && dayFormat(day)}
                      </div>
                     <Fragment>
                      {!groupedEvents.get(fullDayFormat(timeDay.floor(day)))? null:  
                        groupedEvents.get(fullDayFormat(timeDay.floor(day))).length<=4?
                          <div className="flex justify-evenly bg-transparent absolute bottom-0 left-0 right-0 mb-1 items-center bg-white " style={{gridColumnStart: 2, gridRow: `${2} / span 1`}}> 
                            {groupedEvents.get(fullDayFormat(timeDay.floor(day))).map((e,j)=>{
                              var Badge = <span key={j} style={{background: e.color || "black"}} className={classnames("h-2 w-full mx-0.5 rounded-full relative z-50", e.className)}/>;
                             
                              return Badge;
                            })}
                          </div>:
                          <div className="flex justify-evenly bg-transparent absolute bottom-0 left-0 right-0 mb-0 items-center bg-white " style={{gridColumnStart: 2, gridRow: `${2} / span 1`}}> 
                              <span className="text-xs relative px-2 text-white rounded-md py-0.25" style={{background: groupedEvents.get(fullDayFormat(timeDay.floor(day)))[0].color || "black"}} >{groupedEvents.get(fullDayFormat(timeDay.floor(day))).length}+</span>
                          </div>
                      }
                      </Fragment>
                  </div>
                )
            )}
             
            </div>

        </div>
}
