import React, { createContext, useMemo } from "react";

// Hooks
import { useAPI } from "hooks/useAPI";

// API
import { getEvent } from "api/events";
import { useParams } from "react-router-dom";

const EventContext = createContext({});

const EventContextProvider = ({ children }) => {
  const {id} = useParams();
  const params = useMemo(() => ({id}), [id]);
  const [event, props] = useAPI(getEvent, params, {validateParams: (p) => !!p.id});

  return (
    <EventContext.Provider value={{ event, ...props }}>
      {children}
    </EventContext.Provider>
  );
};

export { EventContext, EventContextProvider };