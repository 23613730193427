import { fetchCall } from 'api';

/*
  Search all ressources contacts
*/  
export function searchContacts({search, page, limit, orderBy, orderDirection}){
    const options = {
        method: "GET", 
        endpoint: "/portal/ressources/contacts",
        query: {search, page, limit, orderBy, orderDirection}
    }
    return fetchCall(options)
}

/*
  Search all ressources links
*/  
export function searchLinks({search, page, limit, orderBy, orderDirection}){
    const options = {
        method: "GET", 
        endpoint: "/portal/ressources/links",
        query: {search, page, limit, orderBy, orderDirection}
    }
    return fetchCall(options)
}

/*
  Search all ressources faqs
*/  
export function searchFaqs({search, page, limit, orderBy, orderDirection}){
    const options = {
        method: "GET", 
        endpoint: "/portal/ressources/faqs",
        query: {search, page, limit, orderBy, orderDirection}
    }
    return fetchCall(options)
}