import React from "react";

// Hooks
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useSearch } from "hooks/useSearch";

// Components
import { Heading } from "components/core/typo";
import { email } from "const/contact-infos";
import { Skeleton } from "components/core/skeleton";
import { TextError } from "components/core/typo";
import Markdown from 'markdown-to-jsx';

// API
import { searchFaqs } from "api/ressources";
import { EmptyState } from "components/core/empty";

export function FAQ() {
    const { t } = useTranslation("common");
    const params = useMemo(()=>({}), []);
    const [faqs, { error }] = useSearch(searchFaqs, params);
        
    return (
        <div>
            <Heading title={t("faq.description")} 
                        description={<div>{t("faq.not-satisfied")}<a href={"mailto:"+email} className="text-orange-500 hover:cursor-pointer hover:underline">{t("faq.not-satisfied-by-email")}</a></div>}/>
                
                <div className="mt-10">
                    { error && <TextError className={"mb-4"} {...error}></TextError>}
                    { !faqs ? <Skeleton className={"h-20"}/> : <FAQGrid questions={faqs} />}
                </div>
            
        </div>
    )
}

export function FAQGrid({questions}) {
    const { t } = useTranslation("common");
    if (questions.length===0){
        return <EmptyState title={t("no-faq.title")} description={t("no-faq.description")}/>
    }
    return <div className="grid max-w-2xl gap-y-6 mb-8">
        {questions.map(({slug, name, content}, index) => (
            <div id={slug} key={index} className="col-span-2 lg:col-span-1">
                <div className="text-xl mb-2 font-medium leading-6 text-gray-900">
                    {name}
                </div>
                <div className="text-gray-600 whitespace-pre-line">
                    <Markdown>{content}</Markdown>
                </div>
            </div>
        ))}

    </div>

}