import React from 'react';

// Hooks
import { useState,useEffect, useMemo } from 'react';
import { useTranslationRegularIntervalCrons } from 'hooks/crons'
import { useTranslation } from 'react-i18next'; 
import { SelectMenu } from 'components/core/inputs'

// Components
import { Button } from 'components/core/button';
import { Dropdown } from 'components/core/dropdown';
import { Error } from 'components/core/typo'

// Utils
// import { format } from 'd3-format';
const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"].map((name, weekday)=>({name, weekday:1+weekday}));

function SelectCronIntervalContent({setValue}){
  const {t} = useTranslation("common");
  const [expandedValue, setExpandedVal] = useState({fromHours: "12", fromMinutes: "00", toHours: "13", toMinutes: "00", });

  useEffect(()=>{
    if ((expandedValue.weekday==null)
        ||(expandedValue.fromHours==null)
        ||(expandedValue.toHours==null)
        ||(expandedValue.fromMinutes==null)
        ||(expandedValue.toMinutes==null)
        ){
      // setValue();
      return;
    }
    const d = expandedValue;
    setValue({from_cron: `${d.fromMinutes} ${d.fromHours} * * ${d.weekday}`, to_cron: `${d.toMinutes} ${d.toHours} * * ${d.weekday}` })
  }, [expandedValue])

  return <div className="w-screen max-w-xs mx-auto space-y-3">
          <div className="flex items-center justify-evenly space-x-2">
            <SelectMenu 
                    labelClassName="text-gray-800 text-base "
                    className=" w-full"
                    values={days} 
                    indexKey={"name"}
                    value={expandedValue?days[expandedValue.weekday-1]: null} 
                    placeholder={t("interval.placeholder")}
                    setValue={({weekday})=>setExpandedVal(d=>({...d, weekday}))} />
          </div>
          <div className="flex items-center p-3 bg-gray-100 rounded-lg justify-between">
            <input className="input-time" 
                  type="time" 
                  id="startAt"
                  name="startAt" 
                  min="06:00" 
                  max="22:00" 
                  defaultValue={"12:00"}
                  required
                  onChange={(e)=>setExpandedVal(d=>({...d, fromMinutes: (e.target? e.target.value : "00").split(":")[1]  || 0, fromHours: (e.target? e.target.value : "00").split(":")[0]}))} />
            <label className="px-3">{t("to")}</label>
            <input className="input-time" 
                  type="time" 
                  id="endAt"
                  name="endAt" 
                  min="06:00" 
                  max="22:00" 
                  defaultValue={"13:00"}
                  required
                  onChange={(e)=>setExpandedVal(d=>({...d, toMinutes: (e.target? e.target.value : "00").split(":")[1]  || 0, toHours: (e.target? e.target.value : "00").split(":")[0]}))} />
          </div>
      
         </div>
}

export function SelectCronInterval({value, setValue}){
  const label = useTranslationRegularIntervalCrons(value);

  return <Dropdown label={label.baseStr}>
          <div className="p-3">
            <SelectCronIntervalContent value={value} setValue={setValue}/>
          </div>
         </Dropdown>
}

export function SelectCronIntervalWithConfirm({onConfirmation, validation}){
  const [value, setValue] = useState()

  const isInverse = useMemo(()=>{
    if (!value) return false;
    const b = value.from_cron.split(" ");
    const c = value.to_cron.split(" ");
    if ((parseInt(b[1])*60+ parseInt(b[0]))>(parseInt(c[1])*60+ parseInt(c[0]))) return true;
    return false;
  }, [value]);

  const {t} = useTranslation("common");
  const label = useTranslationRegularIntervalCrons(value?[value]: value);
  const error = useMemo(()=>validation? validation(value): null, [value])

  return <Dropdown orientation="left" label={t("interval.add-btn")}>
          <div className="p-3">
            <SelectCronIntervalContent value={value} setValue={setValue}/>
            {isInverse && <Error.Text className="mt-2" code={"inverse-interval-error"}/>}
            {error && <Error.Text className="mt-2" {...error}/>}
            <Button 
              className="mt-2"
                 block 
                 size="md" 
                 disabled={!value || isInverse || error}
                 color="black" onClick={()=>{onConfirmation(value);}}>
                 {value? `${t("interval.add", {interval:label[0].baseStr})}`: t("interval.not-selected") } 
                 </Button>
          </div>
         </Dropdown>
}