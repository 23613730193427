import React from 'react';
import { SideBarLayout } from 'components/core/layouts/sidebar';
import { Nav } from 'components/core/nav';
import { Welcome } from 'components/widgets/welcome';

export function SideDashboardLayout({ children, contentClassName }){
  return <div className="h-screen overflow-hidden">
          <SideBarLayout.Main>
              <SideBarLayout.Nav>
                <Nav/>
              </SideBarLayout.Nav>
            <SideBarLayout.TopBar>
              <Welcome/>
              <SideBarLayout.MobileNav>
                <Nav/>
              </SideBarLayout.MobileNav>
            </SideBarLayout.TopBar>
            <SideBarLayout.Content className={contentClassName}>
            {children}
            </SideBarLayout.Content>
          </SideBarLayout.Main>
         </div>
}

export const DashboardLayout = SideDashboardLayout;