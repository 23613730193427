import React, { useReducer, useMemo, createContext, useEffect } from "react";
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';

function filterReducer(state, {type, value}){
  switch (type){
    case 'subjectPreferences': 
      var subjectPreferences = state.subjectPreferences || {};
      subjectPreferences[value.slug] = value;
      return {...state, subjectPreferences}
    case 'schoolPreferences': 
      var schoolPreferences = state.schoolPreferences || {};
      schoolPreferences[value.slug] = value;
      return {...state, schoolPreferences}
    case 'tshirtSize':
      var tshirtColor = state.tshirtColor;
      if (value && value.slug==="no-tshirt"){
        tshirtColor = null;
      }
      return {...state, tshirtSize: value, tshirtColor}
    case 'howHaveYouHeard':
      return {...state, howHaveYouHeard: value, subHowHaveYouHeard: null, subSubHowHaveYouHeard: null, subSubSubHowHaveYouHeard: null}
    case 'subHowHaveYouHeard':
      return {...state, subHowHaveYouHeard: value, subSubHowHaveYouHeard: null, subSubSubHowHaveYouHeard: null}
    case 'subSubHowHaveYouHeard':
      return {...state, subSubHowHaveYouHeard: value, subSubSubHowHaveYouHeard: null}
    case 'reset':
      return {};
    default:
      var d = {...state};
      d[type] = value;
      return d
  }
}

function updateWithDefaultFields(val, defaultFields){
  if (!defaultFields) return val;
  return {...val, ...defaultFields};
}
const TutorFormContext = createContext({});

const TutorFormContextProvider = ({ children, doNotStore, storageKey, defaultState, defaultFields }) => {
  const location = useLocation();
  const [cachedFilters, setCachedFilters] = useLocalStorage(storageKey || (location.pathname+'_value'));

  const parsedDefaultFilters = useMemo(()=>{
    if (!cachedFilters || doNotStore) return;
    // Date fields must be parsed
    return cachedFilters;
  }, [])

  const [value, updateForm] = useReducer(filterReducer, updateWithDefaultFields(parsedDefaultFilters || defaultState || {}, defaultFields));
  
  useEffect(()=>{
    if (!doNotStore) setCachedFilters(value);
  }, [value]);

  return (
    <TutorFormContext.Provider value={{ value, updateForm }}>
      {children}
    </TutorFormContext.Provider>
  );
};

export { TutorFormContext, TutorFormContextProvider };