import { fetchCall } from 'api';

/*
  Search active pairings for a tutor portal user.
*/  
export function searchPairings({fields, 
                                page, 
                                limit, 
                                orderBy, 
                                orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/portal/pairings",
    query: {fields,
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
}
