import React from 'react';
import classnames from 'classnames';

// Components

/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
*/
const sizes = {
  "sm": "max-w-sm",
  "md": "max-w-md",
  "lg": "max-w-lg",
  "xl": "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
}
const styles = {
  "white": "bg-white py-8 px-4 sm:rounded-lg sm:px-10",
  "plain": "bg-none py-8 px-4 sm:px-10"
}
export function CenterViewLayout({ children, className, color="white", size="lg"}){

  return <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white">
          <div className={classnames("mx-auto w-full", styles[color], sizes[size], className)}>
            {children}
          </div>
         </div>
}

export function CenterViewLayoutBase({ children, className, color="white"}){

  return <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className={classnames("sm:mx-auto sm:w-full sm:max-w-lg", styles[color], className)}>
            {children}
          </div>
         </div>
}