import React, { createContext,  useCallback,  useMemo } from "react";

// Hooks
import { useSearch } from "hooks/useSearch";

// API
import { searchEvents } from "api/events";

const EventsContext = createContext({});

const EventsContextProvider = ({ fields, children }) => {
  const params = useMemo(() => ({orderBy: 'date', orderDirection: 'desc' }), []);
  const [events, props] = useSearch(searchEvents, params, {fields});

  const markAsRead = useCallback((id) => {
    if (!id) return;
    props.setResult((prev) => {
      return prev.map((c) => {
          if(c.id == id){
            return {...c, has_unseen_messages: null};
          }
          return c;
        })
    });
  }, []);

  return (
    <EventsContext.Provider value={{ events, markAsRead, ...props }}>
      {children}
    </EventsContext.Provider>
  );
};

export { EventsContext, EventsContextProvider };