import { fetchCall } from 'api';

/*
  Send tutor form. We restrict the body format in the API backend.
*/
export function submitTutorForm(body){
  const options = {
    method: "POST",
    isPublic: true,
    endpoint: "/portal/submit-tutor-form",
    body
  }
  return fetchCall(options)
}

/*
  Get self tutor
*/
export function retrieveTutor({fields}){
  const options = {
    method: "GET",
    endpoint: "/portal/tutor",
    query: {fields}
  }
  return fetchCall(options)
}

/*
 Update tutor
 */
export function updateTutor({scheduleAvailabilities, schoolPreference, subjectPreference, street, city, zipcode, facebookAccount, preferedFormat, email, phone, subscribed}){
  const options = {
    method: "PATCH",
    endpoint: "/portal/tutor",
    body: {scheduleAvailabilities, schoolPreference, subjectPreference, facebookAccount, preferedFormat, street, city, zipcode, email, phone, subscribed}
  }
  return fetchCall(options)
}

export function retrieveTutorInscriptionStatus(){
  const options = {
    method: "GET",
    endpoint: "/portal/tutor/inscription-status",
  }
  return fetchCall(options)
}

/*
  Fetch the documents to sign
*/
export function retrieveUnsignedDocuments(){
  const options = {
    method: "GET",
    endpoint: "/portal/tutor/unsigned-documents",
  }
  return fetchCall(options)
}


/*
  Sign the code ethique form
*/
export function signCodeEthique(){
  const options = {
    method: "POST",
    endpoint: "/portal/tutor/sign-code-ethique",
  }
  return fetchCall(options)
}

/*
Sign the cyber security form
*/
export function signCyberSecurity(){
  const options = {
    method: "POST",
    endpoint: "/portal/tutor/sign-cyber-security-policy",
  }
  return fetchCall(options)
}

/*
Sign the contrat engagement
 */
export function signContratEngagement(){
  const options = {
    method: "POST",
    endpoint: "/portal/tutor/sign-contrat-engagement",
  }
  return fetchCall(options)
}

/*
    Tutor reinscription
*/
export function tutorReinscription({period}){
    const options = {
        method: "POST",
        endpoint: "/portal/tutor/reinscription",
        body: {period}
    }
    return fetchCall(options)
}
