import React from "react";

// Components
import { CenterViewLayout } from "components/core/layouts/center";
import { UnsignedDocumentsList } from "components/tutor-form/unsigned-documents";

// API
// Hooks

export function ListUnsignedDocumentsPage(){
    return (
      <CenterViewLayout>
        <UnsignedDocumentsList/>
    </CenterViewLayout>
  );
}


import { EthicFormPage } from 'pages/sign-documents/code-ethique-form';
import { ContractForm } from "pages/sign-documents/contract-form";
import { CybersecurityForm } from "pages/sign-documents/cybersecurity-form";
export const SignDocumentsPages = {EthicFormPage, ContractForm, CybersecurityForm};
