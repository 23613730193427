export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

// Method to validate a password with at least 8 characters, 1 uppercase, 1 lowercase, 1 number
export function validatePassword(password) {
  if (!password) return true;
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
}

/*
  Source: https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript
*/
export function isString(myVar){
  return (typeof myVar === 'string' || myVar instanceof String)
}

export function isBool(myVar){
  return (typeof myVar === 'boolean' || myVar instanceof Boolean)
}

export function isDate(myVar){
  return(Object.prototype.toString.call(myVar) === "[object Date]")
}

// Validate phone number
export function validatePhoneNumber(phoneNumberString){
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return true;
  }
  return false;
}

export function editDistance(a, b){
  if(a.length == 0) return b.length; 
  if(b.length == 0) return a.length; 

  var matrix = [];

  // increment along the first column of each row
  var i;
  for(i = 0; i <= b.length; i++){
    matrix[i] = [i];
  }

  // increment each column in the first row
  var j;
  for(j = 0; j <= a.length; j++){
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for(i = 1; i <= b.length; i++){
    for(j = 1; j <= a.length; j++){
      if(b.charAt(i-1) == a.charAt(j-1)){
        matrix[i][j] = matrix[i-1][j-1];
      } else {
        matrix[i][j] = Math.min(matrix[i-1][j-1] + 1, // substitution
                                Math.min(matrix[i][j-1] + 1, // insertion
                                         matrix[i-1][j] + 1)); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
}