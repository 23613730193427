import React, { useContext, useState } from 'react';

// Markdown
import { SelectMenu, TextInputDebounce } from 'components/core/inputs';
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';


// Hooks
import { useTranslation } from 'react-i18next';

// API
import { updateTutor } from 'api/tutor';

// Context
import { TutorContext } from 'contexts/tutor';
import { useAPI } from 'hooks/useAPI';
import { validateEmail, validatePhoneNumber, editDistance, isBool } from 'utils/validate';
import { useAuth } from 'hooks/useAuth';
import { formatPhoneNumber } from 'utils/format';

function SelectSubscribed({defaultValue, setSubscribed}){
    const {t} = useTranslation("tutor-form");
    const options = [
        {
            "name": "Oui",
            "value": true
        },
        {
            "name": "Non",
            "value": false
        }
    ]
    const [value, setValue] = useState(isBool(defaultValue)? options.find(d=>d.value===defaultValue): options[1]);

    return <SelectMenu values={options}
                        value={value}
                        setValue={(d)=>{setValue(d); setSubscribed(d.value)}}
                        format={d=>d.name}
                        description={t("subscribed.info")}
                        label={t("subscribed.label")}
                        warning={value && value.value===false && t("subscribed.if-no")}
                        buttonLabel={value? null: "Non"}
                        indexKey={"name"}
                        multiple={false}/>
}

export function ControlledContactInput({defaultValue, setPhone, setEmail, setSubscribed}){
    const { user } = useAuth();
    const {t} = useTranslation("tutor-form");
    return <div className=" py-8 space-y-4">
            <Heading mediumTitle={t("contact")} />
            <TextInputDebounce defaultValue={defaultValue.email} 
                onChange={setEmail} 
                label={t("email.label")} 
                description={t("email.change-info", {email: user.email})} 
                warningOnBlur={(val) => val && user.email && editDistance(val.toLowerCase(), user.email.toLowerCase())>0 && editDistance(val, user.email)<4 ? t("email.short-edit-distance", {email: user.email}) : null}
                errorOnBlur={(val) => val && !validateEmail(val) ? t("email.invalid") : null}
                delay={300}
                autoComplete="email" />
           <TextInputDebounce defaultValue={defaultValue.phone} 
                onChange={setPhone} 
                label={t("phone.label")} 
                reformatOnBlur={formatPhoneNumber}
                errorOnBlur={(val) => val && !validatePhoneNumber(val) ? t("phone.invalid") : null}
                delay={300}
                autoComplete="phone" />

            <SelectSubscribed 
                defaultValue={defaultValue.subscribed} 
                setSubscribed={setSubscribed}/>

        </div>
}

export function UncontrolledContactInput({defaultValue}){
    const [, {execute}] = useAPI(updateTutor, {}, {immediate: false})
    return <ControlledContactInput defaultValue={defaultValue} 
                    setEmail={(email)=>execute({email: email.toLowerCase()})}
                    setSubscribed={(subscribed)=>execute({subscribed})}
                    setPhone={(phone)=>execute({phone})}/>
}

export function ContactInput(){
    const { tutor, error } = useContext(TutorContext);
    if (error){
        return <Error.Text {...error}/>
    }
    if (!tutor){
        return <Skeleton className="h-42"/>
    }
    return <UncontrolledContactInput defaultValue={tutor}/>
}