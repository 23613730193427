import React from 'react';

// Hooks
import { useContext } from 'react';

// Components
import { SearchStudentsCommand } from 'components/select/search-students-cmd';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';

export function SelectStudents(){
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    let students = studySession.students || [];

    return  <SearchStudentsCommand values={students} onClick={(value)=>updateStudySession({type: 'students', value})}/>
}
