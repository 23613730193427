import React from 'react';

// Hooks
import { useAuth } from 'hooks/useAuth';
import { useConfirmActivityPrompt } from 'hooks/useConfirmActivityPrompt';

// Components
import { Spinner } from 'components/core/spinner';
import { CenterViewLayoutBase } from 'components/core/layouts/center';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'pages/errors/500';
import { Modal, ModalBasic } from 'components/core/modal';
import { useTranslation } from 'react-i18next';
import { BellAlertIcon } from '@heroicons/react/24/outline';

import { SEO } from 'components/core/seo';

export function AuthGuard({children}){
  const { isConnected, loading, error } = useAuth();
  const { open, setOpen, handleStillHere, remaining } = useConfirmActivityPrompt();
  const {t} = useTranslation("common");
  if ((!isConnected && loading) || (error && loading)){
      return <CenterViewLayoutBase color="plain">
                <SEO title="loading.title" description="loading.description" />
                  <Spinner className="mx-auto" size="md"/>
              </CenterViewLayoutBase>
  }
  if (!isConnected){
    return <Navigate to={`/login?ref=${encodeURIComponent(window.location.pathname+window.location.search)}`}/>
  }
  return <ErrorBoundary>
          <SEO title="main.title" description="main.description" />
          {children}
          <Modal open={open} setOpen={setOpen} onClickOutside={handleStillHere}>
                <ModalBasic title={t('confirm-activity.title')} 
                            description={t('confirm-activity.description', {remaining})}
                            submitLabel={t('confirm-activity.confirm')}
                            Icon={BellAlertIcon}
                            onClick={handleStillHere}/>
          </Modal>
        </ErrorBoundary>;
}

export function RequiredStepsGuard({children}){
  const { user } = useAuth();
  if (user && user.role==="tutor" && user.documents_signed===false){
    return <Navigate to="/sign-required-documents"/>
  }
  return children;
}

export function ScopeGuard({role, children}){
  const { user } = useAuth();

  if (!user) return null;
  if (!role) return children;
  if (user.role !== role){
    return <Navigate to="/"/>
  }
  return children;
}
