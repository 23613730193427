import React from "react";

// Context
import { AddStudySessionContext, AddStudySessionContextProvider } from "contexts/add-study-session";

// Components
import { Heading } from "components/core/typo";
import { SelectPairing } from "components/add-study-session/select-pairing";
import { SelectSessionFormat } from "components/add-study-session/select-session-format";
import { SelectSchoolSubjects } from "components/add-study-session/select-subjets";
import { SelectDate } from 'components/add-study-session/select-date';
import { SelectSessionLength } from 'components/add-study-session/select-length';
import { SelectOptions } from 'components/add-study-session/select-options';
import { ReviewSection } from 'components/add-study-session/review';
import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { SEO } from 'components/core/seo';

// Hooks
import { useTranslation } from "react-i18next";
import { useContext } from "react";

/*
  Add a study session when logged.
*/
export function AddTutoratPageWithContext() {
  const { t } = useTranslation("add-study-session");

  const { studySession } = useContext(AddStudySessionContext);
  if (studySession && studySession.result) {
    return <div className="px-6">
      <SEO title={"add-tutorat.title"} description={"add-tutorat.description"} />
      <Heading Icon={RocketLaunchIcon} title={t("confirmation-tutorat.title")} description={t("confirmation-tutorat.description")} />
    </div>
  }
  return <div className="h-full md:flex">
    <div className="flex-1 max-w-4xl px-6 pb-12 border-r">
      <SEO title={"add-tutorat.title"} description={"add-tutorat.description"} />
      <Heading title={t("title")}>
      </Heading>
      <div className="space-y-6 md:space-y-12 pb-12">
        <SelectPairing />
        <SelectSessionFormat />
        <SelectSchoolSubjects />
        <SelectDate noDescription />
        <SelectSessionLength />
        <SelectOptions />
      </div>
    </div>
    <div className="relative w-full px-6 md:max-w-xs">
      <div className="sticky top-0 max-h-full pt-12 mb-8">
        <ReviewSection />
      </div>
    </div>
  </div>
}


export function AddTutoratPage() {
  return <AddStudySessionContextProvider doNotStore >
    <AddTutoratPageWithContext />
  </AddStudySessionContextProvider>
}
