import React from "react";

// Components
// import { Heading } from 'components/core/typo';
import { Steps } from 'components/tutor-form/steps';
import { Outlet, Navigate} from 'react-router-dom';
import { FullFlexNav } from 'components/core/layouts/flex';
import { Skeleton } from 'components/core/skeleton';
import { SEO } from 'components/core/seo';

// Context
import { TutorFormContextProvider } from 'contexts/tutor-form';

// API
import { retrieveActivePeriodTutorInscription } from 'api/utils';

// Hooks
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';

/*
  Settings base page
*/
export function TutorInscriptionPage(){
  const {user} = useAuth();
  const fetchParams = useMemo(()=>({}), []);
  const [period, {loading}] = useAPI(retrieveActivePeriodTutorInscription, fetchParams, {executeOnce: true, immediate: false, authRequired: false});
  const params = useParams();

  if (loading || !period){
    return <FullFlexNav breakdown="lg" className="h-full lg:overflow-hidden">
            <SEO title={"tutor-inscription.title"} description={"tutor-inscription.title"} />
             <FullFlexNav.Bar className="relative w-full bg-slate-800 lg:h-full lg:border-r lg:max-w-md" breakdown="lg">
              </FullFlexNav.Bar>
            <FullFlexNav.Content className="py-4 space-y-4 overflow-auto bg-white lg:py-12 lg:block"  breakdown="lg">
                <Skeleton className="w-full h-12 max-w-2xl"/>
                <Skeleton className="w-full h-24 max-w-2xl"/>
                <Skeleton className="w-full h-24 max-w-2xl"/>
            </FullFlexNav.Content>
          </FullFlexNav>
  }
  if (!params.period){
    return <Navigate to={`/inscription-tuteur/${period.slug}`} replace={true}/>
  }
  if (params.period !== period.slug){
    // Redirect to the correct period
    return <Navigate to={`/inscription-tuteur/${period.slug}`} replace={true}/>
  }
  return  <TutorFormContextProvider storageKey="tutor-form" defaultFields={user}>
            <FullFlexNav breakdown="lg" className="h-full lg:overflow-hidden">
             <FullFlexNav.Bar className="relative w-full bg-slate-800 lg:h-full lg:border-r lg:max-w-md" breakdown="none">
              <div className="h-full bg-slate-800">
                <Steps/>
              </div>
              </FullFlexNav.Bar>
            <FullFlexNav.Content scrollOnChange className="flex justify-center py-4 overflow-auto bg-white lg:py-12 lg:block"  breakdown="lg">
                <Outlet/>
            </FullFlexNav.Content>
          </FullFlexNav>
          </TutorFormContextProvider>
}

import { GeneralFormPage } from 'pages/tutor-inscription/general-form';
import { InfoPage } from 'pages/tutor-inscription/info';
import { PreferenceFormPage } from 'pages/tutor-inscription/preference-form';
import { ReviewFormPage } from 'pages/tutor-inscription/review';
import { useAuth } from "hooks/useAuth";
export const nestedTutorInscriptionPages = {GeneralFormPage,
                                            InfoPage,
                                            PreferenceFormPage,
                                            ReviewFormPage};
