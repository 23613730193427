import React from 'react';
import classnames from 'classnames';


const colors = {
  default: {
    title: "text-gray-400",
    description: "text-gray-400",
    container: "",
    icon: "text-gray-400",
  },
  gray: {
    title: "text-gray-700",
    description: "text-gray-600",
    icon: "text-gray-700",
    container: "bg-gray-100 rounded-lg",
  },
  success: {
    title: "text-green-700",
    description: "text-green-600",
    icon: "text-green-700",
    container: "bg-green-100 rounded-lg",
  },
  warning: {
    title: "text-yellow-700",
    description: "text-yellow-600",
    icon: "text-yellow-700",
    container: "bg-yellow-50 rounded-lg",
  },
  danger: {
    title: "text-red-700",
    description: "text-red-600",
    icon: "text-red-700",
    container: "bg-red-50 rounded-lg",
  }

}
export function EmptyState({ title, description, Icon, noMargin, children, className, iconProps, color="default" }){
  return <div className={classnames("mx-auto text-center", colors[color].container, className)}>
          <div className={classnames("w-full max-w-md mx-auto", !noMargin && " p-4")}>
            {Icon&& <Icon {...iconProps} className={classnames("text-center h-10 w-10 mx-auto mb-3", colors[color].icon)}/>}
            <h3 className={classnames("text-lg", colors[color].title)}>{title}</h3>
            {description?<p className={classnames(colors[color].description)}>{description}</p>:null}
            {children}
          </div>
         </div>
}