import React from "react";

// Components
import { SEO } from 'components/core/seo';
import { Team } from "components/contact";


export function ContactPage(){


    return <div className="px-6">
                <SEO title={"contact.title"} description={"contact.description"} />
                <Team />
            </div>
}