import slugify from 'slugify';
import { authFetch } from 'utils/auth-fetch';

function camelCaseToSlug(str){
  // Example: aNiceExample -> a_nice_example
  return slugify(str.replace(/[A-Z]/g, function(s){ return "_" + s; }), {replacement: "_", lower:true})
}

export const fetchCall = ({method, endpoint, form, body, query, isPublic, ...props})=>{
    const controller = new AbortController();
    const { signal } = controller;
    const url = new URL(`${process.env.REACT_APP_API_SERVER_URL}${endpoint}`)

    var options = {method: method, signal:signal, credentials: 'include', ...props}

    if (form){
      let f = new FormData();
      Object.keys(form).forEach((key)=>{
        f.append(camelCaseToSlug(key), form[key]);
      })
      options["body"] = f
    }
    
    if (body){
      const camelBody = {};
      Object.keys(body).forEach(key=>(camelBody[camelCaseToSlug(key)]=body[key]));
      options["body"] = JSON.stringify(camelBody)
      options["Content-Type"] = "application/json"
    }

    if (query){
      Object.keys(query).forEach(key => {
        if (query[key]!=null){
          if (Array.isArray(query[key])) {
            for (var i = query[key].length - 1; i >= 0; i--) {
              url.searchParams.append(camelCaseToSlug(key), query[key][i])
            }
          }
          else {
            url.searchParams.append(camelCaseToSlug(key), query[key])
          }
        }
      })
    }
    
    return [authFetch(url, options, isPublic), controller];
}