import React from 'react';

// Components
import { Navigate } from 'react-router-dom';
import { CenterViewLayout } from 'components/core/layouts/center';
import { Spinner } from 'components/core/spinner';


// Hooks
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';

export function LogoutPage(){
  /*
    Check if logged in.
  */
  const {logout, isConnected, loading, loadingLogout} = useAuth();
  const [searchParams] = useSearchParams();

  useEffect(()=>{
    logout();
  }, [])

  if ((!isConnected) && (!loadingLogout) && (!loading)){
    if (searchParams.get("error")){
      return <Navigate to={`/login?error=${searchParams.get("error")}`}/>
    }
    return <Navigate to={"/login"}/>
  }
  return <CenterViewLayout>
          <Spinner/>
         </CenterViewLayout>
}
