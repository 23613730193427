import React from "react";

// Contex
import { AddStudySessionContext } from "contexts/add-study-session";

// Components
import { Heading } from "components/core/typo";
import { SelectSchoolSubjectsDropdown } from "components/add-study-session/select-subjets";
import { SelectDate } from 'components/add-study-session/select-date';
import { SelectLengthDropdown } from 'components/add-study-session/select-length';
import { StudentsList } from 'components/add-study-session/students';
import { SelectStudents } from 'components/add-study-session/select-students';
import { ReviewSectionMultipleStudySessions } from 'components/add-study-session/review';
import { RocketLaunchIcon } from "@heroicons/react/24/outline";

// Hooks
import { useContext } from "react";
import { useTranslation } from "react-i18next";
/*
  Add a study session when logged.
*/
export function AddStudySessionPageWithContext({sessionType}){
  const {t} = useTranslation("add-study-session");
  const { studySession } = useContext(AddStudySessionContext);
  if (studySession && studySession.result){
    return <div className="px-6">
            <Heading Icon={RocketLaunchIcon} title={t(`confirmation-${sessionType}.title`)} description={t(`confirmation-${sessionType}.description`)}/>
            </div>
  }
  return <div className="md:flex   h-full">
              <div className="max-w-4xl px-6 pb-12 border-r flex-1">
                <Heading title={t(`${sessionType}.title`)}/>
                <SelectDate/>

                {/*  NavBar */}
                <Heading className={"mt-6"}  mediumTitle={t("students.title")} longDescription={t("students.description")}/>
                <div className=" lg:flex  items-center lg:space-x-4 lg:justify-between pb-6">
                  <SelectStudents/>
                  <div className="space-y-2 md:space-y-0 md:flex md:space-x-3 items-center mt-2 md:mt-2">
                    <SelectSchoolSubjectsDropdown/>
                    <SelectLengthDropdown/>
                  </div>
                </div>
                  <StudentsList/>
              </div>
              <div className="relative w-full md:max-w-xs px-6">
                <div className="sticky top-0 max-h-full pt-8 md:pt-12">
                  <ReviewSectionMultipleStudySessions sessionType={sessionType}/>
                </div>
              </div>
            </div>
}
