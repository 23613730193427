import { useState, useRef, useEffect } from 'react';

export function useFocus() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const onFocus = () => setValue(true);
  const onBlur = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("focus", onFocus);
        node.addEventListener("blur", onBlur);
        return () => {
          node.removeEventListener("focus", onFocus);
          node.removeEventListener("blur", onBlur);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}