/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { sendResetPasswordEmail } from 'api/auth';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error, TextInfo, Heading } from 'components/core/typo'
import { FiMail } from 'react-icons/fi';
import { SEO } from 'components/core/seo';

export function ForgotPasswordPage(){
  const { t } = useTranslation(["auth"]);
  const [email, setEmail] = useState();
  const [result, {loading, error, execute}] = useAPI(sendResetPasswordEmail, {email},
                                                  {immediate: false});


  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  return <CenterViewLayout>
            <SEO title={"forgot-password.title"} description={"forgot-password.description"} />
            <form className="space-y-6" onSubmit={onSubmit}>
            <div className='relative p-8 text-orange-700 bg-orange-100 rounded-full h-36 w-36'>
                <FiMail className="w-full h-full"/>
            </div>
            <Heading className="max-w-sm" title={t("forgot-password.title")} description={t("forgot-password.description")}/>
            <TextInput
                label={t("login.email.label")}
                autoComplete="email"
                type="email"
                required
                onChange={setEmail}/>
            <Button color="inlineLink" size="link" to="/login">
                {t("forgot-password.back-to-login")}
            </Button>

            <Button disabled={!email} color="active" block size="xl" loading={loading}  type="submit">
                {t("forgot-password.submit")}
            </Button>
            {error? <Error.Text {...error}/>: null}
            {result && <TextInfo>{t("forgot-password.success")}</TextInfo>}
            </form>
         </CenterViewLayout>
}
