import React from 'react'

// Components
import { SelectMenu } from 'components/core/inputs'

// API
import { searchOccupations } from 'api/utils';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function SelectOccupation({value, setValue, ...props}){
  const {t} = useTranslation("tutor-form");
  const params = useMemo(()=>({}), []);
  const [occupations] = useSearch(searchOccupations, params, {authRequired: false})
  return <SelectMenu {...props} placeholder={t("occupation.label")} indexKey="name" values={occupations} value={value} setValue={setValue} label={t("occupation.label")}/>
}