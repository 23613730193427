import React, { useEffect } from 'react';
import {  useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { FiX, FiMenu } from 'react-icons/fi';
import { Button } from 'components/core/button';
import classnames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { BsArrowLeftShort as IconBack } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';

/*
  Mobile nav breakdown: md
*/
function Nav({ children }){
  return <div>
        {/* Static sidebar for desktop */}
        <div className="z-20 hidden md:flex md:w-12 xl:w-72 md:flex-col md:fixed md:inset-y-0 overflow-auto ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-1 min-h-0 border-r bg-gray-50">
            <div className="flex items-center flex-shrink-0 hidden h-16 px-4 mt-16 xl:block">
              <Button to="/" block className="mx-auto ">
                <img src="/assets/logo-HD.png" className="h-auto max-w-full mx-auto max-h-12"/>
              </Button>
            </div>
            <div className="flex flex-col justify-between flex-1 h-full pb-5 ">
              {children}
            </div>
          </div>
        </div>
      </div>
}

function MobileNav({ children }){
  const [sidebarOpen, setSidebarOpen] = useState(false)

  let location = useLocation();
  useEffect(()=>setSidebarOpen(false), [location.pathname]);

  return <div className="md:hidden">
          <Transition show={sidebarOpen}>
          <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
          <TransitionChild
              as={'Fragment'}
              enter="transition-opacity ease-linear duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
               <DialogBackdrop className="fixed inset-0 bg-black/30" />
            </TransitionChild>
            <TransitionChild
              as={'Fragment'}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col flex-1 w-full  pt-5 pb-4 bg-gray-100 h-screen">
                <TransitionChild
                  as={'Fragment'}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 pr-2">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 text-white rounded-full focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <FiX className="w-6 h-6 text-gray-500" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex-1 h-[calc(100vh)] mt-5 overflow-y-auto  w-[calc(100vw-30px)] overflow-auto">
                  {children}
                </div>
              </div>
            </TransitionChild>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-12 xl:w-72 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-1 min-h-0 bg-gray-800 border-r">
            <div className="flex items-center flex-shrink-0 hidden h-16 px-4 mt-6 xl:block">
              <img src="/assets/logo_transparent.png" className="h-auto max-w-full mx-auto max-h-14"/>
            </div>
            <div className="flex flex-col justify-between flex-1 h-full overflow-y-auto">
              {children}
            </div>
          </div>
        </div>

        <div className="sticky top-0 z-10 flex flex-shrink-0 md:hidden ">
           <Button
              color="outline"
              size="md"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <FiMenu className="w-5 h-5" aria-hidden="true" />
            </Button>
          </div>
      </div>
}
function Content({ children, className }) {
  return <div className={classnames("md:mb-0  md:ml-12 xl:ml-72 flex flex-col flex-1 bg-white z-10 h-full md:overflow-auto", className)}>
          {children}
        </div>
}

function Main({ children, className }){
  return <main className={classnames("h-full md:max-h-full flex flex-col overflow-auto md:overflow-hidden", className)}>
            {children}
          </main>
}
function TopBar({ children }){
  return <div className="md:sticky md:top-0 z-20 flex items-center justify-between flex-shrink-0 h-12 px-3 bg-white border-b xl:px-6 flex-0 md:ml-12 xl:ml-72">
              {children}
          </div>
}

export const SideBarLayout = {Nav, Content, Main, TopBar, MobileNav}


export function RightSidebar({ children, open, setOpen }){
  return (
    <Transition show={open}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>

        <TransitionChild
              as={'Fragment'}
              enter="transition-opacity ease-linear duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
               <DialogBackdrop className="fixed inset-0 bg-black/30" />

            </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-20 pointer-events-none md:pl-10">
           
              <TransitionChild
                as={'Fragment'}
                enter="transform transition ease-in-out duration-200 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"

              >

                <DialogPanel className="w-screen pointer-events-auto md:max-w-md h-full">
                  <div className="flex flex-col h-full py-6 overflow-y-auto bg-white shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div className="flex items-center justify-between w-full h-7">
                        <div onClick={() => setOpen(false)} className='flex font-light text-orange-500 hover:cursor-pointer group top-6'><IconBack className='w-6 h-6 transition-all group-hover:w-7'/>Retour</div>
                          <button
                            type="button"
                            className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 mt-6 sm:px-6">{children}</div>
                  </div>
                </DialogPanel>

              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

