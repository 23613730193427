import React, { useContext, useMemo } from 'react';

// Hooks
import { useAuth } from "hooks/useAuth";
import { useTranslation } from 'react-i18next';

// Components
import { Dropdown } from 'components/core/dropdown';
import { FiAward, FiDribbble, FiLogOut, FiPlus, FiSettings, FiUser } from 'react-icons/fi';
import { BookOpenIcon, Cog6ToothIcon, AcademicCapIcon, LifebuoyIcon, DocumentTextIcon, HomeIcon, PhoneIcon, UsersIcon } from '@heroicons/react/24/outline'
import { NavLink } from "react-router-dom";

// utils
import classnames from 'classnames';
import { WelcomeNav } from 'components/widgets/welcome';
import { UnseenConvContext } from 'contexts/unseen-conv';

export function ProfileNav(){
  const {user} = useAuth();
  const {t} = useTranslation("common");
  return <Dropdown onlyIcon={<FiUser/>} withChevron itemClassName="whitespace-nowrap">
          <Dropdown.Item label={user.name} description={t("connected-as")}/>
          <Dropdown.Item to={"/settings"} icon={<FiSettings/>} label={t("settings")} />
          <Dropdown.Item to={"/logout"} icon={<FiLogOut/>} label={t("logout")} />
         </Dropdown>
}

function NavItem({ to, Icon, name, withDot}){
  return <NavLink to={to} className={({isActive})=>classnames(" w-full whitespace-nowrap group relative overscroll-auto flex items-center py-2 rounded-md justify-start md:justify-center xl:justify-start xl:px-1",
           isActive?"bg-gray-200 hover:bg-gray-200 ": "hover:bg-gray-100 ")}>
      {({isActive})=>
            <>
              <Icon className={classnames(" h-6 w-6 flex-none", isActive? 'text-slate-800 md:text-orange-500 xl:text-slate-800': 'text-slate-400')}/>
              <p className="absolute top-0 left-0 hidden px-3 py-2 text-base text-gray-800 transition-opacity duration-100 scale-0 bg-gray-100 rounded-r-lg opacity-0 shadow- group-hover:scale-100 group-hover:opacity-100 translate-x-11 md:block xl:hidden">{name}</p>
              <span className={classnames("ml-2 block md:hidden xl:block font-medium", isActive? "text-slate-800": "text-gray-600", withDot && "!font-bold !text-black")}>{name}</span>
              {withDot && <span className=" h-2 w-2 rounded-full bg-orange-500 ml-auto"/>}
            </>}
          </NavLink>
}

export function Nav(){
  const {t} = useTranslation("common");
  const {user} = useAuth();
  const {unseen} = useContext(UnseenConvContext);
  const actionItems = useMemo(()=>[
    {
      name: t("nav.home"),
      to: "/",
      Icon: HomeIcon
    },
    {
      name: t("nav.play-time"),
      to: "/play-time",
      Icon: FiDribbble,
      withDot: unseen>0,
      visible: user.role == "coach"
    },
    {
      name: t("nav.aides-aux-devoirs"),
      to: "/aides-aux-devoirs",
      Icon: FiAward,
      visible: user.role=="coach"
    },
    {
      name: t("nav.add-tutoring-session"),
      to: "/ajouter-tutorat",
      Icon: AcademicCapIcon,
      visible: user.role=="tutor"
    },
    {
      name: t("nav.add-recuperation"),
      to: "/ajouter-recuperation",
      Icon: AcademicCapIcon,
      visible: user.role=="teacher"
    },
    {
      name: t("nav.add-maison-jeunes"),
      to: "/ajouter-maison-jeunes",
      Icon: AcademicCapIcon,
      visible: user.role=="partner"
    },
    {
      name: t("nav.add-tutorat-prive"),
      to: "/ajouter-tutorat-prive",
      Icon: UsersIcon,
      visible: user.role=="partner"
    },
    
    {
      name: t("nav.add-coach-meeting"),
      to: "/ajouter-coach-meeting",
      Icon: FiPlus,
      visible: user.role=="coach"
    },
  ].filter(d=>d.visible!==false), [user])

  const ressourceItems = useMemo(()=>[
     {
      name: t("nav.ressources"),
      to: "/ressources",
      Icon: BookOpenIcon,
      visible: true
    },
    {
      name: t("nav.documents"),
      to: "/documents",
      Icon: DocumentTextIcon,
      visible: true
    },
    {
      name: t("nav.faq"),
      to: "/faq",
      Icon: LifebuoyIcon,
      visible: user.role=="tutor"
    },
    {
      name: t("nav.contact"),
      to: "/nous-contacter",
      Icon: PhoneIcon,
      visible: true
    },
    {
      name: t("nav.settings"),
      to: "/settings/security",
      Icon: Cog6ToothIcon,
      visible: true
    }
  ].filter(d=>d.visible!==false), [user])

  const bottomItems = useMemo(()=>[
    {
      name: t("nav.logout"),
      to: "/logout",
      Icon: FiLogOut
    }
  ], [])

  return <>
          <div className=''>
              <div className='px-3 md:hidden'>
                <WelcomeNav />
              </div>
            <nav className={"space-y-1 mt-2 px-3 md:px-0 xl:pl-5 xl:pr-3 pt-0"}>
              <p className="block mb-3 text-sm font-medium text-slate-400 md:hidden xl:block">{t("nav-section.actions")}</p>
              {actionItems.map(d=><NavItem {...d} key={d.to}/>)}
            </nav>

            <nav className={"space-y-1 mt-6 px-3 md:px-0 xl:pl-5 xl:pr-3 md:pt-6"}>
              <p className="block mb-3 text-sm font-medium text-slate-400 md:hidden xl:block">{t("nav-section.ressources")}</p>
              {ressourceItems.map(d=><NavItem {...d} key={d.to}/>)}
            </nav>
            <nav className={"block md:hidden space-y-1 mt-6 px-3 md:px-0 xl:pl-5 xl:pr-3 md:pt-6 border-t"}>
              {bottomItems.map(d=><NavItem {...d} key={d.to}/>)}
            </nav>
          </div>

          <div className='hidden md:block'>
            <nav className={"space-y-1 mt-6 px-3 md:px-0 xl:pl-5 xl:pr-3 md:pt-6"}>
              {bottomItems.map(d=><NavItem {...d} key={d.to}/>)}
            </nav>
          </div>
         </>
}
