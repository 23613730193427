import React from 'react';

// Components
import { Container } from 'components/core/container';
import { Heading } from 'components/core/typo';
import { ChangePassword } from 'components/settings/change-password';
import { RecentConnectionsList } from 'components/settings/security/recent_connections';

// Hooks
import { useTranslation } from 'react-i18next';

export function SecurityPage(){
    const {t} = useTranslation("settings");
    return <Container className={"pt-6 pb-12"}>
                <Heading title={t("security.title")} />
                <Container className={"pl-0 mt-5"}>
                    <ChangePassword/>
                </Container>
                <Container className={"pl-0 mt-14"}>
                    <RecentConnectionsList />
                </Container>
            </Container>
}