import React, { useMemo } from "react";

// Components
import { Error, Heading } from "components/core/typo";
import { Skeleton } from "components/core/skeleton";
import { SelectSubjectPreferences } from "components/tutor-form/select-preference";

// Hooks
import { useAPI } from "hooks/useAPI";

// Const
import { useTranslation } from "react-i18next";
import { retrieveTutor, updateTutor } from "api/tutor";

function transformDefaultData(data) {
    const transformedData = {};
    data.forEach((school) => {
        const { slug, ...d } = school;
        transformedData[slug] = {
            slug,
            ...d,
        };
    });
    return transformedData;
}

// Components
export function SelectSubjectsWithContext({ defaultValue }) {
    const { t } = useTranslation("settings");
    const [, { error, execute }] = useAPI(
        updateTutor,
        {},
        { immediate: false }
    );

    const transformedDefaultValue = useMemo(
        () => transformDefaultData(defaultValue),
        []
    );

    return (
        <>
            <Heading
                longDescription={t("subject-preferences.description")}
            />
            <SelectSubjectPreferences
                defaultValue={transformedDefaultValue}
                setValue={(subjectPreference) => execute({ subjectPreference })}
            />
            {error && <Error.Text {...error} />}
        </>
    );
}

export function SelectSubjects() {
    const fields = useMemo(() => ({ fields: ["ni", "subjects"] }), []);
    const [tutor, { loading, error }] = useAPI(retrieveTutor, fields);

    return (
        <div>
            {!tutor && loading && <Skeleton className={"h-42"} />}
            {tutor && <SelectSubjectsWithContext defaultValue={tutor.subjects} />}
            {error && <Error.Text {...error} />}
        </div>
    );
}
