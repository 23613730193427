export function formatPhoneNumber(phoneNumberString) {
  // Remove the non number character, including special characters, spaces, etc.
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
}

export function isValidPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  return cleaned.length === 10;
}

export function formatPostalCode(postalCode) {
  if (!postalCode) return postalCode;
  return postalCode.toUpperCase().replace(/\W/g,'').replace(/(...)/,'$1 ')
}