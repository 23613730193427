import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiZap, FiAlertTriangle } from 'react-icons/fi';
import { Button } from 'components/core/button'

export function Heading({ title, mediumTitle, smallTitle, subtitle, description, noMargin, longDescription, Icon, children, className }){
  return <div className={classnames("md:flex items-center justify-between", !noMargin && "py-3", className)}>
            
            <div>
            {Icon?<div className='relative w-24 h-24 p-4 mb-5 text-orange-800 bg-orange-100 rounded-full'>
                  <Icon className="w-full h-full"/>
              </div> :null}
              {title&&<h2 className="mb-3 text-2xl font-bold text-gray-900 lg:text-4xl">{title}</h2>}
              {mediumTitle&&<h2 className="text-xl font-bold text-gray-800 lg:text-2xl ">{mediumTitle}</h2>}
              {smallTitle&&<h2 className="font-medium text-gray-800 lg:text-lg ">{smallTitle}</h2>}
              {subtitle&&<h3 className="font-medium text-orange-600 lg:text-lg">{subtitle}</h3>}
              {description?
                <p className="max-w-lg mt-2 text-gray-500 sm:mt-0 lg:text-lg">
                  {description}
                </p>:null}
              {longDescription?
                <p className={classnames("mt-2 text-gray-500 sm:mt-0 lg:text-lg", description? 'py-6 text-gray-800 font-medium': '')}>
                  {longDescription}
                </p>:null}
            </div>
            
            <div >
              {children}
            </div>
        </div>
}

export function Hero({title, subtitle, description, children, className}){
  return <div className={className}>
            <div className="px-6 mx-auto max-w-7xl lg:px-8">
              <div className="max-w-3xl mx-auto lg:text-center">
                {subtitle  && <h2 className="text-base font-semibold leading-7 text-orange-600">{subtitle}</h2>}
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {title}
                </p>
                <p className="mt-6 text-lg text-gray-600">
                  {description}
                </p>
              </div>
                {children}
            </div>
          </div>
}

export function Footnote({children, className}){
  return <p className={classnames("text-gray-500 text-sm mt-2", className)}>{children}</p>
}

export function TextError({code, detail, payload, className}){
  const { t } = useTranslation("errors")
  return <p className={classnames("text-red-600 bg-red-50 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2 -mt-0.5"/>{`${t(code, payload)}`!==code && code? t(code, payload): detail}</p>
}

export function TextWarning({code, detail, payload, className}){
  const { t } = useTranslation("errors")
  return <p className={classnames("text-yellow-700 bg-yellow-50 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2"/>{t(code || detail, {...payload})}</p>
}

export function TextInfo({children, className}){
  return <p className={classnames("text-green-700 bg-green-50 rounded-md p-3 text-sm", className)}><FiZap className="inline mr-2"/>{children}</p>
}

export function TextBox({children, className}){
  return <p className={classnames("text-blue-600 bg-blue-50 rounded-md p-3 text-sm", className)}><FiZap className="inline mr-2"/>{children}</p>
}

export function TextBoxError({children, className}){
  return <p className={classnames("text-red-600 bg-red-50 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2"/>{children}</p>
}

export function DetailError({title, description, withReturn, children, className}){
  const { t } = useTranslation('common');
  return <div className={classnames("text-gray-600 text-sm text-center", className)}>
          <h3 className="mt-2 text-lg text-gray-400">{title || t('general_error_title')}</h3>
          <div className="max-w-xl mx-auto mt-1 text-sm text-center text-gray-400">{description}</div>
          {withReturn?
              <Button to="/" color="gray" size="lg" block className="mt-4">
                {t('return_home')}
              </Button>:null
          }
          {children}
         </div>
}

export const Error = {Text: TextError, Detailed: DetailError};
