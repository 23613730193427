
export const helpOptions = {
            overrides: {
                p: {
                    props: {
                        className: 'text-gray-700 lg:leading-6 text-base',
                    },
                },
                ol: {
                    props: {
                        className: 'text-gray-700 list-decimal list-outside pl-3 ml-3 text-base'
                    }
                },
                li: {
                    props: {
                        className: 'text-gray-700 text-base',
                    },
                },
                a: {
                    props: {
                        className: 'text-gray-700 text-blue-500 text-base underline',
                    },
                },
            },
        }

export const helpOptions2 = {
    overrides: {
        p: {
            props: {
                className: 'text-gray-700 lg:leading-6 text-base',
            },
        },
        ol: {
            props: {
                className: 'text-gray-700 list-decimal list-outside pl-3 ml-3 text-base'
            }
        },
        ul: {
            props: {
                className: 'text-gray-700 list-disc list-outside pl-3 ml-3 text-base'
            }
        },
        li: {
            props: {
                className: 'text-gray-700 text-base',
            },
        },
        a: {
            props: {
                className: 'text-gray-700 text-blue-500 text-base underline',
            },
        },
    },
}
export const options = {
            overrides: {
                div: {
                  props: {
                    className: "text-base md:text-lg text-gray-500"
                  }
                },
                h1: {
                    props: {
                        className: 'mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl',
                    },
                },
                h2: {
                    props: {
                        className: 'mt-6 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl ',
                    },
                },
                p: {
                    props: {
                        className: 'text-gray-700 py-3 lg:leading-6 text-base md:text-lg',
                    },
                },
                a: {
                    props: {
                        className: 'text-gray-700 text-orange-500 text-base md:text-lg',
                    },
                },
                table: {
                    props: {
                        className: 'text-gray-700 min-w-full divide-y divide-gray-300 mb-4 text-base md:text-lg',
                    },
                },
                th: {
                    props: {
                        className: 'text-gray-700 py-1.5 px-3 text-left text-sm font-semibold',
                    },
                },
                ul: {
                    props: {
                        className: 'text-gray-700 list-disc list-outside pl-3 ml-3 mt-3 space-y-5 text-base md:text-lg',
                    },
                },
                ol: {
                    props: {
                        className: 'text-gray-700 list-decimal list-outside pl-3 ml-3 mt-3 text-base md:text-lg'
                    }
                },
                li: {
                    props: {
                        className: 'text-gray-700 text-base md:text-lg',
                    },
                },
            },
        }

export const optionsRessources = {
            overrides: {
                strong: {
                    props: {
                        className: 'text-gray-700 font-semibold',
                    },
                }
              
            }
        }
            