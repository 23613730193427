import React from "react";

// Components
import { Outlet } from "react-router-dom";
import { FlexNav } from "components/core/layouts/flex";
import { Nav } from "components/settings/nav";
import { SEO } from 'components/core/seo';

/*
  Settings base page
*/
function SettingsPage(){
  return <FlexNav>
            <SEO title={"parameters.title"} description={"parameters.description"} />
            <FlexNav.Bar>
                    <Nav/>
            </FlexNav.Bar>
            <FlexNav.Content>
                <Outlet/>
            </FlexNav.Content>
         </FlexNav>
}

import { SecurityPage } from "./security";
import { PreferencePage } from "./preferences"
import { SchoolsPage } from "./schools";
import { SubjectPage } from "./subjects";
import { InfoPage } from "./info";
const Settings = {Root: SettingsPage, Security: SecurityPage, Preferences: PreferencePage, Schools: SchoolsPage, Subjects: SubjectPage, Info: InfoPage};
export {Settings}