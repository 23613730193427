import React, { useMemo } from 'react';

// Hooks
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { submitTutoringSession,
         submitRecuperations,
         submitMaisonJeunes,
         submitTutoratPrive,
         submitCoachMeeting } from 'api/study_sessions';

// Components
import { Error, Footnote, Heading } from 'components/core/typo';
import { BoltSlashIcon, BookOpenIcon, ClockIcon, EyeSlashIcon, VideoCameraSlashIcon } from '@heroicons/react/24/outline';

// Utils
import { locale, dateParse } from 'utils/locale';
import classnames from 'classnames';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';
import { Button } from 'components/core/button';

const iconMapping = {
    "absence-denregistrement-tutorat": VideoCameraSlashIcon,
    "camera-fermee-tutorat": EyeSlashIcon,
    "retard-tutorat": ClockIcon,
    "manque-de-concentration-tutorat": BoltSlashIcon,
    "manque-de-materiel-tutorat": BookOpenIcon
}

function IncompletePoint(){
    return <div className="relative flex items-center justify-center flex-shrink-0 w-5 h-5" aria-hidden="true">
                <div className="w-2 h-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
            </div>
}

function CompletedPoint(){
    return <span className="relative flex items-center justify-center flex-shrink-0 w-5 h-5" aria-hidden="true">
            <span className="absolute w-4 h-4 bg-green-200 rounded-full" />
            <span className="relative block w-2 h-2 bg-green-600 rounded-full" />
        </span>
}

function BulletPoint({label, description, Icon, completed, option}){
    return <div className={classnames('flex items-start', option?"bg-gray-100 shadow-sm rounded-md mb-2 p-2": "py-2")}>
                {Icon? <Icon className="flex-shrink-0 w-6 h-6 text-red-500"/>:completed? <CompletedPoint/>: <IncompletePoint/>}
                <div>
                      <p className={classnames("ml-3 font-medium", option? "text-gray-800":completed? "text-green-600": "text-gray-400")}>{label}</p>
                      {
                        description && <p className={classnames("ml-3", option? "text-gray-500": "text-gray-800")}>{description}</p>
                      }
                    </div>
            </div>
}

function Pairing(){
    const { t } = useTranslation("add-study-session");
    const { studySession } = useContext(AddStudySessionContext);
    return <BulletPoint label={t("review.pairing.label")}
                        description={studySession.pairing && studySession.pairing.student.name}
                        completed={!!studySession.pairing}/>
}

function SessionFormat(){
    const { t } = useTranslation("add-study-session");
    const { studySession } = useContext(AddStudySessionContext);
    return <BulletPoint label={t("review.format.label")}
                        description={studySession.sessionFormat && studySession.sessionFormat.name}
                        completed={!!studySession.sessionFormat}/>
}

function SchoolSubjects(){
    const { t } = useTranslation("add-study-session");
    const { studySession } = useContext(AddStudySessionContext);
    return <BulletPoint label={t("review.subjects.label")}
                        description={studySession.schoolSubjects && studySession.schoolSubjects.map(d=>d.name).join(', ')}
                        completed={studySession.schoolSubjects && studySession.schoolSubjects.length>0}/>
}

function Date(){
    const { t } = useTranslation("add-study-session");
    const { studySession } = useContext(AddStudySessionContext);
    return <BulletPoint label={t("review.date.label")}
                        description={studySession.date && locale.format("Le %A %d %B %Y ")(dateParse(studySession.date))}
                        completed={!!studySession.date}/>
}

function SessionLength(){
    const { t } = useTranslation("add-study-session");
    const { studySession } = useContext(AddStudySessionContext);
    return <BulletPoint label={t("review.length.label")}
                        description={studySession.sessionLength? `${studySession.sessionLength} min.`: null}
                        completed={!!studySession.sessionLength}/>
}

function Options(){
    const { studySession } = useContext(AddStudySessionContext);
    if (!studySession.options) return null;
    return <>
            {(studySession.options || []).map(d=><BulletPoint Icon={iconMapping[d.label]} option label={d.title} description={d.comments} key={d.label}/>)}
        </>
}

function Students(){
    const { t } = useTranslation("add-study-session");
    const { studySession } = useContext(AddStudySessionContext);
    return <>
            {(studySession.students || []).map(student=>{
                const subjects = (studySession.studentSchoolSubjects || {})[student.ni] || ( studySession && studySession.schoolSubjects) || [];
                const sessionLength = !isNaN((studySession.studentSessionLength || {})[student.ni]) ? (studySession.studentSessionLength || {})[student.ni]: studySession.sessionLength;

                return <BulletPoint completed={subjects.length>0 && sessionLength || sessionLength === 0}
                                    label={student.name}
                                    description={(sessionLength || sessionLength === 0 ? `${sessionLength} min` : t("review.length.no-length")) + ` | ${subjects.map(d=>d.name).join(', ')}`}
                                    key={student.ni}/>
            })}
        </>
}

export function ReviewSection(){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession, isValid } = useContext(AddStudySessionContext);
    const params = useMemo(()=>({
        pairing: studySession.pairing && studySession.pairing.id,
        sessionFormat: studySession.sessionFormat && studySession.sessionFormat.code,
        schoolSubjects: studySession.schoolSubjects && studySession.schoolSubjects.map(d=>d.slug),
        date: dateParse(studySession.date),
        sessionLength: studySession.sessionLength,
        options: studySession.options && studySession.options.map(d=>({label:d.label, comments: d.comments}))
    }), [studySession]);

    const [result, {execute, loading, error}] = useAPI(submitTutoringSession, params, {immediate: false})
    useEffect(()=>{
        if (result) {
            updateStudySession({type: "result", value: result});
        }
    }, [result]);

    return  <div className='w-full'>
                <Heading smallTitle={t("review.title")} />
                <Pairing/>
                <SessionFormat/>
                <SchoolSubjects/>
                <Date/>
                <SessionLength/>
                <Options/>
                <hr className="my-4"/>
                <Footnote className={"mb-3"}>{t("review.info")}</Footnote>
                <Button block color="active" size="md" disabled={!isValid} onClick={execute} loading={loading}>
                    {t("review.submit")}
                </Button>
                {error? <Error.Text {...error}/>: null}
            </div>
}


export function ReviewSectionMultipleStudySessions({sessionType}){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession, isValid } = useContext(AddStudySessionContext);

    const apiMethod = useMemo(()=>{
        switch(sessionType){
            case "recuperation": return submitRecuperations;
            case 'maison-jeunes': return submitMaisonJeunes;
            case 'coach-meeting': return submitCoachMeeting;
            case 'tutorat-prive': return submitTutoratPrive;
            default: return null;
        }
    }, [sessionType]);

    const params = useMemo(()=>({
        date: dateParse(studySession.date),
        sessions: (studySession.students || []).map(student=>{
            const subjects = (studySession.studentSchoolSubjects || {})[student.ni] || ( studySession && studySession.schoolSubjects) || [];
            const sessionLength = (studySession.studentSessionLength || {})[student.ni] || studySession.sessionLength;
            const notes = ((studySession.studentNotes || {})[student.ni] || []).map(d=>({label: d.label, comments: d.comments}));
            return {student: student.ni, subjects: subjects.map(d=>d.slug), length: sessionLength, notes}
        })
    }), [studySession]);

    const [result, {execute, loading, error}] = useAPI(apiMethod, params, {immediate: false})
    useEffect(()=>{
        if (result) {
            updateStudySession({type: "result", value: result});
        }
    }, [result]);

    return  <div className='w-full pb-3'>
                <Heading mediumTitle={t("review.title")} />
                    <Date/>
                    <Students/>
                    <hr className="my-4"/>
                <Footnote className={"mb-3"}>{t("review.multiple.info")}</Footnote>
                <Button block color="active" size="md" disabled={!isValid} onClick={execute} loading={loading} >
                    {t("review.multiple.submit")}
                </Button>
                {error? <Error.Text {...error}/>: null}
            </div>
}