/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { useSearch } from 'hooks/useSearch';
import { useMemo, useCallback } from 'react';
import classnames from 'classnames';

// API
import { searchSchoolSubjects, searchSchools } from 'api/utils';

export function SelectPreferences({ values, setValue, formatValue, options, defaultOption, minQte=0 }) {
    if (!values) return <Skeleton className="w-full h-64" />

    return <div className=" grid lg:grid-cols-2 gap-x-4">
        {(values).map(d =>
            <div key={formatValue(d)} className="justify-between flex items-center rounded-md p-2">
                <div className="">
                    <p className="font-medium">{formatValue(d)}</p>
                    <p className="text-gray-500 mr-3 mr-auto">{
                        options[d.description || defaultOption]?.qte<Math.max(minQte)?
                        options[defaultOption]?.name
                        :
                        (options[d.description || defaultOption])?.name}</p>
                </div>
                <div className="flex space-x-3  ">
                    <div className="flex items-center space-x-0.5">
                        {[...Array(Object.keys(options).length - 1).keys()].map(
                            (i) => <span key={i} 
                            className={classnames(Math.max(minQte, (options[d.description || defaultOption])?.qte) > i ? "bg-orange-500" : "bg-gray-200", "rounded h-full w-4")} />
                        )}
                    </div>
                    <Button size="smToolbarIcon" color="gray" 
                        disabled={!(options[d.description || defaultOption])?.previous || (minQte && (options[d.description || defaultOption])?.qte <= minQte)}
                        onClick={() => setValue({ ...d, description: (options[d.description || defaultOption])?.previous })}><FiMinus /></Button>
                    <Button size="smToolbarIcon" color="gray" disabled={!(options[d.description || defaultOption])?.next}
                        onClick={() => setValue({ ...d, description: (options[d.description || defaultOption])?.next })}><FiPlus /></Button>
                </div>
            </div>
        )}
    </div>
}
// ()=>setValue({...d, description:(options[d.description || defaultOption]).next})
const subjectPreferenceOptions = {
    "none": { name: "Pas du tout", previous: null, next: "low", qte: 0 },
    "low": { name: "Partiellement", previous: "none", next: "high", qte: 1 },
    "high": { name: "Très", previous: "low", next: null, qte: 2 },
}

export function SelectSubjectPreferences({ defaultValue, setValue }) {
    // Fetch the
    const params = useMemo(() => ({ usedForPreference: true }), [])
    const transformResults = useCallback((d) => d.map(e => (defaultValue || {})[e.slug] || e), [])
    const [values, { setResult }] = useSearch(searchSchoolSubjects, params, { transformResults, authRequired: false })
    const updateValues = useCallback((d) => setResult(r => r.map(e => e.slug === d.slug ? d : e)), []);

    return <SelectPreferences
        values={values}
        formatValue={(d) => d.name}
        options={subjectPreferenceOptions}
        defaultOption={"none"}
        setValue={d => { setValue(d); updateValues(d) }}
    />
}

const schoolPreferenceOptions = {
    "none": { name: "Non disponible", previous: null, next: "dispo", qte: 0 },
    "dispo": { name: "Disponible", previous: "none", next: "prioritaire", qte: 1 },
    "prioritaire": { name: "Prioritaire", previous: "dispo", next: null, qte: 2 },
}

export function SelectSchoolPreferences({ defaultValue, setValue, updateOnInit=true, online = false }) {

    // Settings
    const initialOption = online ? "dispo" : "none"
    const minQte = schoolPreferenceOptions[initialOption]?.qte

    const params = useMemo(() => ({}), [])
    const transformResults = useCallback((d) => {
        return d.map(e => (defaultValue || {})[e.slug] || e)
    }, [])
    const [values, { setResult, loading }] = useSearch(searchSchools, params, { transformResults, authRequired: false })
    const updateValues = useCallback((d) => setResult(r => r.map(e => e.slug === d.slug ? d : e)), []);
    const handleSetValue = (d) => {
        setValue(d)
        updateValues(d)
    }
    
    useEffect(()=>{
        if (!values || loading || !updateOnInit) return;
        values.forEach(v => {
            const { description = "none", setByUser } = v;
            const vQte = schoolPreferenceOptions[description]?.qte;
            // if (defaultValue)
            handleSetValue({ ...v, description: vQte<minQte? initialOption: description });
                // if ((!setByUser && vQte > minQte) || vQte < minQte) {
                // handleSetValue({ ...v, description: initialOption });
            // }
        })
    },[online, loading])
    
    return <SelectPreferences
        values={values}
        formatValue={(d) => d.name}
        options={schoolPreferenceOptions}
        defaultOption={initialOption}
        minQte={minQte}
        setValue={(v)=>handleSetValue({...v, 
            description: (schoolPreferenceOptions[v.description || initialOption]).qte < minQte ? initialOption : v.description})}
    />
}
