import React from 'react';

// Components
import { ExtraQuestionContainer } from 'components/tutor-form/container';
import { Heading } from 'components/core/typo';
import { TextInput } from 'components/core/inputs';
import { Button } from 'components/core/button';
import { SelectOccupation } from 'components/tutor-form/select-occupation';
import { SelectQuestion } from 'components/tutor-form/select-extra';
import Markdown from 'markdown-to-jsx';
import { helpOptions } from 'mdx';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// Utils
import { formatPhoneNumber, formatPostalCode } from 'utils/format';
import { validateEmail } from 'utils/validate';

// Contexts
import { TutorFormContext } from 'contexts/tutor-form';


const whyThisQuestion = `
<div className="bg-gray-100 rounded-md p-3 mt-2">
👋 Pourquoi posons-nous cette question?

DAM est partenaire avec le ministère de l’Immigration, de la Francisation et de l’Intégration (MIFI) et cette donnée est évaluée pour maximiser le vivre-ensemble auprès des élèves-athlètes.
</div>`


export function GeneralFormPage() {
    const { value, updateForm } = useContext(TutorFormContext);
    const navigate = useNavigate();
    const { t } = useTranslation("tutor-form");
    const buttonDisabled = useMemo(() => {
        return (!value.firstname || !value.lastname || !value.phone || !value.email || !value.tutorOrParentIsImmigrant || !value.street || !value.city || !value.phone)
    }, [value]);
    return <form className="w-full max-w-2xl px-4 text-lg space-y-4" autoComplete="on">
        <Heading title={t("general-form.title")} description={t("general-form.description")} />
        <div className="grid grid-cols-2 space-x-3">
            <TextInput defaultValue={value.firstname}
                onChange={(value) => updateForm({ type: "firstname", value })}
                label={t("firstname.label")}
                autoComplete="firstname" />
            <TextInput defaultValue={value.lastname}
                onChange={(value) => updateForm({ type: "lastname", value })}
                label={t("lastname.label")}
                autoComplete="name" />
        </div>
        <TextInput defaultValue={value.phone}
            onChange={(value) => updateForm({ type: "phone", value })}
            label={t("phone.label")}
            type="tel"
            reformatOnBlur={(value) => formatPhoneNumber(value)}
            errorOnBlur={(value) => (value && formatPhoneNumber(value) === value) ? t("phone.invalid") : null}
            autoComplete="tel" />
        <TextInput defaultValue={value.email}
            onChange={(value) => updateForm({ type: "email", value })}
            type="email"
            errorOnBlur={(val) => val && !validateEmail(val) ? t("email.invalid") : null}
            label={t("email.label")} />
        <SelectOccupation name="occupation"
            value={value.occupation}
            setValue={(value) => updateForm({ type: "occupation", value })} />

        {!value.occupation ? null :
            value.occupation.slug === "etudiante-universitaire" ?
                <ExtraQuestionContainer>
                    <TextInput defaultValue={value.occupation_location}
                        onChange={(value) => updateForm({ type: "occupation_location", value })}
                        label={t("university.label")} />
                    <TextInput defaultValue={value.occupation_program}
                        onChange={(value) => updateForm({ type: "occupation_program", value })}
                        label={t("program.label")} />
                </ExtraQuestionContainer> :
                value.occupation.slug == "cegepienne" ?
                    <ExtraQuestionContainer>
                        <TextInput defaultValue={value.occupation_location}
                            onChange={(value) => updateForm({ type: "occupation_location", value })}
                            label={t("cegep.label")} />
                        <TextInput defaultValue={value.occupation_program}
                            onChange={(value) => updateForm({ type: "occupation_program", value })}
                            label={t("cegep-program.label")} />
                    </ExtraQuestionContainer> :
                    value.occupation.slug == "etudiante-au-secondaire" ?
                        <ExtraQuestionContainer>
                            <TextInput defaultValue={value.occupation_location}
                                onChange={(value) => updateForm({ type: "occupation_location", value })}
                                label={t("etablissement-secondaire.label")} />
                            <TextInput defaultValue={value.occupation_program}
                                onChange={(value) => updateForm({ type: "occupation_program", value })}
                                label={t("secondaire-program.label")} />
                        </ExtraQuestionContainer> :
                        value.occupation.slug == "travailleurse" ?
                            <ExtraQuestionContainer>
                                <TextInput defaultValue={value.occupation_location}
                                    onChange={(value) => updateForm({ type: "occupation_location", value })}
                                    label={t("boss.label")} />
                            </ExtraQuestionContainer> :
                            value.occupation.slug == "autre" ?
                                <ExtraQuestionContainer>
                                    <TextInput defaultValue={value.occupation_location}
                                        onChange={(value) => updateForm({ type: "occupation_location", value })}
                                        label={t("autre-occupation.label")} />
                                </ExtraQuestionContainer> : null
        }
        <div className='pt-12'>
            <SelectQuestion value={value.tutorOrParentIsImmigrant}
                setValue={d => updateForm({ type: 'tutorOrParentIsImmigrant', value: d })}
                id="tutor-or-parent-is-immigrant" />
            <Markdown options={helpOptions}>{whyThisQuestion}</Markdown>
        </div>
        <div className='space-y-4'>
            <SelectQuestion 
                value={value?.gender || null}
                setValue={d => updateForm({ type: 'gender', value: d })}
                id="select-gender" 
            />
            {
                value?.gender?.slug === "other" && 
                    <TextInput defaultValue={value.occupation_location}
                        onChange={(value) => updateForm({ type: "gender_precision", value })}
                        label={t("select-gender.extra-label")} />
                  
            }
        </div>
        <div className=" py-8 space-y-4">
            <Heading mediumTitle={t("address.label")} />
            <TextInput defaultValue={value.street} onChange={(value) => updateForm({ type: "street", value })} label={t("address.street")} autoComplete="street-address" />
            <div className="grid grid-cols-2 space-x-3">
                <TextInput defaultValue={value.city} onChange={(value) => updateForm({ type: "city", value })} label={t("address.city")} />
                <TextInput defaultValue={value.zipcode}
                    onChange={(value) => updateForm({ type: "zipcode", value })}
                    reformatOnBlur={(value) => formatPostalCode(value)}
                    errorOnBlur={(value) => (value && formatPostalCode(value).length !== 7) ? t("zipcode.invalid") : null}
                    label={t("address.ZIP")} autoComplete="postal-code" />
            </div>
        </div>

        <div>
            <Heading mediumTitle={t("motivation.label")} description={t("optional")} />

            <SelectQuestion value={value.howHaveYouHeard}
                className="pt-4"
                setValue={d => updateForm({ type: 'howHaveYouHeard', value: d })}
                id="how-have-you-heard" />
            {value.howHaveYouHeard && value.howHaveYouHeard.sub && value.howHaveYouHeard.sub.options &&
                <SelectQuestion value={value.subHowHaveYouHeard}
                    className="pt-4"
                    setValue={d => updateForm({ type: 'subHowHaveYouHeard', value: d })}
                    placeholder={value.howHaveYouHeard.sub.placeholder}
                    label={value.howHaveYouHeard.sub.label}
                    options={value.howHaveYouHeard.sub.options} />

            }
            {value.howHaveYouHeard && value.subHowHaveYouHeard && value.subHowHaveYouHeard.sub && value.subHowHaveYouHeard.sub.options &&
                <SelectQuestion value={value.subSubHowHaveYouHeard}
                    className="pt-4"
                    setValue={d => updateForm({ type: 'subSubHowHaveYouHeard', value: d })}
                    placeholder={value.subHowHaveYouHeard.sub.placeholder}
                    label={value.subHowHaveYouHeard.sub.label}
                    options={value.subHowHaveYouHeard.sub.options} />

            }
            {value.howHaveYouHeard && value.subHowHaveYouHeard && value.subHowHaveYouHeard.sub && value.subHowHaveYouHeard.sub.format && value.subHowHaveYouHeard.sub.format === "text" &&
                <TextInput defaultValue={value.subSubHowHaveYouHeard}
                    className="pt-4"
                    onChange={d => updateForm({ type: 'subSubHowHaveYouHeard', value: d })}
                    placeholder={value.subHowHaveYouHeard.sub.placeholder}
                    label={value.subHowHaveYouHeard.sub.label} />

            }
            {value.howHaveYouHeard &&
                value.subHowHaveYouHeard &&
                value.subSubHowHaveYouHeard &&
                value.subSubHowHaveYouHeard.sub &&
                value.subSubHowHaveYouHeard.sub.format
                && value.subSubHowHaveYouHeard.sub.format === "text" &&
                <TextInput defaultValue={value.subSubSubHowHaveYouHeard}
                    className="pt-4"
                    onChange={d => updateForm({ type: 'subSubSubHowHaveYouHeard', value: d })}
                    placeholder={value.subSubHowHaveYouHeard.sub.placeholder}
                    label={value.subSubHowHaveYouHeard.sub.label} />

            }

            {value.howHaveYouHeard && value.howHaveYouHeard.sub && value.howHaveYouHeard.sub.format && value.howHaveYouHeard.sub.format === "text" &&
                <TextInput defaultValue={value.subHowHaveYouHeard}
                    className="pt-4"
                    onChange={d => updateForm({ type: 'subHowHaveYouHeard', value: d })}
                    placeholder={value.howHaveYouHeard.sub.placeholder}
                    label={value.howHaveYouHeard.sub.label} />

            }
            <TextInput defaultValue={value.whyGetIn}
                onChange={(value) => updateForm({ type: "whyGetIn", value })}
                label={t("why-get-in.label")}
                multiLines
                className="mt-8"
                labelClassName="text-base"
                rows={8}
                autoComplete="off" />
            <SelectQuestion value={value.tshirtSize}
                className="pt-4 mb-32"
                setValue={d => updateForm({ type: 'tshirtSize', value: d })}
                id="tshirt-size" />
        </div>
        <Button block
            color="black"
            disabled={buttonDisabled}
            size="lg"
            onClick={() => navigate("../preferences")}
        >{t("next")}</Button>
    </form>
}