import { fetchCall } from 'api';

/*
  Search occupations
*/  
export function searchOccupations({search, page, limit, orderBy, orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/occupations",
    isPublic: true,
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}

/*
  Search schools
*/  
export function searchSchools({search, page, limit, orderBy, orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/schools",
    isPublic: true,
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}

/*
  Search school subjects
*/  
export function searchSchoolSubjects({search, usedForPreference, page, limit, orderBy, orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/school-subjects",
    isPublic: true,
    query: {search, usedForPreference, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}

/*
  Get active period of inscription
*/  
export function retrieveActivePeriodTutorInscription(){
  const options = {
    method: "GET", 
    isPublic: true,
    endpoint: "/active-period-of-inscription-tutor"
  }
  return fetchCall(options)
}

/*
  Get pairing types
*/  
export function searchPairingTypes({search, page, limit, orderBy, orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/pairing-types",
    isPublic: true,
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}

/*
  Get documents
*/
export function searchDocuments({search, page, limit, orderBy, orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/portal/ressources/documents",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}