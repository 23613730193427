import React, { useState, useMemo } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

// Components
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import {
    MagnifyingGlassIcon
} from '@heroicons/react/24/outline';
import { MonthView } from 'components/calendar/month';
import { EmptyState } from 'components/core/empty';
import { Pill } from 'components/core/pill';
import { Paginate } from 'components/core/paginate';

// Utils
import classnames from 'classnames'
import { locale,  parseWithTimeZone } from 'utils/locale';
import { timeMonth, timeDay } from 'd3-time';
const fullDayFormat = locale.format("%d %B %Y à %H:%M");

// API
import { searchEvents } from 'api/events';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Tooltip } from 'components/core/tooltip';

function EventCell({ id, event_type, date,  team, visiting_team, receiving_team, num_matches, transmitted, conversation}) {
    const { t } = useTranslation("common");
    return <div className='grid grid-cols-5 gap-3   '>
        <div>
        <p>{fullDayFormat(parseWithTimeZone(date))}</p>
        </div>
        <div className='col-span-3 '>
            <h3 className='text-lg font-medium'>{event_type && event_type.name} {num_matches>1 && t("num-matches", {count: num_matches})}</h3>
            <p>{team && team.name}</p>
            <p>{visiting_team && !receiving_team?
                t("events.visiting-team", { team:visiting_team }) :
                !visiting_team && receiving_team?
                t("events.receiving-team", { team:receiving_team }) :
                !visiting_team && !receiving_team?
                t("events.no-team") :
                t("events.teams", { vTeam: visiting_team, rTeam: receiving_team })}</p>
        {transmitted && id? 
                <Link to={`/play-time/${id}`} className='inline-flex cursor-pointer hover:opacity-80 transition-colors items-center justify-center bg-orange-500 text-white px-2 py-0.5 text-sm rounded-md'>{t("events.transmitted")} 
            <FiArrowRight className='inline ml-2'/></Link>:
        transmitted && !conversation?
        <Tooltip color="light" position="left" delay="100" content={t("events.transmitted-but-not-you-explain")}>
        <span className='relative inline-flex items-center justify-center bg-green-100 text-green-800 px-2 py-0.5 text-sm rounded-md'>{t("events.transmitted-but-not-you")} </span>
        </Tooltip>
            :
            <Link to={`/play-time/${id}`} 
            className='inline-flex cursor-pointer hover:opacity-80 transition-colors items-center justify-center bg-gray-100 text-gray-800 px-2 py-0.5 text-sm rounded-md'>
                {t("events.not-transmitted")} 
            <FiArrowRight className='inline ml-2'/></Link>
            }

        </div>
        

    </div>
}

export function EventGrid() {
    const { t } = useTranslation("common");
    const { user } = useAuth();
    const [date, setDate] = useState(timeMonth.floor(new Date()));
    const params = useMemo(() => ({
        fromDate: timeMonth.floor(date),
        toDate: timeMonth.ceil(date),
        orderBy: "date",
        orderDirection: "desc"
    }), [date]);
    const validateParams = (params) => (params.fromDate && params.toDate && timeDay.count(params.fromDate, params.toDate) >= 25);
    const [results, { loading, error, paging, setPage }] = useSearch(searchEvents, params, { limit: 200, validateParams });
    const events = useMemo(() => results ? results.map((d) => ({ color: '#e6550d', date: parseWithTimeZone (d.date) })) : [], [results]);
    if (!user) return null;
   
    return <div className='w-full my-4'>
        <Heading title={t("events." + user.role)} className={"px-6 !-mb-3"} />
        <Pill.Interval excludeLastDay fromDate={params.fromDate} toDate={params.toDate} className={"mx-6"} />
  
        <div className='grid grid-cols-1 gap-8 divide-x lg:grid-cols-2'>
            <div className=' p-3 space-y-4 '>
                <MonthView numMonths={1}
                    className={"w-full max-w-sm mx-auto"}
                    events={events}
                    onDateChange={(d)=> {setDate(d); if(paging && paging.page !== 1) setPage(1);}}
                    layout="full" />
                <div className='max-w-xl mx-auto'><p className='text-gray-400 text-sm max-w-xl pt-4 hidden md:block'>{t("events.list-description")}</p>
                </div>

            </div>

            <div className='p-3 '>
            <h2 className='text-lg font-medium mb-6'>{t("events.list")}</h2>

                {!results || loading ? <>
                    <Skeleton className={"w-full h-20 my-3"} />
                    <Skeleton className={"w-full h-20 my-3"} />
                </> :
                    results.length === 0 ?
                        <EmptyState Icon={MagnifyingGlassIcon} title={t("events.empty.title")}
                            description={t("events.empty.description", { month: locale.format("%B")(date) })} /> :
                        <div className='space-y-6 '>
                            {results.map((d) => <EventCell key={d.id} {...d} />)}
                        </div>}

                <div className={classnames('px-4', paging && paging.totalPages <= 1 && "hidden")}>
                    {paging && <Paginate setPage={setPage}  {...paging} />}
                </div>
            </div>
        </div>
        {error ? <Error.Text {...error} /> : null}
    </div>
}