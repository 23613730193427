import React, { useCallback } from 'react';

// Utils
import classnames from 'classnames';

// Components
import { Dropdown } from 'components/core/dropdown';
import { TextInputTrailing } from 'components/core/inputs/text-trail';
// Hooks
import { useTranslation } from 'react-i18next';

export function LengthCell({label, selected}){
  return <div className={classnames("rounded-md  text-center")}>
            <p className={classnames("text-gray-800", selected && "font-medium")}>
              {label} min
            </p>
         </div>
}

export function SelectLengthForm({ labels, value, setValue, containerClassName }){
    return <div className={classnames("grid grid-cols-3 gap-3 lg:grid-cols-9 md:gap-3", containerClassName)}>
              {labels.map(d=>
                <div key={d}
                  className={classnames("rounded-md shadow-sm col-span-1 p-2 py-2.5 flex items-center cursor-pointer",
                    value && value===d? "text-white ring-[2pt] ring-orange-500 bg-orange-50 ": "ring-1 ring-gray-300")}
                   onClick={()=>setValue(d)}>
                <div className="flex-1">
                  <LengthCell label={d} selected={value===d}/>
                </div>
              </div>)}
           </div>

}


export function CustomSelectLengthForm({ id, label, trailingText, placeholder, value, setValue, onBlur, size = "md", color="outline", inputClassName, active, ...props}){
  const onChange = useCallback((length)=>{
    if (length.trim() === "") {
      return setValue("");
    }
    const parsedLength = parseInt(length, 10);
    if (isNaN(parsedLength) || parsedLength < 0 ) {
      return setValue("");
    }

    return setValue(parsedLength);
  }, []);

  return <>
    <TextInputTrailing {...props} id={id} label={label} trailingText={trailingText} placeholder={placeholder} value={value} onChange={onChange} onBlur={onBlur} size={size} color={color} inputClassName={inputClassName} active={active} />
  </>

}

export function SelectLengthFormDropdown({ labels, value, setValue, ...props }){
  const { t } = useTranslation("add-study-session");
  return <Dropdown label={`${value +" min" ? value : t("length.label")}`} {...props}>
            {labels.map(d=><Dropdown.Item className={"flex justify-center"} key={d} label={`${d} min`} onClick={()=>setValue(d)}/>)}
      </Dropdown>
}
