import React from 'react';

// Components
import { Heading } from 'components/core/typo';
import { Button } from 'components/core/button';
import { SelectSubjectPreferences, SelectSchoolPreferences } from 'components/tutor-form/select-preference';
import { SelectQuestion } from 'components/tutor-form/select-extra';
import { TextInput } from 'components/core/inputs';

// Hooks
import { useTranslation, Trans } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';


// Contexts
import { TutorFormContext } from 'contexts/tutor-form';

export function PreferenceFormPage(){
  const { value, updateForm } = useContext(TutorFormContext);
  const navigate = useNavigate();
  const {t} = useTranslation("tutor-form");
  return <div className="w-full max-w-2xl px-4 space-y-12">
          <Heading title={t("preferences-form.title")} longDescription={t("preferences-form.description")}/>
          <div>
            <Heading mediumTitle={t("subject-preferences.title")} longDescription={t("subject-preferences.description")}/>
            <SelectSubjectPreferences defaultValue={value.subjectPreferences} setValue={d=>updateForm({type: 'subjectPreferences', value:d})}/>
            <TextInput defaultValue={value.preciseSubjects} 
                    onChange={(value)=>updateForm({type: "preciseSubjects", value})} 
                    label={t("subject-preferences.precise")} 
                    type="text"
                    labelClassName="text-base font-normal text-gray-800 "
                    placeholder={t("subject-preferences.no-precision")}
                    className="mt-4"
                    autoComplete="off"/>
          </div>
          <div>
            
            <Heading className={"!whitespace-pre-wrap"} mediumTitle={t("school-preferences.title")} />
            <SelectQuestion value={value.preferedFormat} 
                className="pb-4"
                setValue={d=>updateForm({type: 'preferedFormat', value:d})}
                id="prefered-session-format"
                label={<Trans t={t} i18nKey="prefered-session-format.label" components={{ul: <ul className='list-disc '/>, li: <li className='mb-1.5 first:mt-1.5 ml-4'/> ,b:<b/>}}/>}
            />
                            
            <Heading longDescription={t("school-preferences.description")}/>

            <SelectSchoolPreferences defaultValue={value.schoolPreferences} setValue={d=>updateForm({type: 'schoolPreferences', value:d})} online={value?.preferedFormat?.slug === "online"}/>
          </div>
          <div>
            <Heading mediumTitle={t("other-preferences.title")}/>
            <div className="space-y-6">
            <SelectQuestion value={value.studentInFrencizationClass} 
                            setValue={d=>updateForm({type: 'studentInFrencizationClass', value:d})}
                            id="student-in-frencization"/>
            {value.studentInFrencizationClass && value.studentInFrencizationClass.slug==="partiellement" &&
              <TextInput defaultValue={value.precisionOnStudentInFrencization} 
                      onChange={(value)=>updateForm({type: "precisionOnStudentInFrencization", value})} 
                      label={t("student-in-frencization.precise")} 
                      type="text"
                      labelClassName="text-base font-normal text-gray-800 "
                      placeholder={t("student-in-frencization.no-precision")}
                      className="p-4 transition-opacity -translate-y-6 bg-gray-100 rounded-md"
                      autoComplete="off"/>}

            <SelectQuestion value={value.studentWithTroubles} 
                            setValue={d=>updateForm({type: 'studentWithTroubles', value:d})}
                            id="student-with-trouble"/>
                      {value.studentWithTroubles && value.studentWithTroubles.slug==="partiellement" &&
              <TextInput defaultValue={value.precisionOnStudentWithTroubles} 
                      onChange={(value)=>updateForm({type: "precisionOnStudentWithTroubles", value})} 
                      label={t("student-with-trouble.precise")} 
                      type="text"
                      labelClassName="text-base font-normal text-gray-800 "
                      placeholder={t("student-with-trouble.no-precision")}
                      className="p-4 transition-opacity -translate-y-6 bg-gray-100 rounded-md"
                      autoComplete="off"/>}
                       <SelectQuestion value={value.haveYouEverBeenTutor} 
                            setValue={d=>updateForm({type: 'haveYouEverBeenTutor', value:d})}
                            id="have-you-ever-been-tutor"/>

            </div>
          </div>

          <TextInput defaultValue={value.comments} 
                    onChange={(value)=>updateForm({type: "comments", value})} 
                    label={t("comments.label")} 
                    labelClassName="text-base"
                    multiLines
                    className="pt-8 mt-8 border-t"
                    rows={4}
                    autoComplete="off"/>

          <Button block 
              color="black" 
              disabled={false}
              size="lg"
              onClick={()=>navigate("../review")}
            >{t("next")}</Button>
         </div>
}
