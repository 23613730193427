import React, { useReducer, useMemo, createContext, useEffect } from "react";
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';

function filterReducer(state, {type, value}){
    var currentState = {...state};
    switch (type){
    case 'toogle-school-subject': {
      if (!currentState.schoolSubjects) currentState.schoolSubjects = [value];
      else {
        const filtedSubjects = currentState.schoolSubjects.filter(d=>d.slug!==value.slug);
        if (filtedSubjects.length===currentState.schoolSubjects.length){
          currentState.schoolSubjects = [...currentState.schoolSubjects, value];
        }
        else currentState.schoolSubjects = filtedSubjects;
      }
      return currentState;
    }
    case 'update-option-value': {
      currentState.options = currentState.options.map(d=>{
        if (d.label===value.label) return value;
        return d;
      });
      return currentState
    }
    case 'set-student-session-length': {
      // For each student, we set the session length in a separate field
      if (!currentState.studentSessionLength) currentState.studentSessionLength = {};
      currentState.studentSessionLength[value.student.ni] = value.sessionLength;
      return currentState;
    }
    case 'set-student-school-subjects': {
      // For each student, we set the session length in a separate field
      if (!currentState.studentSchoolSubjects) currentState.studentSchoolSubjects = {};
      currentState.studentSchoolSubjects[value.student.ni] = value.schoolSubjects;
      return currentState;
    }
    case 'set-student-note': {
      if (!currentState.studentNotes) currentState.studentNotes = {};
      currentState.studentNotes[value.student.ni] = value.studentNotes;
      return currentState;
    }
    case 'toggle-option': {
      if (!currentState.options) currentState.options = [value];
      else {
        const fitleredOptions = currentState.options.filter(d=>d.label!==value.label);
        if (fitleredOptions.length===currentState.options.length){
          currentState.options = [...currentState.options, value];
        }
        else currentState.options = fitleredOptions;
      }
      return currentState;
    }
    case 'toggle-student': {
      if (!currentState.students) currentState.students = [value];
      else {
        const fitleredOptions = currentState.students.filter(d=>d.ni!==value.ni);
        if (fitleredOptions.length===currentState.students.length){
          currentState.students = [...currentState.students, value];
        }
        else currentState.students = fitleredOptions;
      }
      return currentState;
    }
    case 'reset':{
        return {};
    }
    default:
      var d = {...state};
      d[type] = value;
      return d
  }
}

function updateWithDefaultFields(val, defaultFields){
  if (!defaultFields) return val;
  return {...val, ...defaultFields};
}
const AddStudySessionContext = createContext({});

const AddStudySessionContextProvider = ({ children, doNotStore, storageKey, defaultState, defaultFields }) => {
  const location = useLocation();
  const [cachedFilters, setCachedFilters] = useLocalStorage(storageKey || (location.pathname+'_value'));

  const parsedDefaultFilters = useMemo(()=>{
    if (!cachedFilters || doNotStore) return;
    // Date fields must be parsed
    return cachedFilters;
  }, [])

  const [value, updateStudySession] = useReducer(filterReducer, updateWithDefaultFields(parsedDefaultFilters || defaultState || {}, defaultFields));

  const isValid = useMemo(()=>{
    if (!(value.pairing || value.students)) return false;
    if (value.pairing){
      return value.sessionFormat && value.date && value.sessionLength && value.schoolSubjects && value.schoolSubjects.length>0;
    }
    else {
      const validStudents = value.students.map(student=>{
        const subjects = (value.studentSchoolSubjects || {})[student.ni] || ( value && value.schoolSubjects) || [];
        const sessionLength = (value.studentSessionLength || {})[student.ni] || value.sessionLength;
        return subjects.length>0 && sessionLength;
      }).reduce((acc, d)=>acc && d, true);

      return value.date && validStudents
    }
  }, [value]);

  useEffect(()=>{
    if (!doNotStore) setCachedFilters(value);
  }, [value]);

  return (
    <AddStudySessionContext.Provider value={{ studySession:value, updateStudySession, isValid }}>
      {children}
    </AddStudySessionContext.Provider>
  );
};

export { AddStudySessionContext, AddStudySessionContextProvider };