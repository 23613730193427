import React, { useContext, useMemo } from "react";

// Components
import { SEO } from 'components/core/seo';
import { useTranslation } from "react-i18next";
import { Heading, TextError } from "components/core/typo";
import { useSearch } from "hooks/useSearch";
import { searchDocuments } from "api/utils";
import { Skeleton } from "components/core/skeleton";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { Button } from "components/core/button";
import { EmptyState } from "components/core/empty";
import { TutorContext, TutorContextProvider } from "contexts/tutor";
import classNames from "classnames";
import { helpOptions2 } from "mdx";
import Markdown from "markdown-to-jsx";
/*
  Documents base page
*/

const antecedentMarkdown = `
Voici les étapes à suivre pour compléter la section Antécédents judiciaires

1. **Télécharger le document « Antécédents judiciaires »** en cliquant sur le bouton « Télécharger ».
1. **Remplir et signer le document « Antécédents judiciaires »**. Plusieurs options s’offrent à vous :
    - Remplir et signer le document électroniquement en apposant une signature manuelle.
        - Si vous avez un PC, utiliser Adobe Pro ou un autre logiciel de signature en suivant leur procédure de signature manuelle. **Important : Nous ne pouvons accepter de signature dactylographiée.**
        - Si vous avez un MAC, voir la section « Créer et utiliser des signatures » sur le lien suivant: [Lien vers le support d'Apple](https://support.apple.com/fr-ca/guide/preview/prvw35725/mac)
    - Imprimer le formulaire, remplir les informations, apposer une signature manuelle et le scanner. 
1. Prendre **une photo des deux pièces d’identité inscrites au formulaire** dont au moins une avec photographie, date de naissance et signature (assurance-maladie, permis de conduire ou passeport) et une avec une adresse valide.
1. **Envoyer le formulaire signé et les pièces par courriel** à  [interventions@diplomeavantlamedaille.org](mailto:interventions@diplomeavantlamedaille.org).
`;

function DocumentItem({name, description, num_bytes, url}){
    const {t} = useTranslation("common");
    const {tutor} = useContext(TutorContext);
    const actionRequired = name === "Antécédents judiciaires" && tutor && !tutor.antecedent_judiciaire_document_received;
    const showAntecedentMarkdown = name === "Antécédents judiciaires" && tutor && !tutor.antecedent_judiciaire_document_received;
    return <div className={classNames("lg:flex lg:items-center w-full gap-5", actionRequired && "bg-yellow-50 rounded-md p-3 border-2 border-yellow-600")}>
            <div>
                <DocumentTextIcon className="w-8 h-8 lg:mr-3 text-gray-400" aria-hidden="true" />
                </div>
            <div className="mr-auto flex-1">
                {actionRequired && <p className="text-yellow-600 text-sm font-medium">{t("documents.action-required")}</p>}
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {name}
                </h3>
                <p className="text-gray-500">{description}</p>
                {showAntecedentMarkdown && <Markdown className="py-3" options={helpOptions2}>{antecedentMarkdown}</Markdown>}
                <p className="font-medium text-gray-500">{num_bytes}</p>
            </div>
            <Button color="inlineLink" size="link" href={url} className='ml-auto text-right'>{t("download")}</Button>
            </div>
}

function DocumentsPage(){
  const {t} = useTranslation("common");
  const params = useMemo(()=>({}), []);
  const [documents, {error}] = useSearch(searchDocuments, params);
  const fields = useMemo(()=>({fields: ["ni", "antecedent_judiciaire_document_received"]}), []);
  return <TutorContextProvider fields={fields}>
    <div className="px-6 ">
            <SEO title={"documents.title"} description={"documents.description"} />
            <Heading title={t("documents.title")}/>
            {!documents?
                <Skeleton className={"h-24"}/>:
            documents.length===0?
                <EmptyState title={t("no-documents.title")} description={t("no-documents.description")}/>
                :
                <div className="w-full max-w-3xl lg:p-6 space-y-6 lg:space-y-12 @container">
                    {documents.map((d, i)=><DocumentItem key={i} {...d}/>)}
                </div>
            }
            {error && <TextError {...error}/>}
         </div>
         </TutorContextProvider>
}

export {DocumentsPage}

