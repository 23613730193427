import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from "react-i18next";

export function SEO({title, description}) {
    const { t } = useTranslation("seo");
    return (
        <Helmet>
            <title>{t(title)}</title>
            <meta name='description' content={t(description)} />
            <meta name="theme-color" content="#ffffff"/>
            <meta name="background-color" content="#ffffff"/>
            <meta property="og:type" content={"url"} />
            <meta property="og:title" content={t(title)} />
            <meta property="og:description" content={t(description)} />
            <meta property='og:image' content='https://portail.staging.diplomeavantlamedaille.org/assets/logo-HD.png' />
            <meta name="twitter:card" content={"summary_large_image"} />
            <meta name="twitter:title" content={t(title)} />
            <meta name="twitter:description" content={t(description)} />
            <meta name='twitter:image' content='https://portail.staging.diplomeavantlamedaille.org/assets/logo-HD.png' />
        </Helmet>
    )
}