import {timeMonth} from 'd3-time';

export function getCurrentPeriod(){
  const date = timeMonth.offset(new Date(), -6); // 1er août
  return `${date.getFullYear()}-${date.getFullYear()+1}`
}

export function getFullCurrentPeriod(){
  return {slug: getCurrentPeriod(), name: getCurrentPeriod()}
}
