import React from 'react';

// Hooks
import { useContext } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useTranslation } from 'react-i18next';

// API
import { searchPairingTypes } from 'api/utils';

// Components
import { Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { SelectSessionFormatForm } from 'components/select/select-session-format';
import { Error } from 'components/core/typo';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';

export function SelectSessionFormat(){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);
    const [formats, {loading, error}] = useSearch(searchPairingTypes);
    return <div>
            <Heading mediumTitle={t("select-format.title")}
                    description={t("select-format.description")}
            />
            {loading?
            <Skeleton className={"h-40"}/>:
            !formats || formats.length==0?
                <EmptyState title={t("select-format.empty.title")} description={t("select-format.empty.description")}/>:
                <SelectSessionFormatForm value={studySession && studySession.sessionFormat} setValue={(value)=>updateStudySession({type: 'sessionFormat', value})} formats={formats}/>
            }
            {error? <Error.Text {...error}/>: null}
        </div>
}