import { timeParse, timeFormatDefaultLocale } from 'd3-time-format';

// French
export const locale = timeFormatDefaultLocale({
  "dateTime": "%A %e %B %Y à %X",
  "date": "%Y-%m-%d",
  "time": "%H:%M",
  "periods": ["", ""],
  "days": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
  "shortDays": ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
  "months": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
  "shortMonths": ["jan", "fév", "mar", "avr", "mai", "jui", "jul", "août", "sep", "oct", "nov", "déc"]
})


export const dateParse = (d)=>timeParse("%Y-%m-%d")(d);
export const parseWithTimeZone = (d)=>(new Date(new Date(d) - new Date(d).getTimezoneOffset() * 60000));
export const parseDateTimeIgnoreTime = (d)=>(dateParse(d.slice(0, 10)));
