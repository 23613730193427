/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// Utils

// API
import { updateSubscription } from 'api/auth';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button'
import { Error, Heading } from 'components/core/typo'
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'components/core/spinner';
import { FiMail } from 'react-icons/fi';

export function UnsubscribePage(){
  const { t } = useTranslation(["common"]);
  const [searchParams] = useSearchParams();
  const [subscribe, setSubscribe] = useState(false);
  const params = useMemo(()=>({token: searchParams.get("t") || "invalid-token", subscribe}), [searchParams, subscribe]);
  const validateParams = (params)=>(params.token !== "invalid-token");
  const [result, {loading, error}] = useAPI(updateSubscription, params, {validateParams});
  if (loading && validateParams(params)){
    return <CenterViewLayout><Spinner/></CenterViewLayout>
  }

    return <CenterViewLayout>
                <div className='space-y-6'>
                    <div className='relative h-36 w-36 bg-orange-100 text-orange-700 p-8 rounded-full'>
                        <FiMail className="w-full h-full"/>
                    </div>
                    <Heading title={t(`manage-subscription.title`)} 
                    description={t(`manage-subscription.mail-info`)}
                    longDescription={
                        !validateParams(params)?t(`manage-subscription.invalid-token`):
                        error? t(`manage-subscription.invalid-token`):
                        !subscribe?t(`manage-subscription.subscribe-description`): t(`manage-subscription.unsubscribe-description`)}/>

                    <Button color="active" size="sm" block onClick={()=>setSubscribe(d=>!d)} disabled={!validateParams(params)}>
                        {!subscribe?t("manage-subscription.subscribe"):t("manage-subscription.unsubscribe")} 
                    </Button>
                    <p className="text-center text-sm text-gray-500">{result && result.msg}</p>
                    {error? <Error.Text {...error}/>: null}
                </div>

            </CenterViewLayout>
}
