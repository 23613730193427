import React from 'react';

// Components
import { Heading } from 'components/core/typo';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'
import Markdown from 'markdown-to-jsx';
import { EmptyState } from 'components/core/empty';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useParams } from 'react-router-dom';

// API
import { submitTutorForm } from 'api/tutor';

// Utils
import { formatPhoneNumber, formatPostalCode } from 'utils/format';
import { options } from 'mdx';
import classnames from 'classnames';
import { validateEmail } from 'utils/validate';
// import { locale } from 'utils/locale';

// Contexts
import { TutorFormContext } from 'contexts/tutor-form';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

function Item({label, id, value, notRequired, invalid}){
  const {t} = useTranslation("tutor-form");
  return <div className="">
          <label className={classnames("block text-xs font-medium", (value || notRequired)&&(!invalid)? "text-gray-500": "text-red-600")}>
            {label}
          </label>
          <input
            type="text"
            name="name"
            disabled={true}
            id={id}
            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-900 focus:ring-0 truncate"
            placeholder={value|| t("missing-info")}
          />
        </div>
}

function LargeTextItem({label, id, value, invalid}){
  return <div className="md:flex space-x-3 w-full py-2">
          <label className={classnames("block font-medium text-gray-900")}>
            {label}
          </label>
          <p
            type="text"
            name="name"
            disabled={true}
            id={id}
            className={classnames(invalid? "text-red-600 placeholder-red-500 ":"text-gray-600 placeholder-gray-600 ","text-right block bg-none w-full  border-0 p-0 focus:ring-0  ")}
          >{value}
          </p>
        </div>
}


function RowItem({label, id, value, invalid}){
  return <div className="w-full md:flex md:items-center py-2 md:justify-between md:space-x-3">
          <label className={classnames("block font-medium text-gray-900")}>
            {label}
          </label>
          <p
            type="text"
            name="name"
            disabled={true}
            id={id}
            className={classnames(invalid? "text-red-600 placeholder-red-500 ":"text-gray-600 placeholder-gray-600 ","w-full max-w-sm block  bg-none md:text-right border-0 p-0 focus:ring-0  ")}
          >
          {value}
          </p>
        </div>
}


const markdown =
`Une fois la demande soumise, vous recevrez un courriel pour configurer votre accès au portail des tuteur·rices. Nous vous contacterons une fois votre formulaire envoyé pour plus de détails.`


export function ReviewFormPage(){
  const {t} = useTranslation("tutor-form");
  const { value, updateForm } = useContext(TutorFormContext);
  const params = useParams();
  const submitParams = useMemo(()=>({period: params.period, 
                                    ...value,
                                    phone: formatPhoneNumber(value.phone), 
                                    zipcode: formatPostalCode(value.zipcode)}), [value]);
  const [result, {loading, execute, error}] = useAPI(submitTutorForm, submitParams, {immediate: false});
  const validForm = useMemo(()=>
  ( value.firstname &&
    value.lastname &&
    value.email &&
    validateEmail(value.email)&&
    value.phone &&
    value?.gender &&
    value.occupation &&
    value.tutorOrParentIsImmigrant &&
    value.street &&
    value.city &&
    value.zipcode &&
    value.subjectPreferences &&
    Object.values(value.subjectPreferences).filter(d=>d.description!=="none").length>0 &&
    value.preferedFormat &&
    value.studentInFrencizationClass &&
    value.studentWithTroubles &&
    value.haveYouEverBeenTutor &&
    value.howHaveYouHeard &&
    value.whyGetIn &&
    value.tshirtSize &&
    value.schoolPreferences &&
    Object.values(value.schoolPreferences).filter(d=>d.description!=="none").length>0 
      ), [value])

  useEffect(()=>{
    if (result && !error){
      // console.log(updateForm)
      updateForm({type: "reset"})
    }
  }, [result]);


  if (result){
    // Show successful submission
    return <div>
      <Heading Icon={EnvelopeIcon} 
      title={t("success.title")} 
      description={t("success.description")}/>
    <div className='p-3 bg-yellow-100 text-yellow-800 rounded-md max-w-lg mb-3'>
      {t("success.warning")}
      </div>
      <Button to={"/login"} size="md" color="gray">
        {t("success.action")}
      </Button>

    </div>
  }

  return <form className="w-full max-w-2xl px-4 space-y-8 lg:space-y-12" autoComplete="off" type="POST">
          <Heading title={t("review.title")} longDescription={t("review.description")}/>
          <div>
            <div className="grid sm:grid-cols-2 gap-4 pb-4">
              <Item id="firstname" label={t("firstname.label")} value={value.firstname}/>
              <Item id="lastname" label={t("lastname.label")} value={value.lastname}/>
              <Item id="phone" label={t("phone.label")} value={formatPhoneNumber(value.phone)}/>
              <Item id="email"
                invalid={value.email && !validateEmail(value.email)}
                label={`${value.email && !validateEmail(value.email)? t("email.invalid"): t("email.label")}`} value={value.email}/>
              <Item id="occupation" label={t("occupation.label")} value={value.occupation && value.occupation.name}/>
              <Item id="address" label={t("address.label")}
             value={(!value.street || !value.city || !value.zipcode)? "": `${value.street}, ${value.city}, ${formatPostalCode(value.zipcode)}`}/>
              <Item id="tutor-or-parent-is-immigrant"
                  label={t(`tutor-or-parent-is-immigrant.short-label`)}
                  invalid={!value.tutorOrParentIsImmigrant}
                  value={value.tutorOrParentIsImmigrant? value.tutorOrParentIsImmigrant.name:t("not-selected")}/>
            <Item id="gender" label={t("select-gender.label-short")} value={value?.gender?.slug !== "other" ? value?.gender && value?.gender["name"] : value?.gender_precision}/>

            </div>



            <Button size="outline" color="inlineLink" to="../general-info" className="mt-4">{t("modify")}</Button>

          </div>


          <div>
            <Heading noMargin mediumTitle={t("review.subjects-preferences.title")}>
                <Button size="outline" color="inlineLink" to="../preferences">{t("modify")}</Button>
            </Heading>
            <div className="grid divide-y">
             {value.subjectPreferences && Object.values(value.subjectPreferences).filter(d=>d.description!=="none").map(d=>
              <RowItem key={d.slug} id={d.slug} label={d.name} value={t(`subject-preferences.${d.description}`)}/>
              )}
             {(!value.subjectPreferences || Object.values(value.subjectPreferences).filter(d=>d.description!=="none").length===0) &&
              <EmptyState description={t("review.subjects-preferences.no-selection")}/>}
            </div>

            <div className="divide-y pt-8">
              <LargeTextItem id="subject-preferences"
                      notRequired label={t("subject-preferences.precise-short-label")}
                      value={value.preciseSubjects || t("subject-preferences.no-precision")}/>

               <RowItem id={"prefered-session-format"}
                  label={t(`prefered-session-format.short-label`)}
                  invalid={!value.preferedFormat}
                  value={value.preferedFormat? value.preferedFormat.name:t("not-selected")}/>
            <RowItem id={"student-in-frencization"}
                  label={t(`student-in-frencization.short-label`)}
                  invalid={!value.studentInFrencizationClass}
                  value={value.studentInFrencizationClass? value.studentInFrencizationClass.name:t("not-selected")}/>
            <RowItem id={"student-with-trouble"}
                  label={t(`student-with-trouble.short-label`)}
                  invalid={!value.studentWithTroubles}
                  value={value.studentWithTroubles? value.studentWithTroubles.name:t("not-selected")}/>
            <RowItem id={"have-you-ever-been-tutor"}
                  label={t(`have-you-ever-been-tutor.short-label`)}
                  invalid={!value.haveYouEverBeenTutor}
                  value={value.haveYouEverBeenTutor? value.haveYouEverBeenTutor.name:t("not-selected")}/>
            </div>
          </div>

          <div>
            <Heading noMargin mediumTitle={t("review.school-preferences.title")}>
                <Button size="outline" color="inlineLink" to="../preferences">{t("modify")}</Button>
            </Heading>
            <div className=" divide-y">
             {value.schoolPreferences && Object.values(value.schoolPreferences).filter(d=>d.description!=="none").map(d=>
              <RowItem key={d.slug} id={d.slug} label={d.name} value={t(`school-preferences.${d.description}`)}/>
              ) }
             {(!value.schoolPreferences || Object.values(value.schoolPreferences).filter(d=>d.description!=="none").length===0) &&
              <EmptyState description={t("review.school-preferences.no-selection")}/>}
            </div>
          </div>


          <div>
            <Heading noMargin mediumTitle={t("review.others.title")} >
                <Button size="outline" color="inlineLink" to="../general-info">{t("modify")}</Button>
            </Heading>
            <div className="divide-y ">
              <RowItem id={"how-have-you-heard"}
                    label={t(`how-have-you-heard.short-label`)}
                    invalid={!value.howHaveYouHeard}
                    value={value.howHaveYouHeard? value.howHaveYouHeard.name:t("not-selected")}/>
              
              <RowItem id={"tshirt-size"}
                    label={t(`tshirt-size.short-label`)}
                    invalid={!value.tshirtSize}
                    value={value.tshirtSize? value.tshirtSize.name:t("not-selected")}/>
                <LargeTextItem id="why-get-in"
                  label={t("why-get-in.short-label")}
                    invalid={!value.whyGetIn}
                    value={value.whyGetIn? value.whyGetIn:t("not-selected")}/>
              <LargeTextItem id="comments" label={t("comments.short-label")} value={value.comments ||t("comments.placeholder")}/>
            </div>
          </div>


       
          <div className="space-y-3">
            <Markdown options={options}>{markdown}</Markdown>
            <Button block color="black" loading={loading} disabled={!validForm} type="submit" size="lg"
            onClick={(e)=>{e.preventDefault(); execute()}}>{t("submit")}</Button>
            {error? <Error.Text {...error}/>: null}
          </div>
         </form>
}