import React from 'react';

// Components
import { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { FiChevronDown } from 'react-icons/fi';

import classnames from 'classnames';

import { ModalIsOpenContext, ModalIsOpenContextProvider } from 'contexts/modalIsOpen';

const colors = {
  danger: 'text-red-600 hover:text-white hover:bg-red-500 ',
  warning: 'text-yellow-600',
  default: 'text-gray-700',
  white: 'bg-white',
  lightGray: 'bg-gray-50',
  active: 'bg-gray-100 text-gray-900',
}

const styles = {
  inactive: 'bg-gray-100 text-gray-500',
  selected: 'bg-gray-100 text-gray-900',
  white: 'bg-white hover:bg-gray-100 text-gray-600 hover:text-gray-800 border',
  dark: 'bg-gray-800 text-gray-300 hover:text-gray-200 hover:bg-gray-700',
  default: 'bg-gray-100 text-gray-600 hover:text-gray-800 hover:bg-gray-200',
  warning: 'bg-orange-100 text-orange-500 hover:text-orange-800 hover:bg-orange-200',
  warningOutline: 'bg-orange-50 text-orange-500 hover:text-orange-800 hover:bg-orange-200 border border-orange-300',
  defaultDarker: 'bg-gray-200 text-gray-600 hover:text-gray-800 hover:bg-gray-300',
  activeColor: 'bg-orange-100 text-orange-900  hover:text-gray-800 hover:bg-gray-200'
}
function ExternalLink(props){
  return <a href={props.to} {...props}/>
}
export function DropdownItem({ label, description, color='default', className, to, href, icon, onClick, disabled }){
  const LinkComponent = (href || to) && (href? ExternalLink: Link);
  return <Menu.Item>
            {({ active }) => (
              (href || to)?
              <LinkComponent
                to={(href || to)}
                className={classnames(
                  disabled&& 'bg-gray-50 text-gray-500 cursor-not-allowed', active && colors.active, colors[color],
                  'px-3 rounded-md my-1 py-2 text-sm flex items-center w-full text-left',
                  className
                )}
              >
                {icon? icon: null}
                <div className={icon? "pl-3":""}>
                  <span className="block text-gray-500">{description}</span>
                  {label}
                </div>
              </LinkComponent>:
              <button
                onClick={disabled? (()=>{}): onClick? onClick: (()=>{})}
                className={classnames(
                  disabled && 'bg-gray-50 text-gray-500 cursor-not-allowed',  active && colors.active, colors[color],
                  'px-3 rounded-md my-1 py-2 text-sm flex items-center w-full text-left ',
                  className
                )}
              >
                {icon? icon: null}
                <div className={icon? "pl-3":""}>
                  <span className="block text-gray-500">{description}</span>
                  {label}
                </div>
              </button>
            )}
          </Menu.Item>
}

export function DropdownBorder({ className }){
  return <Menu.Item className={className}>
            <hr/>
          </Menu.Item>
}

export function DropdownInfo({title, description}){
  return <Menu.Item className="px-4 py-2 text-sm">
            <div>
              <div>{title}</div>
              {description?<div className="text-gray-500">{description}</div>:null}
            </div>
            
          </Menu.Item>
}

export function DropdownWithoutContext({ children, 
                                          label, 
                                        onlyIcon, 
                                        customButton, 
                                        customButtonClassName, 
                                        withChevron, 
                                        disabled, 
                                        orientation, 
                                        itemClassName, 
                                        itemStyle, 
                                        color='default',
                                        menuClassName, 
                                        menuItemsClassName, 
                                        menuButtonClassName}) {
  const context = useContext(ModalIsOpenContext);

  return (
    <Menu as="div" className={classnames("relative inline-block text-left",  menuClassName)}>
      {({ open }) => (
        <>
          <div>
            {customButton?
              <Menu.Button className={customButtonClassName}>
                {customButton}
                <FiChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>:
              label? 
              <Menu.Button className={classnames("inline-flex items-center text-sm justify-center w-full rounded-md  hover:bg-gray-200 px-4 py-2  font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-500", styles[color], menuButtonClassName)}>
                {label}
                <FiChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>:
              <Menu.Button disabled={disabled} className={classnames(styles[color], "disabled:opacity-30 flex shadow-none border-0 transition-all duration-100 outline-none rounded-lg text-xl p-2")}>
                <span className="sr-only">Open options</span>
               {onlyIcon}
               {withChevron? <FiChevronDown className="-mr-1 ml-0 h-5 w-5" aria-hidden="true" />:null}
              </Menu.Button >
            }
          </div>
          <Transition
            show={context.open && !disabled? true: disabled? false: open}
            as={Fragment}
            enter={"transition ease-out duration-100"}
            enterFrom={orientation==="center"? "opacity-0 scale-95 ":"transform opacity-0 scale-95"}
            enterTo={orientation==="center"? "opacity-100 scale-100 ":"transform opacity-100 scale-100"}
            leave={"transition ease-in duration-75"}
            leaveFrom={orientation==="center"? " opacity-100 scale-100 ":"transform opacity-100 scale-100"}
            leaveTo={orientation==="center"? " opacity-0 scale-95 ":"transform opacity-0 scale-95"}
          >
            <Menu.Items 
              unmount={false}
              className={classnames("z-50 max-w-screen px-1 origin-top-right absolute mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
                     orientation==='left'? 'left-0': (orientation==='center'? 'left-1/2 transform -translate-x-1/2': 'right-0'), menuItemsClassName)}
            >
              <div className={itemClassName} style={itemStyle}>
                {children}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export function Dropdown({...props}){
  return <ModalIsOpenContextProvider><DropdownWithoutContext {...props}/></ModalIsOpenContextProvider>;
}

Dropdown.Item = DropdownItem;
Dropdown.Border = DropdownBorder;
Dropdown.Info = DropdownInfo;
