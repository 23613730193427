


import React, { useEffect } from "react";

// Components
import { Button } from 'components/core/button';
import { Error } from "components/core/typo";
import Markdown from 'markdown-to-jsx';
import { options } from 'mdx';

// API
import { signCyberSecurity } from "api/tutor";

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from "hooks/useAPI";
import { CenterViewLayout } from "components/core/layouts/center";
import { useNavigate } from "react-router";

const markdown = 
`
# Politique de cybersécurité

## Le Diplôme avant la Médaille (DAM)
Afin d’offrir un service de tutorat en ligne sécuritaire aux tuteur∙rices bénévoles du programme de tutorat de l’organisme, Le Diplôme avant la Médaille (DAM) s’engage à:
- Assurer l’application de procédures sécuritaires dans l’encadrement du programme de tutorat en ligne.
- Sensibiliser les employé∙es de DAM et les tuteur∙rices bénévoles aux :
  - Procédures du tutorat en ligne et à l’utilisation optimale de la plateforme offerte.
  - Risques des séances de tutorat en ligne.
  - Comportements à adopter durant les séances de tutorat en ligne. 

- Encourager une communication positive et faciliter les occasions d'apprentissage entre le tuteur ou la tutrice bénévole et les élèves-athlètes via une plateforme adaptée (Skype)
- Favoriser et ecourager l’adoption de comportements cybersécuritaires entre les tuteur∙rices et les élèves-athlètes.
- Encadrer le tutorat en ligne par un suivi régulier auprès du ou de la tuteur∙rice bénévole.
- Entreprendre des actions contre les comportements défavorables, tels que :
  - La cyberintimidation;
  - La publication de messages irrespectueux et/ou injurieux;
  - L’utilisation d’une fausse identité (fraude);
  - L’incitation à un comportement malhonnête;
  - La diffusion sans permission d’images portant atteinte à la réputation (partage des conversations, des vidéos envoyés, etc.).


## Tuteur∙rices bénévoles

Afin d’effectuer des séances de tutorat en ligne sécuritaires durant son implication dans le programme de tutorat DAM, les tuteur∙rices bénévoles s’engagent à :
- Assurer l’application des procédures établies par DAM durant ses séances de tutorat en ligne, telles que :
  - Enregistrer la séance de tutorat en ligne. 
  - Nommer les personnes présentes et la date de la séance au début de chaque rencontre en ligne.
- Encourager une communication positive et offrir des occasions d'apprentissage proactives aux élèves-athlètes via une plateforme adaptée (Skype).
- Favoriser et encourager l’adoption de comportements cybersécuritaires avec les élèves-athlètes.
- Ne pas effectuer les comportements défavorables mentionnés ci-dessus.
- Communiquer toutes les informations pertinentes liées à son tutorat en ligne au ou à la coordonnateur∙rice des interventions responsable de son jumelage.

<br/>

La sécurité de tous et le développement des élèves-athlètes soutenu∙es par DAM sont la priorité. Si le ou la tuteur∙rice bénévole adopte un comportement qui va à l’encontre de la mission et des valeurs de DAM, celui-ci ou celle-ci sera retiré∙e immédiatement du programme de tutorat. La direction de DAM, celle de l’école et le parent de l’enfant seront mis au courant. Dépendamment du comportement, une plainte pourrait également être déposée au service de police. Le non-respect fréquent des procédures et des exigences du tutorat en ligne conduit également à son expulsion. 


`

export function CybersecurityForm(){
  const {t} = useTranslation("tutor-form");
  const [result, {error, execute, loading}] = useAPI(signCyberSecurity, {}, {immediate: false});
  const navigate = useNavigate();

  useEffect(()=>{
    if(result){
      navigate("/sign-required-documents");
    }
  }, [result]);

  return <CenterViewLayout size="3xl">
            <Markdown options={options}>{markdown}</Markdown>
             
                <div className=" p-3 mt-6 mb-3 border shadow-md rounded-md">
                  <div className="flex items-start pb-4">
                    <label htmlFor="accept-cybersecurity-code" className="ml-2 block ">
                      En tant que futur∙e tuteur∙rice bénévole, je reconnais avoir pris connaissance de la politique de cybersécurité ci-dessus et de l’avoir comprise. Je m’engage à la respecter pour toute la durée de mon mandat.
                    </label>
                  </div>
                  <Button block 
                    color="black" 
                    loading={loading}
                    disabled={loading}
                    size="lg"
                    onClick={execute}
                  >{t("accept-cybersecurity")}</Button>

                  {error && <Error.Text {...error}/>}
                </div>
         </CenterViewLayout>
}
