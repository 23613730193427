import React, { useEffect,useRef, useContext, useState } from 'react';

// Markdown
import Markdown from 'markdown-to-jsx';
import { TextInput } from 'components/core/inputs';
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';

// Uils
import { helpOptions } from 'mdx';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'hooks/useDebounce';

// API
import { updateTutor } from 'api/tutor';

// Context
import { TutorContext } from 'contexts/tutor';

const facebookMarkdown = `
<div className="bg-gray-100 rounded-md p-3 mt-2">
💁‍♀️ Pour trouver votre propre lien de profil Facebook:

1. Connectez-vous à votre compte sur votre ordinateur. 
2. Cliquez sur votre nom en haut à droite de votre page d'accueil. Vous serez redirigé sur votre profil principal. 
3. Copiez le lien dans la barre d'adresse, car c'est l'URL de votre profil Facebook.
</div>`

const whyFacebookMarkdown = `
<div className="bg-gray-100 rounded-md p-3 mt-2">
👋 Pourquoi avons-nous besoin de votre URL Facebook?

Votre URL Facebook nous servira à vous mettre en contact plus facilement avec l’élève-athlète sur Messenger et à vous ajouter sur les groupes Facebook des tuteurs pour vous tenir informé plus rapidement des nouvelles importantes concernant le programme de tutorat. 
</div>`

export function ControlledFacebookURLInput({value, setValue}){
    const {t} = useTranslation("tutor-form");
    return <div>
            <Heading mediumTitle={t("facebook.short-label")} />
            <TextInput defaultValue={value} 
            onChange={setValue}
            useLeftIcon={true} 
            success={!!value}
            error={!value}
            label={t("facebook.label")} 
            placeholder={t("facebook.placeholder")} 
            labelClassName="text-base"
            autoComplete="off"/>
            <Markdown options={helpOptions}>{facebookMarkdown}</Markdown>
            <Markdown options={helpOptions}>{whyFacebookMarkdown}</Markdown>
        </div>
}
export function UncontrolledFacebookURLInput({defaultValue}){
    const [value, setValue] = useState(defaultValue || "");
    const debouncedValue = useDebounce(value, 300);
    const isMounted = useRef(false);
    useEffect(()=>{
        if (!isMounted.current){
            isMounted.current = true;
            return;
        }
        updateTutor({facebookAccount: value});
        return ()=>isMounted.current = true;
    }, [debouncedValue]);

    return <ControlledFacebookURLInput value={value} setValue={setValue}/>
}

export function FacebookURLInput(){
    const { tutor, error } = useContext(TutorContext);
    if (error){
        return <Error.Text {...error}/>
    }
    if (!tutor){
        return <Skeleton className="h-42"/>
    }
    return <UncontrolledFacebookURLInput defaultValue={tutor.facebook_account}/>
}