import React, { useMemo } from 'react';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {  Heading } from 'components/core/typo';
import { CustomSelectLengthForm, SelectLengthForm } from 'components/select/select-length';
import { Popover } from 'components/core/popover';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';


const minutes = [20, 25, 30, 35, 40, 45, 50, 55, 60];
export function SelectSessionLength(){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    return  <div>
                <Heading mediumTitle={t("select-length.title")} />

                <div className='justify-end w-full mt-5 mb-4 lg:flex'>
                    <CustomSelectLengthForm inputClassName={"md:min-w-[380px]"} active={(studySession.sessionLength || studySession.sessionLength === 0) && !minutes.includes(studySession.sessionLength)} label={t("select-length-dropdown.insert-duration")} placeholder={t("review.length.label")} trailingText={"min"} value={studySession.sessionLength} setValue={value=>updateStudySession({type: 'sessionLength', value})} />
                </div>

                  <SelectLengthForm labels={minutes}
                    value={studySession.sessionLength}
                    setValue={value=>updateStudySession({type: 'sessionLength', value})}/>



            </div>
}

export function SelectLengthDropdown(){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    return <div className='w-full md:flex'>
        <Popover label={(studySession.sessionLength && studySession.sessionLength + " min" || t("review.length.label") )} >
             <div className='my-4'>
                <CustomSelectLengthForm active={(studySession.sessionLength || studySession.sessionLength === 0) && !minutes.includes(studySession.sessionLength)} placeholder={t("review.length.label")} trailingText={"min"} size='xs' label={t("select-length-dropdown.insert-duration")} value={studySession.sessionLength} setValue={value=>updateStudySession({type: 'sessionLength', value})} />
            </div>
            <div className='mb-4 text-sm'>
                {t("select-length-dropdown.favorites")}
            </div>
            <SelectLengthForm labels={minutes}
                containerClassName="!gap-3 !grid-cols-3"
                value={studySession.sessionLength}
                setValue={value=>updateStudySession({type: 'sessionLength', value})}/>

        </Popover>
    </div>
}

export function SelectLengthDropdownStudentBased({student}){
    /* Select the length of the study session for a specific student*/
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    let studySessionLength = useMemo(()=> {
      return studySession.sessionLength
    }, [studySession])

    const studentSessionLength = useMemo(()=> {
      if ((studySession.studentSessionLength || {})[student.ni] === undefined){
        return studySession.sessionLength
      }
      return (studySession.studentSessionLength || {})[student.ni]

    }, [studySession, student])

    return <div className='flex items-center w-28'>
        <CustomSelectLengthForm placeholder={t("review.length.label")} trailingText={"min"} color="activeFilled" size='sm' inputClassName='!flex !items-center' value={studentSessionLength} setValue={sessionLength=>updateStudySession({type: 'set-student-session-length', value: {sessionLength, student}})} onBlur={(v)=> {
            if (v == "") {
              updateStudySession({type: 'set-student-session-length', value: {studySessionLength, student}})
            }
          }}
          active={(studentSessionLength || studentSessionLength === 0) && studentSessionLength != studySessionLength}
        />
    </div>
}

