import React from 'react';

// Hooks
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';

// Components
import { SelectLengthDropdownStudentBased } from 'components/add-study-session/select-length';
import { SelectSchoolSubjectsDropdownStudentBased } from 'components/add-study-session/select-subjets';
import { EmptyState } from 'components/core/empty';
import { UserPlusIcon,  EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { SelectOptions, SelectedOptionsList } from 'components/add-study-session/select-student-options';
import { RightSidebar } from 'components/core/layouts/sidebar';
import { Heading } from 'components/core/typo';
import { Dropdown } from 'components/core/dropdown';

// Icons 
import { PiListPlus as AddNoteIcon } from 'react-icons/pi';
import { Button } from 'components/core/button';

export function StudentsList() {
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);
    const { t } = useTranslation("add-study-session")
    const [open, setOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState({});
    if (!studySession.students || studySession.students.length === 0) {
        return <EmptyState title={t("students.no-selection.title")}
            description={t("students.no-selection.description")}
            Icon={UserPlusIcon}
        >
        </EmptyState>;
    }
    return <div className='divide-y'>
        {studySession.students.map((student) => (
            <div key={student.ni} className='flex items-center justify-between py-6 space-x-3 lg:py-3'>
                <div className='items-center justify-between w-full lg:flex'>
                    <div className='flex items-center mb-1 space-x-3 lg:mb-0'>
                        <p className='font-medium whitespace-nowrap'>{student.name}</p>
                        <SelectedOptionsList options={(studySession.studentNotes || {})[student.ni]}/>
                    </div>
                    <div className='flex items-center w-full space-x-3 lg:justify-end'>
                        <SelectSchoolSubjectsDropdownStudentBased student={student} />
                        <SelectLengthDropdownStudentBased student={student} />
                        <Button onClick={() => {setSelectedStudent(student); setOpen(true)}} color={"default"} size="toolbarIcon">
                            <AddNoteIcon />
                        </Button>
                        <Dropdown onlyIcon={<EllipsisVerticalIcon className='w-5 h-5'/>} menuItemsClassName="w-48 max-w-sceen">
                            <Dropdown.Item
                                color="danger"
                                label={t("students.actions.remove")}
                                onClick={() => updateStudySession({ type: 'toggle-student', value: student })}/>
                        </Dropdown>
                    </div>
                </div>




            </div>

        ))}
        <RightSidebar open={open} setOpen={setOpen}>
            <StudentInfo student={selectedStudent} />
        </RightSidebar>
    </div>
}


export function StudentInfo({student}) {
    const studentTeams = (student.teams||[]).map(team=>team.team.name).join(', ');
    return <>
            <div className='flex justify-center text-center'>
                <Heading title={student.name} description={studentTeams} />
            </div>
            <div className='pl-2 '>
                <div className='my-6'>
                    <SelectOptions student={student} />
                </div>
            </div>

    </>

}