

import React, { useEffect } from "react";

// Components
import { Button } from 'components/core/button';
import { Error } from "components/core/typo";
import Markdown from 'markdown-to-jsx';
import { options } from 'mdx';

// API
import { signContratEngagement } from "api/tutor";

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from "hooks/useAPI";
import { CenterViewLayout } from "components/core/layouts/center";
import { useNavigate } from "react-router";

const markdown = 
`
# Contrat d'engagement

Dans le respect des valeurs de collaboration, d'équité, d'engagement et de fierté de l'organisme **Le Diplôme avant la Médaille (DAM)**, il est demandé aux tuteur∙rices bénévoles de:
- Bien comprendre le travail qui leur est proposé avant de l’accepter et être en mesure d’assurer une **aide continue et durable** à l'élève-athlète ou aux élèves-athlètes soutenu∙es par DAM pour l’année scolaire en cours, soit de la mi-octobre à la mi-avril au minimum.
- Assurer un **tutorat hebdomadaire d’une durée minimale d’une heure** (sauf sous avis contraire) à l’élève-athlète ou aux élèves-athlètes qu’ils ou elles aident. 
- Participer positivement aux **formations données** par DAM.
- **Accepter d’être dirigé∙es et supervisé∙es** par la personne-ressource de DAM attitrée.
- **Communiquer toutes les informations pertinentes** concernant leur tutorat et l’élève-athlète ou les élèves-athlètes qu’ils ou elles accompagnent à la personne-ressource de DAM attitrée pour maximiser l’impact des actions des intervenant∙es de l’organisme auprès des jeunes.
- En tenant compte de leurs connaissances, adapter leur approche pédagogique en **conséquence des méthodes des enseignant∙es** et ne pas critiquer celles-ci. 
- **Enregistrer toutes les séances de tutorat en ligne** effectuées avec l’élève-athlète ou les élèves-athlètes pour le tutorat à distance.
`

export function ContractForm(){
  const {t} = useTranslation("tutor-form");
  const [result, {error, execute, loading}] = useAPI(signContratEngagement, {}, {immediate: false});
  const navigate = useNavigate();

  useEffect(()=>{
    if(result){
      navigate("/sign-required-documents");
    }
  }, [result]);


  return <CenterViewLayout size="3xl">
            <Markdown options={options}>{markdown}</Markdown>
             
                <div className=" p-3 mt-6 mb-3 border shadow-md rounded-md">
                  <div className="flex items-start pb-4">
                    <label htmlFor="accept-contract-code" className="ml-2 block ">
                      En tant que futur·e tuteur·rice bénévole, je reconnais avoir pris connaissance du contrat d’engagement ci-dessus et de l’avoir compris. Je m’engage à le respecter pour toute la durée de mon implication bénévole.
                    </label>
                  </div>
                  <Button block 
                    color="black" 
                    loading={loading}
                    disabled={loading}
                    size="lg"
                    onClick={execute}
                  >{t("accept-contract")}</Button>

                  {error && <Error.Text {...error}/>}
                </div>
         </CenterViewLayout>
}

