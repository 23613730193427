import React, { createContext, useMemo } from "react";

// Hooks
import { useAPI } from "hooks/useAPI";

// API
import { useLocation } from "react-router-dom";
import { hasUnseenConversations } from "api/auth";

const UnseenConvContext = createContext({});

const UnseenConvContextProvider = ({ children }) => {
  const location = useLocation();
  const params = useMemo(() => ({}), [location.pathname]);
  const [unseen, props] = useAPI(hasUnseenConversations, params);

  return (
    <UnseenConvContext.Provider value={{ unseen: unseen && unseen.num_unseen_conv, ...props }}>
      {children}
    </UnseenConvContext.Provider>
  );
};

export { UnseenConvContext, UnseenConvContextProvider };