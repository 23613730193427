import React, { useMemo } from 'react';

// Components
import { Heading, Error } from 'components/core/typo'
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';

// API
import { listRecentConnections } from 'api/auth';
import { Table } from 'components/core/table';

import { locale, parseWithTimeZone} from 'utils/locale';


export function RecentConnectionsList(){
    const { t } = useTranslation('common');
    const params = useMemo(()=>({orderDirection: 'DESC', orderBy: 'created_at'}), []);
    const [connections, {error}] = useSearch(listRecentConnections, params, {limit:10});
    const headers = useMemo(()=>[
        {title: t("status"), field: (d=>d? t(`auth-status.${d.status}`): 'status')},
        {title:  t("login-at"),
        field: (d=>d? locale.format("%d %B %Y à %H:%M:%S")(parseWithTimeZone(d.created_at)):'created_at')
        },
    ], []);

    return <div className="space-y-3">
              <Heading mediumTitle={t("recent-connections.title")} longDescription={t("recent-connections.description")}/>
              {!connections?
                 <Skeleton className={"h-24"}></Skeleton>:
                !connections.length?
                <EmptyState title={t("recent-connections.no-recent-connections")}/>:
                <Table headers={headers} data={connections} indexingKey={(_,index)=>index}/>
              }
              {error? <Error.Text {...error}/>: null}
             </div>
  }
