// API
import { useMemo } from 'react';
import { timeMonday, timeDay, timeMinute } from 'd3-time';

export function useAbstractPeriods(crons, props={}){
  const date = useMemo(()=>timeMonday.floor(new Date()), []);
  const events = useMemo(()=>{
    if (!crons) return [];
    return crons.map(d=>{

      const fc = (props.asList? d[0]: d.from_cron).split(" ");
      const fromMin = parseInt(fc[0]);
      const fromHour = parseInt(fc[1]);
      const fromDay = parseInt(fc[4]);

      var toMin, toHour, toDay;
      if (!(props.asList? d[0]: d.to_cron)){
        toMin = fromMin
        toHour = fromHour+1
        toDay =fromDay
      }
      else {
        const tc = (props.asList? d[0]: d.to_cron).split(" ");
        toMin = parseInt(tc[0]);
        toHour = parseInt(tc[1]);
        toDay = parseInt(tc[4]);
      }

      const t0 = timeDay.offset(date, fromDay);
      t0.setHours(fromHour)
      t0.setMinutes(fromMin)

      const t1 = timeDay.offset(date, toDay);
      t1.setHours(toHour)
      t1.setMinutes(toMin)
      
      // const t0 = timeMinute.offset(date, fromDay*24*60 + fromHour*60 + fromMin);
      // const t1 = timeMinute.offset(date, toDay*24*60 + toHour*60 + toMin)
      return {date: t0, duration: timeMinute.count(t0, t1), ...props }
    })
  }, [date, crons]);

  return [date, events]
}