import { fetchCall } from 'api';

/*
  Search students
*/  
export function searchStudents({search, page, limit, orderBy, orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/portal/students",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}
