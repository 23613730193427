import React from 'react';

// Components
import { Container } from 'components/core/container';
import { Heading } from 'components/core/typo';
import { SelectSchools } from 'components/settings/select-schools';

// Hooks
import { useTranslation } from 'react-i18next';

export function SchoolsPage(){
    const {t} = useTranslation("settings");
    return <Container className={"pt-6 pb-12"}>
                <Heading title={t("schools.title")} />
                <SelectSchools/>
            </Container>
}