// Method to decide if the error requires to refresh the token
function shouldRefreshToken(error){
    return error.status === 403;
}

// Fetch method with error handling
function fetchWithErrorHandling(url, options){
    return fetch(url, options).then((res)=>{
        if (res.status==200){
            return res.json()
        }
        throw res
    })
}

// Refresh token
function refreshToken(){
    const url = new URL(`${process.env.REACT_APP_API_SERVER_URL}/portal/auth/update-token`)
    return fetchWithErrorHandling(url, {method: "POST", credentials: "include"})
}

// Create a method to configure the fetch with the refresh token logic
function configureRefreshFetch({ refreshToken, shouldRefreshToken }) {
    let refreshingTokenPromise = null;
    
    return (url, options, isPublic) => {
        if (isPublic) {
            return fetchWithErrorHandling(url, options)
        }
        if (refreshingTokenPromise !== null) {
            return (  
                refreshingTokenPromise
                .then(() => fetchWithErrorHandling(url, options))
                // Even if the refreshing fails, do the fetch so we reject with
                // error of that request
                .catch(() => fetchWithErrorHandling(url, options))
            )
        }
        return fetchWithErrorHandling(url, options)
            .catch(error => {
            if (shouldRefreshToken(error)) {
                if (refreshingTokenPromise === null) {
                refreshingTokenPromise = new Promise((resolve, reject) => {
                    refreshToken()
                    .then(() => {
                        refreshingTokenPromise = null
                        resolve()
                    })
                    .catch(refreshTokenError => {
                        refreshingTokenPromise = null
                        reject(refreshTokenError)
                    })
                })
                }
            return refreshingTokenPromise
            .catch(error => {
                // // If refreshing fails, continue with original error
                // if (window.location.pathname && !window.location.pathname.includes("/login")&& !window.location.pathname.includes("/inscription-tuteur")){
                //     window.localStorage.removeItem("user")
                //     window.location.href = `/login?error=idle_timeout&ref=${encodeURIComponent(window.location.pathname+window.location.search)}`;
                // }
                throw {...error, status: 403, action: 'logout'}
            })
            .then(() => {
                // Refetch.
                return fetchWithErrorHandling(url, options)
            })
        } else {
            throw error
        }
        })
    }
}

const authFetch = configureRefreshFetch({
    shouldRefreshToken,
    refreshToken
})

export { authFetch };