import React, { useState, useMemo } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useTranslation } from 'react-i18next';

// Components
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { PairingList } from 'components/widgets/pairing_list';
import {
    WifiIcon,
    UserGroupIcon,
    MagnifyingGlassIcon,
    BookOpenIcon,
    BoltSlashIcon,
    EyeSlashIcon,
    VideoCameraSlashIcon,
    ClockIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    ExclamationCircleIcon,
    BellAlertIcon
} from '@heroicons/react/24/outline';
import { MonthView } from 'components/calendar/month';
import { EmptyState } from 'components/core/empty';
import { Disclosure } from '@headlessui/react'
import { Button } from 'components/core/button';
import { Pill } from 'components/core/pill';
import { Paginate } from 'components/core/paginate';

// Utils
import classnames from 'classnames'
import { locale, dateParse } from 'utils/locale';
import { timeMonth, timeDay } from 'd3-time';
const fullDayFormat = locale.format("%d %B %Y");

// API
import { searchStudySessions } from 'api/study_sessions';

const noteToIcon = {
    "absence-denregistrement-a-son-aide-aux-devoirs": VideoCameraSlashIcon,
    "camera-fermee": EyeSlashIcon,
    "manque-de-concentration-a-son-aide-aux-devoirs": BoltSlashIcon,
    "manque-de-materiel-a-son-aide-aux-devoirs": BookOpenIcon,
    "retard-a-son-aide-aux-devoirs": ClockIcon,
    "les-sujets-etudies-ne-sont-pas-en-echec": BellAlertIcon,
    "aide-aux-devoirs-trop-breve": ExclamationCircleIcon
}

function SessionFormat({ session_format }) {
    if (!session_format) return null;
    if (session_format.code === "en-ligne") {
        return <p><WifiIcon className='inline h-5 mr-2' />{session_format.name}</p>
    }
    return <p><UserGroupIcon className='inline h-5 mr-2' />{session_format.name}</p>
}

function StudySessionCell({ student, period, length_in_minutes, notes, subjects, session_format }) {
    const { t } = useTranslation("common");
    return <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (<>
            <Disclosure.Button className={classnames('p-3 hover:bg-gray-100 w-full flex items-center justify-between text-left', open ? "bg-gray-100 rounded-t-md" : "rounded-md ")}>
                <div>
                    <p className='font-medium'>{student.name}</p>
                    <p>{fullDayFormat(dateParse(period))}</p>
                    <SessionFormat session_format={session_format} />
                </div>
                <div className='flex items-center justify-right'>
                    {(notes || []).map(d => {
                        const Icon = noteToIcon[d.code.code] || BellAlertIcon;
                        return <Icon key={d.id} className="inline h-6 p-1 mb-1 mr-1 text-white bg-red-500 rounded-md" />
                    }
                    )}
                    {open ? <ChevronUpIcon className='h-4' /> : <ChevronDownIcon className='h-4' />}
                </div>
            </Disclosure.Button>
            <Disclosure.Panel className={classnames(open && "bg-gray-200  p-3 rounded-b-md")}>
                <div className='grid grid-cols-2 gap-3'>
                    <p className='font-medium'><ClockIcon className='inline h-5 mb-1 mr-1' />{t("minutes", { count: length_in_minutes })}</p>
                    <p className='font-medium'><BookOpenIcon className='inline h-5 mb-1 mr-1' />{subjects.map(d => d.name).join(', ')}</p>

                    {(notes || []).map(d => {
                        const Icon = noteToIcon[d.code.code]  || BellAlertIcon;
                        return <p key={d.id} ><Icon className="inline h-5 mb-1 mr-1 text-red-500" /> {d.code.name}</p>
                    }
                    )}
                </div>
            </Disclosure.Panel></>)}
    </Disclosure>
}

export function TutoringGrid() {
    const { t } = useTranslation("common");
    const [date, setDate] = useState(timeMonth.floor(new Date()));
    const params = useMemo(() => ({
        fromDate: timeMonth.floor(date),
        toDate: timeMonth.ceil(date),
        orderBy: "period",
        orderDirection: "desc"
    }), [date]);
    const validateParams = (params) => (params.fromDate && params.toDate && timeDay.count(params.fromDate, params.toDate) >= 25);
    const [results, { loading, error, paging, setPage }] = useSearch(searchStudySessions, params, { limit: 200, validateParams });
    const events = useMemo(() => results ? results.map((d) => ({ color: '#e6550d', date: dateParse(d.period) })) : [], [results]);
    return <div className='w-full my-4 max-w-7xl mx-auto'>
        <Heading title={t("study-sessions")} className={"px-6 !-mb-3"} />
        <Pill.Interval excludeLastDay fromDate={params.fromDate} toDate={params.toDate} className={"mx-6"} />
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2'>
            <div className='row-start-1 p-3 space-y-4 lg:col-start-2 lg:max-w-sm lg:mx-auto'>
                
                <MonthView numMonths={1}
                    className={"w-full max-w-sm mx-auto"}
                    events={events}
                    onDateChange={(d)=> {setDate(d); if(paging && paging.page !== 1) setPage(1);}}
                    layout="full" />
                <Button block color="active" size="md" className='max-w-sm mx-auto mt-3 lg:flex' to={"/ajouter-tutorat"}>{t("add-study-session")}</Button>
                <PairingList className="hidden lg:block" />
            </div>

            <div className='p-3 lg:col-start-1 lg:row-start-1 flex-1'>

                {!results || loading ? <>
                    <Skeleton className={"w-full h-12 my-3"} />
                    <Skeleton className={"w-full h-12 my-3"} />
                    <Skeleton className={"w-full h-12 my-3"} />
                </> :
                    results.length === 0 ?
                        <EmptyState Icon={MagnifyingGlassIcon} title={t("no-study-session.title")}
                            description={t("no-study-session.description", { month: locale.format("%B")(date) })} /> :
                        <div className='space-y-1'>
                            {results.map((d) => <StudySessionCell key={d.id} {...d} />)}
                        </div>}
                <div className={classnames('px-4', paging && paging.totalPages <= 1 && "hidden")}>
                    {paging && <Paginate setPage={setPage} {...paging} />}
                </div>
                <PairingList className="block lg:hidden" />

            </div>



        </div>
        {error ? <Error.Text {...error} /> : null}
    </div>
}