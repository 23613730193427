import React from 'react';

// Utils
import classnames from 'classnames';

// Components

// Hooks

export function PairingCell({student}){
  return <div className={classnames("rounded-md p-2 md:flex items-center")}>
          <div className={classnames("flex-1")}>
            <div className="flex items-center justify-between">
            <p className="text-gray-800 text-lg font-medium">
              {student && student.name}
            </p>
             
            </div>
           
          </div>
         </div> 
}

export function SelectPairingForm({ pairings, value, setValue }){
    return <div className="grid gris-cols-1 md:grid-cols-2 gap-3 md:gap-3">
              {pairings.map(d=>
                <div key={d.id} 
                  className={classnames("rounded-md  shadow-sm col-span-1 p-2 py-2.5 flex items-center cursor-pointer",
                    value && value.id===d.id? "text-white ring-[2pt] ring-orange-500 bg-orange-50 ": "ring-1 ring-gray-300")}
                   onClick={()=>setValue(d)}>
                  <div className="w-6 text-center " >
                    <input type="radio" className="text-orange-500 " id={d.id} name={"pairing"} checked={(value && value.id===d.id) || false} readOnly/>
                  </div>
                <div className="flex-1 ml-3">
                  <PairingCell {...d}/>
                </div>
              </div>)}
           </div>
  
  }
   