import React, { useMemo, useEffect, createContext, useCallback } from "react";

// Hooks
import { useAPI } from 'hooks/useAPI';

// API
import { logout, retrieveProfile } from 'api/auth';
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext({loading: true});

const AuthContextProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const emptyParams = useMemo(()=>({}), []);
  const [user, {loading, setResult, execute, error}] = useAPI(retrieveProfile, emptyParams);
  const [, logoutProps] = useAPI(logout, emptyParams, {immediate: false, onResult: ()=>setResult()});

  const loginWithRedirect = useCallback(()=>{
    if (location.pathname === '/logout') return;
    return navigate(`/login?msg=login_redirect&ref=${encodeURIComponent(location.pathname+location.search)}`);
   }, [location]);

  useEffect(()=>{
    if (location.pathname === '/logout') return;
    execute();
  }, [location.pathname])

  useEffect(()=>{ 
    if (error){
      if ((error.status === 403 ) || (error.action === "logout")){
          return navigate(`/login?error=idle_timeout&ref=${encodeURIComponent(location.pathname+location.search)}`);
      }
    }
  }, [error]);

  return (
    <AuthContext.Provider value={{ isConnected: !!user, user, error, loading, loadingLogout: logoutProps.loading, setUser: setResult, logout: logoutProps.execute, loginWithRedirect }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
