import React from "react";
import { SEO } from 'components/core/seo';

// Context
import { AddStudySessionContextProvider } from "contexts/add-study-session";

// Components
import { AddStudySessionPageWithContext } from "components/add-study-session/add-multiple-sessions";

export function AddRecuperationPage(){
  return <AddStudySessionContextProvider doNotStore defaultState={{sessionLength: 30}}>
            <SEO title={"recuperation.title"} description={"recuperation.description"} />
            <AddStudySessionPageWithContext sessionType={"recuperation"}/>
        </AddStudySessionContextProvider>
}