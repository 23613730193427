// API
import { useMemo } from 'react';
import { format } from 'd3-format';


export function translateCron(d){
      const fc = d.split(" ");
      const minutes = parseInt(fc[0]);
      const hour = parseInt(fc[1]);
      const dayInt = parseInt(fc[4]);
      const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt]
      return `${day} à ${format("02")(hour)}:${format("02")(minutes)}`
}
/*
useTranslationRegularCrons
'0 20 * * 4' -> Le jeudi à 20:00
*/
export function useTranslationRegularCrons(value){
  const translated = useMemo(()=>{
    if (!value) return [];
    return value.map(d=>{
      const fc = d.split(" ");
      const minutes = parseInt(fc[0]);
      const hour = parseInt(fc[1]);
      const dayInt = parseInt(fc[4]);
      const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt]
      return `${day} à ${format("02")(hour)}:${format("02")(minutes)}`
    })
  }, [value]);
  return translated;
}

export function useTranslationRegularCron(cron){
  const value = useTranslationRegularCrons(cron? [cron]: null);
  return value? value[0]: null;
}

/*
useTranslationRegularIntervalCrons
{from:'0 20 * * 4', to:''} -> {'fromStr': "Le jeudi à 20:00', ...}
*/
export function useTranslationRegularIntervalCrons(value, {asList=false}={}){
  const translated = useMemo(()=>{
    if (!value) return [];
    return value.map(d=>{
      const fc = (asList?d[0]: d.from_cron).split(" ");
      const fromMinutes = parseInt(fc[0]);
      const fromHour = parseInt(fc[1]);
      const fromDayInt = parseInt(fc[4]);
      const fromDay = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][fromDayInt]

      const tc = (asList?d[1]: d.to_cron).split(" ");
      const toMinutes = parseInt(tc[0]);
      const toHour = parseInt(tc[1]);
      const toDayInt = parseInt(tc[4]);
      const toDay = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][toDayInt]

      return {...d, 
            fromMinutesInt: parseInt(fromDayInt)*24*60 + fromHour*60 + fromMinutes,
            fromStr: `${fromDay} à ${format("02")(fromHour)}:${format("02")(fromMinutes)}`, 
            toStr: `${toDay} à ${format("02")(toHour)}:${format("02")(toMinutes)}`,
            baseStr: `${fromDay} de ${format("02")(fromHour)}:${format("02")(fromMinutes)} à ${format("02")(toHour)}:${format("02")(toMinutes)}`}
    })
  }, [value]);

  return translated;
}

