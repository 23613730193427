import React, { useContext } from 'react';

// Markdown
import { TextInputDebounce } from 'components/core/inputs';
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';


// Hooks
import { useTranslation } from 'react-i18next';

// API
import { updateTutor } from 'api/tutor';

// Context
import { TutorContext } from 'contexts/tutor';
import { formatPostalCode } from 'utils/format';
import { useAPI } from 'hooks/useAPI';

export function ControlledAddressInput({defaultValue, setStreet, setCity, setZipcode}){
    const {t} = useTranslation("tutor-form");
    return <div className=" py-8 space-y-4">
            <Heading mediumTitle={t("address.label")} />
            <TextInputDebounce defaultValue={defaultValue.street} 
                onChange={setStreet} 
                label={t("address.street")} 
                delay={300}
                autoComplete="street-address" />
            <div className="grid grid-cols-2 space-x-3">
                <TextInputDebounce defaultValue={defaultValue.city} 
                    delay={300}
                    onChange={setCity} 
                    label={t("address.city")} />
                <TextInputDebounce defaultValue={defaultValue.zipcode}
                    onChange={setZipcode}
                    reformatOnBlur={(d) => formatPostalCode(d)}
                    delay={300}
                    errorOnBlur={(d) => (d && formatPostalCode(d).length !== 7) ? t("zipcode.invalid") : null}
                    label={t("address.ZIP")}
                    autoComplete="postal-code" />
            </div>
        </div>
}
export function UncontrolledAddressInput({defaultValue}){
    const [, {execute}] = useAPI(updateTutor, {}, {immediate: false})
    return <ControlledAddressInput defaultValue={defaultValue} 
                    setCity={(city)=>execute({city})}
                    setZipcode={(zipcode)=>execute({zipcode})}
                    setStreet={(street)=>execute({street})}/>
}

export function AddressInput(){
    const { tutor, error } = useContext(TutorContext);
    if (error){
        return <Error.Text {...error}/>
    }
    if (!tutor){
        return <Skeleton className="h-42"/>
    }
    return <UncontrolledAddressInput defaultValue={tutor}/>
}