import React from 'react';

// Components
import { Container } from 'components/core/container';
import { Heading } from 'components/core/typo';
import { SelectSubjects } from 'components/settings/select-subjects';

// Hooks
import { useTranslation } from 'react-i18next';

export function SubjectPage(){
    const {t} = useTranslation("settings");
    return <Container className={"pt-6 pb-12"}>
                <Heading title={t("subject-preferences.title")} />
                <SelectSubjects/>
            </Container>
}