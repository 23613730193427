import React, { useMemo } from 'react';

// Components
import { Hero, TextError } from 'components/core/typo';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';
import { Button } from 'components/core/button';

// Icons
import { HiCheckCircle as CompletedIcon } from 'react-icons/hi2';
import { HiExclamationCircle as IncompletedIcon } from 'react-icons/hi2';
import { HiArrowsRightLeft as PendingIcon } from 'react-icons/hi2';
import { FiClock as ClockIcon } from 'react-icons/fi';
import { FiUser as InfoIcon } from 'react-icons/fi';
import { HiOutlineBookOpen as BookIcon} from 'react-icons/hi';
import { PiGraduationCapDuotone as AcademicCapIcon} from 'react-icons/pi';
import { AiOutlineEdit as EditIcon} from 'react-icons/ai';
import { HiOutlineUserGroup as UserGroupIcon} from 'react-icons/hi';
import { FaBalanceScaleLeft as BalanceScaleIcon} from 'react-icons/fa';

function StatusElement({title, description, isCompleted, isPending, to, href, Icon, className, ...props}){
    const {t} = useTranslation("common");
    return <Link to={to || href || ""} className={classnames('border rounded-md p-3', 
    isPending?'bg-orange-100 border-0': isCompleted? "bg-gray-100 border-0": "border-yellow-500 border-2", 
    (!to && !href) && "cursor-default", className)} {...props}>
                {isPending && <p className='flex items-center justify-end  text-orange-600'>{t("tutor-inscription-status.pending")}<PendingIcon className='ml-2 text-2xl'/></p>}
                {!isPending && isCompleted && <p className='flex items-center justify-end  text-green-600'>{t("tutor-inscription-status.completed")}<CompletedIcon className='ml-2 text-2xl'/></p>}
                {!isPending && !isCompleted && <p className='flex items-center justify-end text-yellow-600'>{t("tutor-inscription-status.incompleted")}<IncompletedIcon className='ml-2 text-2xl'/></p>}
                <h3 className='flex items-center text-lg font-medium'>{Icon && <Icon className="mr-2 "/>}{title}</h3>
                <p className='text-gray-500'>{description}</p>
                {!isPending &&(to || href) && <Button color="inlineLink" size="link" href={href} to={to} className='mt-2 text-right'>{t("tutor-inscription-status.action")}</Button>}
            </Link>
  
}

export function TutorInscriptionStatus({report, error }){
    const {t} = useTranslation("common");

    const availabilitesCompleted = useMemo(()=>report && report.schedule_availabilities && report.schedule_availabilities.length>0, [report]);
    const daysAvailable = useMemo(()=>{
        if (!report || !report.schedule_availabilities || report.schedule_availabilities.length===0) return;
        if (report.schedule_availabilities.length>4){
            return report.schedule_availabilities.slice(0,4)
                                .map(d=>d.from)
                                .sort((a,b)=>{
                                        const week_days = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
                                        const a_day = week_days.indexOf(a.split(' ')[0]);
                                        const b_day = week_days.indexOf(b.split(' ')[0]);
                                        if (a_day<b_day) return -1;
                                        if (a_day>b_day) return 1;
                                        return 0;
                                })
                                .join(', ') + " " + t("tutor-inscription-status.availabilities.and-more", {count: report.schedule_availabilities.length-4});
        }
        const all_avail = report.schedule_availabilities.map(d=>d.from);
        // all_avail = ["lundi 8h", "lundi 9h", "mardi 17h"]

        // Order by week day
        const order_all_avail = all_avail.sort((a,b)=>{
            const week_days = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
            const a_day = week_days.indexOf(a.split(' ')[0]);
            const b_day = week_days.indexOf(b.split(' ')[0]);
            if (a_day<b_day) return -1;
            if (a_day>b_day) return 1;
            return 0;
        });
        return order_all_avail.join(', ');
    }, [report]);

    const subjectsCompleted = useMemo(()=>report && report.subjects && report.subjects.length>0, [report]);
    const subjectsAvailable = useMemo(()=>{
        if (!report || !report.subjects || report.subjects.length===0) return;
        return report.subjects.map(d=>d.name).join(', ');
    }, [report]);

    const schoolsCompleted = useMemo(()=>report && report.schools && report.schools.length>0, [report]);
    const schoolsAvailable = useMemo(()=>{
        if (!report || !report.schools || report.schools.length===0) return;
        return report.schools.map(d=>d.name).join(', ');
    }, [report]);

    const allCompleted = useMemo(()=>(availabilitesCompleted && 
                                        subjectsCompleted &&
                                         schoolsCompleted && 
                                         report &&
                                         !!report.membership_document_received && 
                                         !!report.formation_completed && 
                                         !!report.antecedent_judiciaire_document_received
                                         && report.facebook_account
                                         ), [availabilitesCompleted, subjectsCompleted, schoolsCompleted]);

    return <div className='py-12'>
                <Hero title={allCompleted?t("tutor-inscription-status.title.completed"): t("tutor-inscription-status.title.pending")}
                        subtitle={t("tutor-inscription-status.subtitle")}
                        description={allCompleted ? t("tutor-inscription-status.description-completed") : t("tutor-inscription-status.description")}>
                {report && 
                    <div className="mx-auto mt-8 ">
                        <dl className="grid max-w-xl grid-cols-1 md:p-3 gap-x-8 gap-y-3 md:gap-y-8 md:max-w-none md:grid-cols-2 ">

                            {/*  Make sure the tutor are availabilites */}
                            <StatusElement title={t("tutor-inscription-status.availabilities.title")}
                                            description={
                                                availabilitesCompleted? t("tutor-inscription-status.availabilities.description.completed", {days: daysAvailable}):
                                                t("tutor-inscription-status.availabilities.description.incompleted")}
                                            isCompleted={availabilitesCompleted}
                                            to={"/settings/availabilities"}
                                            Icon={ClockIcon}
                                            />

                            {/* Make sure the tutor has subjects */}
                            <StatusElement title={t("tutor-inscription-status.subjects.title")}
                                            description={subjectsCompleted? t("tutor-inscription-status.subjects.description.completed", {subjects: subjectsAvailable}):
                                                t("tutor-inscription-status.subjects.description.incompleted")}
                                            isCompleted={subjectsCompleted}
                                            to={"/settings/subjects"}
                                            Icon={BookIcon}
                                            />
                            {/*  Make sure the tutor has schools */}
                            <StatusElement title={t("tutor-inscription-status.schools.title")}
                                            description={schoolsCompleted? t("tutor-inscription-status.schools.description.completed", {schools: schoolsAvailable}):
                                                t("tutor-inscription-status.schools.description.incompleted")}
                                            isCompleted={schoolsCompleted}
                                            to={"/settings/schools"}
                                            Icon={AcademicCapIcon}
                                            />
                                {/* Membership */}
                            <StatusElement title={t("tutor-inscription-status.membership.title")}
                                            description={report.membership_document_received?
                                                t("tutor-inscription-status.membership.description.completed"):
                                                t("tutor-inscription-status.membership.description.incompleted")}
                                            isCompleted={!!report.membership_document_received}
                                            target="_blank"
                                            href={"https://docs.google.com/forms/d/e/1FAIpQLSfQFEaq9LulTPpAd9c4eTgxgZN3gTXi_8hfFd--1M3Gcae1jg/viewform"}
                                            Icon={EditIcon}
                                            />

                        <StatusElement title={t("tutor-inscription-status.formation.title")}
                                description={report.formation_completed?
                                    t("tutor-inscription-status.formation.description.completed"):
                                    t("tutor-inscription-status.formation.description.incompleted")}
                                isCompleted={!!report.formation_completed}
                                Icon={UserGroupIcon}
                                />
                                {/* Antecedent judiciaire */}   
                            <StatusElement title={t("tutor-inscription-status.antecedent-judiciaire.title")}
                                            description={!report? null:
                                                (!report.antecedent_judiciaire_document_received 
                                                && !report.antecedent_judiciaire_document_conform
                                                && !report.antecedent_judiciaire_document_validated)?
                                                    t("tutor-inscription-status.antecedent-judiciaire.description.incompleted"):
                                                (report.antecedent_judiciaire_document_received 
                                                && !report.antecedent_judiciaire_document_conform
                                                && !report.antecedent_judiciaire_document_validated)
                                                ?
                                                t("tutor-inscription-status.antecedent-judiciaire.description.received"):
                                                (report.antecedent_judiciaire_document_received 
                                                && report.antecedent_judiciaire_document_conform
                                                && !report.antecedent_judiciaire_document_validated)?
                                                t("tutor-inscription-status.antecedent-judiciaire.description.conform"):
                                                (report.antecedent_judiciaire_document_received 
                                                && report.antecedent_judiciaire_document_conform
                                                && report.antecedent_judiciaire_document_validated)?
                                                t("tutor-inscription-status.antecedent-judiciaire.description.completed"):
                                                t("tutor-inscription-status.antecedent-judiciaire.description.incompleted")}
                                            isCompleted={report &&  !!report.antecedent_judiciaire_document_received}
                                            isPending={report && report.antecedent_judiciaire_document_received && (!report.antecedent_judiciaire_document_conform|| !report.antecedent_judiciaire_document_validated)}
                                            to={report&&!report.antecedent_judiciaire_document_validated &&"/documents"}
                                            Icon={BalanceScaleIcon}
                                            />

                            <StatusElement title={t("tutor-inscription-status.information.title")}
                                description={report.facebook_account?
                                    t("tutor-inscription-status.information.description.completed"):
                                    t("tutor-inscription-status.information.description.incompleted")}
                                            to={"/settings/info"}
                                            isCompleted={!!report.facebook_account}
                                Icon={InfoIcon}
                                />
                        </dl>
                    </div>
                }
                </Hero>

                {error && <TextError {...error}/>}
            </div>
}