import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { refreshAccessToken } from 'api/auth';
import { useAPI } from './useAPI';

// Settings
const timeout = 600_000; // The time before the onIdle event will be emitted. (10 minutes = 600 000ms)
const promptBeforeIdle = 60_000; // How long before idle to emit the onPrompt event.


export function useConfirmActivityPrompt(){ 
    const [remaining, setRemaining] = useState(timeout);
    const [timeSinceRefresh, setTimeSinceRefresh] = useState(0);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [, {execute}] = useAPI(refreshAccessToken, {}, {immediate: false});

    const onIdle = () => {
        // User is idle since timeout. Should logout
        setOpen(false);
        navigate(`/logout?error=idle_timeout&ref=${encodeURIComponent(window.location.pathname+window.location.search)}`);
    }

    const onActive = () => {
        // User is active
        setOpen(false);
    }

    const onPrompt = () => {
        setOpen(true);
        // User is idle since timeout - promptBeforeIdle
        // Show a prompt to the user to keep them active
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        crossTab: true, // Sync with other tabs
        name: 'common-inactivity-monitor', // Use the same name accross tabs so all tabs are synced
        syncTimers: 500, // The throttle will sync the event across tabs every 500ms
        throttle: 5000 // Throttle the onActive and onIdle events to 5000ms (preserve CPU)
    })

    useEffect(() => {
        // Auto refresh the token if the user is non-stop active during 10 minutes but without any request
        if (timeSinceRefresh > 600_000) {
            // Refresh the token every 10 minutes
            execute();
            setTimeSinceRefresh(0);
        }
    }, [timeSinceRefresh]);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
            setTimeSinceRefresh(d=>d+500);
        }, 500)
        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate();
        execute();
        setTimeSinceRefresh(0);
    }
    
    return {open, setOpen, handleStillHere, remaining}
}