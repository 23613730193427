import React from 'react';

// Components
import { Container } from 'components/core/container';
import { Heading } from 'components/core/typo';

// Hooks
import { useTranslation } from 'react-i18next';
import { SelectDispo, SelectDispoFormat } from 'components/settings/select-dispo';

export function PreferencePage(){
    const {t} = useTranslation("settings");
    return <Container className={"pt-6 pb-12"}>
                <Heading title={t("availabilities.title")} />
                <SelectDispoFormat/>
                <SelectDispo/>
            </Container>
}