import React, { createContext } from "react";

// Hooks
import { useAPI } from 'hooks/useAPI';

// API
import { retrieveTutor } from "api/tutor";

const TutorContext = createContext({});

const TutorContextProvider = ({ fields, children }) => {
  const [tutor, {setResult, ...props}] = useAPI(retrieveTutor, fields);

  return (
    <TutorContext.Provider value={{ tutor, setTutor: setResult, ...props }}>
      {children}
    </TutorContext.Provider>
  );
};

export { TutorContext, TutorContextProvider };