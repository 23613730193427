import React from 'react';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from 'components/core/button'

export function Modal({open, setOpen, children, onClickOutside, disableOutsideClick=false}){
    const handleClickOutside = ()=>{
        if (disableOutsideClick) return
        setOpen(false)
        onClickOutside?.()
    }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto" onClick={handleClickOutside}>
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div onClick={(e)=>e.stopPropagation()} className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function ModalBasic({Icon, title, description, submitLabel, onClick, loading, disabled}){
    return <>
            <div>
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-orange-100 rounded-full">
                    {Icon && <Icon className="w-6 h-6 text-orange-600" aria-hidden="true" />}
                </div>
                <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    {title}
                </h3>
                <div className="mt-2">
                    <p className="text-gray-500 ">
                    {description}
                    </p>
                </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <Button disabled={disabled} loading={loading} block color="active" size="md" onClick={onClick}>
                    {submitLabel}
                </Button>
            </div>
            </>
}