import React from "react";

// Components
import { Button } from 'components/core/button';

function SubNavCategory({className, title, children}) {
  return <>
    <label className={`text-sm text-gray-500 block ${className}`}>{title}</label>
    {children}
  </>;
}

function SubNavItem({label, to, Icon}) {
  return <>
    <Button key={label}
      size="subSideNav"
      nav
      color={(isActive) => !isActive ? "subSideNav" : "activeSubSideNav"}
      to={to}>
      <Icon className="h-5 w-5 mr-2" /> <span>{label}</span>
    </Button>
  </>;
}

export { SubNavCategory, SubNavItem };