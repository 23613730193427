import React from 'react';

// Components
import { WeekView } from 'components/calendar/week';
import { Heading } from 'components/core/typo';
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';
import { SelectCronIntervalWithConfirm } from 'components/tutor-form/select-cron';

// Hooks
import { useTranslationRegularIntervalCrons } from 'hooks/crons'
import { useAbstractPeriods } from 'hooks/useAbstractPeriods';
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback } from 'react';


export function SelectWeeklyAvailabilities({value, setValue}){
  const { t } = useTranslation('common');
  const [date, events] = useAbstractPeriods(value);
  const translatedCrons = useTranslationRegularIntervalCrons(value);
  const sortedCrons = useMemo(()=>translatedCrons.sort((a,b)=>a.fromMinutesInt>b.fromMinutesInt), [translatedCrons])
  const validation = useCallback((value)=>{
    if (!value) return;
    const [minutes, hours] = value.from_cron.split(" ");
    const [minutes2, hours2] = value.to_cron.split(" ");
    const time = parseInt(hours)*60+parseInt(minutes);
    const time2 = parseInt(hours2)*60+parseInt(minutes2);
    if (time<11*60){
      return {code: "availability-must-be-after-11h00"}
    }
    if ((time>13*60+15)& (time<15*60+45)){
      return {code: "availability-cannot-be-between-13h15-and-15h45"}
    }
    if (time>20*60){
      return {code: "availability-cannot-be-between-after-20h"}
    }
    if ((time2-time>=0) && (time2-time<60)){
      return {code: "period-must-be-60min-minimum"}
    }
    return;
  }, [])
  return <div>
            <Heading title={t("interval.title")}>
            </Heading>
              <SelectCronIntervalWithConfirm validation={validation} onConfirmation={d=>setValue(value? [...value, d]: [d])}/>
            
              {translatedCrons.length===0?
                <EmptyState title={t("interval.no-selection")}/>:
                <div className="space-y-1 my-2 pb-2">
                {sortedCrons.map((d,i)=>
                <div key={`${i}-${d.baseStr}`} className="flex items-center justify-between px-3 py-1 border rounded-md">
                  <p className='font-medium'>{d.baseStr}</p>
                  <Button color="delete" size="md" onClick={()=>setValue(value.filter((val, ind)=>ind!==i))}>{t("interval.delete")}</Button>
                </div>
                )}</div>}
            <div className="mt-3">
              <WeekView  abstract date={date} events={events} numTicks={6} minHour={10} maxHour={20}/>
            </div>
         </div>
}