import React from 'react';

// API
import { retrieveUnsignedDocuments } from 'api/tutor';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Components
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { FiCheck } from 'react-icons/fi';

// Utils
import { locale, parseWithTimeZone } from 'utils/locale';
import { Button } from 'components/core/button';
import { Navigate } from 'react-router';
import { Spinner } from 'components/core/spinner';

const toDocuments = {
    "code_ethique": "/sign-required-documents/code-ethique",
    "contrat_engagement": "/sign-required-documents/contrat-engagement",
    "cyber_security_policy": "/sign-required-documents/cybersecurity",
}
const documentGender = {
    "code_ethique": "male",
    "contrat_engagement": "male",
    "cyber_security_policy": "female"
}

function Document({name, value}){
    const {t} = useTranslation("tutor-form");
    return <div className='flex items-center justify-between'>
            <div>
                <p className=' font-medium '>{t(`documents.${name}`)}</p>
                {value && <p className='text-gray-500'>{t(`documents.signed-on`, {context: documentGender[name], date: locale.format("%x")(parseWithTimeZone(value))})}</p>}
            </div>
            {value? <FiCheck className='text-green-500 h-5 w-5'/>: null}
        </div>
}

export function UnsignedDocumentsList(){
    const {t} = useTranslation("tutor-form");
    const [results, {loading, error}] = useAPI(retrieveUnsignedDocuments);

    const nextUnsignedDocument = results && results.find(d=>!d.value);
    
    if (results && !nextUnsignedDocument){
        // Nothing to sign
        return <Navigate to="/"/>
    }
    if (loading ){
        return <Spinner className="mx-auto"/>
    }
    return <div >
            <Heading title={t("sign-documents.title")} longDescription={t("sign-documents.description")}/>
            {(!results && !error) ? <Skeleton className={"h-72"}/>:
                error? <Error.Text {...error}/>:
                <div className='space-y-3 pt-6 pb-10'>
                    {results.map(d=><Document key={d.name} {...d}/>)}
                </div>         
                }
            <Button color="black" 
                    size="md" 
                    to={toDocuments[nextUnsignedDocument && nextUnsignedDocument.name]} 
                    block>
                {t("documents.continue", {context: documentGender[nextUnsignedDocument&& nextUnsignedDocument.name], to:t(`documents.${nextUnsignedDocument&& nextUnsignedDocument.name}`)})}
            </Button>
            </div>
}