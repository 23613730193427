import React from 'react';

// Hooks
import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {  Heading } from 'components/core/typo';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';
import { SelectOptionsForm } from 'components/select/select-options';
import { BoltSlashIcon, BookOpenIcon, ClockIcon, EyeSlashIcon, VideoCameraSlashIcon } from '@heroicons/react/24/outline';

export function SelectOptions(){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    const options = useMemo(()=>[
        {
            title: t("options.absence-enregistrement.title"),
            description: t("options.absence-enregistrement.description"),
            placeholder: t("options.absence-enregistrement.placeholder"),
            label: "absence-denregistrement-tutorat",
            Icon: VideoCameraSlashIcon
        },
        {
            title: t("options.camera-fermee.title"),
            description: t("options.camera-fermee.description"),
            placeholder: t("options.camera-fermee.placeholder"),
            label: "camera-fermee-tutorat",
            Icon: EyeSlashIcon
        },
        {
            title: t("options.manque-concentration.title"),
            description: t("options.manque-concentration.description"),
            placeholder: t("options.manque-concentration.placeholder"),
            label: "manque-de-concentration-tutorat",
            Icon: BoltSlashIcon
        },
        {
            title: t("options.manque-materiel.title"),
            description: t("options.manque-materiel.description"),
            placeholder: t("options.manque-materiel.placeholder"),
            label: "manque-de-materiel-tutorat",
            Icon: BookOpenIcon
        },
        {
            title: t("options.retard.title"),
            description: t("options.retard.description"),
            placeholder: t("options.retard.placeholder"),
            label: "retard-tutorat",
            Icon: ClockIcon
        }
    ], []);
    return  <div>
                <Heading mediumTitle={t("select-options.title")}
                        description={t("select-options.description")}
                />
                <SelectOptionsForm options={options}
                        values={studySession.options}
                        onChange={value=>updateStudySession({type: 'update-option-value', value})}
                        onClick={value=>updateStudySession({type: 'toggle-option', value})}/>
            </div>
}
