import React from "react";

// Components
import Markdown from 'markdown-to-jsx';
import { optionsRessources } from "mdx";
import { Skeleton } from "components/core/skeleton";
import { TextError } from "components/core/typo";

// Icons
import { BsLink45Deg as LinkIcon } from 'react-icons/bs';
import { HiArrowUpRight as ArrowIcon } from 'react-icons/hi2';

// Hooks
import { useSearch } from "hooks/useSearch";
import { useMemo } from "react";

// API
import { searchLinks } from "api/ressources";

export function Resources() {
    const params = useMemo(()=>({}), []);
    const [links, { error }] = useSearch(searchLinks, params);
        
    return (
        <div className="py-4 sm:py-10">
            { error && <TextError className={"mb-4"} {...error}></TextError>}
            {
                !links ? <Skeleton className={"h-20"}/> : <ResourcesGrid resources={links} />
            }
        </div>
    )
}

export function ResourcesGrid({ resources }) {
    return (
        <section className="grid grid-cols-2 rounded-md max-w-7xl">
            {
                resources.map(({name, description, url, img}, index) => (
                    <div key={index} onClick={() => window.open(url, '_blank')} className="col-span-2 min-h-[200px] pb-10 py-3 px-0 md:odd:pr-6 border-b md:col-span-1 md:even:pl-10 md:odd:border-r group hover:cursor-pointer">
                        <div className="flex flex-col items-start">
                            <div className="flex justify-between w-full text-gray-300">
                                <div className="h-12 w-28">
                                    {img && img != "" ? <img className="object-contain object-left w-full h-full" src={img} alt={name} /> : <div className="flex items-center h-full text-black">{name}</div>}
                                </div>
                                <ArrowIcon className="text-xl transition-all group-hover:text-gray-500" />
                            </div>
                            <div>
                                <div className="flex items-center my-3 text-orange-500 hover:underline hover:cursor-pointer"><LinkIcon className="mr-1 text-xl" />{url}</div>
                            </div>
                            <div className="text-base font-light tracking-tight text-justify text-gray-600">
                                <Markdown options={optionsRessources}>
                                    {description}
                                </Markdown>
                            </div>
                        </div>
                    </div>
                ))
            }
        </section>
    )
}

