import React, { useState, createContext } from "react";

const ModalIsOpenContext = createContext({open: false});

const ModalIsOpenContextProvider = ({ children }) => {
  const [open, setOpen] = useState();

  return (
    <ModalIsOpenContext.Provider value={{ open, setOpen }}>
      {children}
    </ModalIsOpenContext.Provider>
  );
};

export { ModalIsOpenContext, ModalIsOpenContextProvider };