import React from 'react';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Heading } from 'components/core/typo';

// Hooks
import { locale, dateParse } from 'utils/locale';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';
import { SelectDateUncollapsed, SelectDate as SelectDateDropdown } from 'components/select/select-date';

export function SelectDate({noDescription}){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    return  <div>
                <Heading mediumTitle={t("select-date.title")}
                        description={!noDescription && t("select-date.description")}
                />
               <SelectDateUncollapsed 
               value={studySession.date && dateParse(studySession.date)} 
               setValue={value=>updateStudySession({type: 'date', value: value?locale.format("%x")(value): null})}
               maxDateSelection={new Date()}
               />
            </div>
}

export function SelectDateButton(){
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    return  <SelectDateDropdown 
                    value={studySession.date && dateParse(studySession.date)} 
                    setValue={value=>updateStudySession({type: 'date', value: value?locale.format("%x")(value): null})}
                    maxDateSelection={new Date()}
                    />
}