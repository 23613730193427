import React from 'react';

// API
import { searchPairings } from 'api/pairings';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';

// Components
import { Heading, Error } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';

function PairingCell({student, prefered_type, active_period}){
    return <div className='p-3 border  rounded-md flex justify-between'>
         <div>
            <p className='font-medium'>{student.name}</p>
            <p>{prefered_type.name}</p>
        </div>
        <p>{active_period && active_period.name}</p>
    </div>
}

export function PairingList({className}){
    const {t} = useTranslation("common");
    const [pairings, {error}] = useSearch(searchPairings);

    return <div className={classnames('space-y-3', className)}>
                <Heading mediumTitle={t("pairing-list.title")}/>
        {!pairings?   
            <Skeleton className={"h-40"}/>:
            pairings.length===0?
                <EmptyState title={t("no-pairing.title")} 
                            description={t("no-pairing.description")}/>:
            pairings.map(d=><PairingCell key={d.id} {...d}/>)
        }
                {error? <Error.Text {...error}/>: null}
    </div>
}