import React from 'react';

// Components
import { Heading } from 'components/core/typo';
import { TextInput } from 'components/core/inputs';
import { Button } from 'components/core/button';
import { Error, TextInfo } from 'components/core/typo';
// API
import { changePassword } from 'api/auth';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// Utils
import { validatePassword } from 'utils/validate';

export function ChangePassword(){
    const {t} = useTranslation("settings");
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [result, {loading, execute, error}] = useAPI(changePassword, {oldPassword, newPassword}, {immediate: false });
    return <div>
                <Heading mediumTitle={t("security.change-password.title")} longDescription={t("security.change-password.help")}/>
                <TextInput label={t("security.change-password.old-password")} 
                            onChange={setOldPassword} 
                            shouldClear={!!result}
                            type="password"/>
                <TextInput label={t("security.change-password.new-password")} 
                            errorOnBlur={(val)=>(!validatePassword(val)? t("security.change-password.invalid-password"):null)}
                            className={"mt-2"}
                            shouldClear={!!result}
                            onChange={setNewPassword} 
                        type="password"/>

                <Button className={"mt-4"} 
                        disabled={!oldPassword || !newPassword || !validatePassword(newPassword) } 
                        color="black"
                        size={"md"}
                        loading={loading}
                        onClick={execute}
                    >{t("security.change-password.submit")}
                </Button>
                {error? <Error.Text {...error}/>: null}
                {result? <TextInfo>{t("security.change-password.success")}</TextInfo>: null}
            </div>
}