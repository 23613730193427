import React, { useContext } from "react"
import { EventContext, EventContextProvider } from "contexts/event"
import { Skeleton } from "components/core/skeleton";
import { PlayTimeView } from "components/play-time";
import { MessageList } from "components/conversation/messages";
import { locale, parseWithTimeZone } from "utils/locale";
import { EmptyState } from "components/core/empty";
import { useTranslation } from "react-i18next";
import { FiAlertTriangle } from "react-icons/fi";


function UncontrolledEventView(){
    const {t} = useTranslation();
    const {event, loading} = useContext(EventContext);
    const playTime = (event && event.conversation && event.conversation.play_time) || (event && event.play_time);
    const transmitted = event && event.conversation && event.conversation.event && event.conversation.event.transmitted;

    if (loading || !event){
        return <div className="p-4 w-full">
            <Skeleton className='h-16 mb-4 w-full'/>
            <Skeleton className='h-64 w-full'/>
        </div>
    }
    return <div className="w-full overflow-auto">
         <div className='p-3'>
          <h1 className='text-3xl font-bold'>{ event && event.event_type && event.event_type.name} du {locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(event && event.date))}</h1>
          {transmitted ?
              <p className='text-lg'>Transmis le {locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(transmitted))}</p>:
              <p className='mt-3 bg-yellow-100 text-yellow-800 px-3 py-1 rounded-md w-fit'>
                <FiAlertTriangle className="inline mb-1 mr-2"/>{t("play-time.not-transmitted")}</p>
              }
          </div>
        {event.conversation && <MessageList messages={event.conversation.messages} loading={loading}/>}
        {!playTime?
        <EmptyState title={t("play-time.unavailable.title")} description={t("play-time.unavailable.description")}/>:
        <PlayTimeView playTime={playTime} transmitted={transmitted } />}
        </div>
}

export function EventViewPage(){
    return <EventContextProvider>
                <UncontrolledEventView/>
    </EventContextProvider>
}
