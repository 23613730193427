import React from 'react';

// Utils
import classnames from 'classnames';

// Components
import { SelectMenu } from 'components/core/inputs';
import { useTranslation } from 'react-i18next';

// Hooks

export function SubjectCell({name, selected}){
  return <div className={classnames("rounded- p-0 md:flex items-center")}>
          <div className={classnames("flex-1")}>
            <p className={classnames("text-gray-800", selected? "font-medium": "")}>
              {name}
            </p>
          </div>
         </div>
}

export function SelectSchoolSubjectsForm({ subjects, values, onClick }){
    return <div className="grid gap-3 gris-cols-1 md:grid-cols-2 md:gap-3">
              {subjects.map(d=>
                {
                const selected = (values||[]).filter(e=>e.slug===d.slug).length>0;
                return (<div key={d.slug}
                          className={classnames("rounded-md  shadow-sm col-span-1 p-2 py-2.5 flex items-center cursor-pointer",
                          selected? "text-white ring-[2pt] ring-orange-500 bg-orange-50 ": "ring-1 ring-gray-300")}
                          onClick={()=>onClick(d)}>
                          <div className="w-6 text-center " >
                            <input type="checkbox" className="text-orange-500 " id={d.slug} name={"subject"} checked={selected} readOnly />
                          </div>
                        <div className="flex-1 ml-3">
                          <SubjectCell selected={selected} {...d}/>
                        </div>
                    </div>)})}
           </div>

  }

  export function SelectSchoolSubjectsFormDropdown({ subjects, value, setValue, color, name }){
    const {t} = useTranslation("add-study-session")
    return <SelectMenu values={(subjects||[]).map(d=>d.name)}
                       value={(value||[]).map(d=>d.name)}
                       setValue={(v)=>setValue((subjects||[]).filter(d=>v.includes(d.name)))}
                       optionsClassName="w-screen max-w-[140pt]"
                       color={color}
                       format={d=>d}
                       name={name}
                       buttonLabel={(value || []).length===0? t("select-subject.menu.label", {count: (value || []).length}):
                                (value || []).length<4? (value || []).map(d=>d.name).join(', '):
                              t("select-subject.menu.label", {count: (value || []).length})}
                       indexKey={null}
                       multiple={true}/>
  }