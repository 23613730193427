import React from 'react';
import { Fragment } from 'react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import { FiCheck, FiChevronDown, FiChevronUp } from 'react-icons/fi'
import classnames from 'classnames';

const styles = {
  "default": "border border-gray-300 bg-white py-2 lg:py-3 shadow-sm text-base",
  "whiteOutline": "border text-slate-700 border-gray-200 bg-white py-2 text-sm ",
  "warning":"bg-orange-50 text-orange-500 hover:text-orange-800 hover:bg-orange-200 border border-orange-300 py-2 text-sm ",
  "gray": "bg-gray-100 hover:bg-gray-200 text-gray-600 text-sm py-2 font-medium"
}
export function SelectMenu({label,
                            values,
                            value,
                            buttonLabel,
                            setValue,
                            placeholder,
                            className,
                            description,
                            name,
                            format,
                            color="default",
                            indexKey="id",
                            warning,
                            multiple,
                            optionsClassName,
                            labelClassName="text-sm font-medium text-gray-700"}) {
  return (
    <Listbox value={value} onChange={setValue} name={name} multiple={multiple}>
      {({ open }) => (
        <div  className={className}>
          <Label className={classnames(labelClassName)}>{label}</Label>
          <div className="relative mt-0">
            <ListboxButton
              className={classnames("relative cursor-pointer rounded-md px-4  w-full text-left  focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500", styles[color])}>
              <span className={classnames("block truncate mr-8", !value && "text-gray-400")}>{buttonLabel? buttonLabel: value?value.name: placeholder}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <div className="flex flex-col items-center justify-center w-8 h-8 -space-y-1" aria-hidden="true">
                  <FiChevronUp/>
                  <FiChevronDown/>
                </div>
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={"Fragment"}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className={classnames("absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm", optionsClassName)}>
                {(values || []).map((d) => (
                  <ListboxOption
                    key={indexKey? d[indexKey]: d}
                    className={({ active }) =>
                      classnames(
                        active ? 'bg-orange-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={d}
                  >
                    {({ value, active, selected }) => (
                      <>
                        <span className={classnames(value ? 'font-semibold' : 'font-normal', 'block truncate sm:text-sm')}>
                          {format ? format(d): d.name}
                        </span>

                        {selected ? (
                          <span
                            className={classnames(
                              active ? 'text-white' : 'text-orange-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <FiCheck className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        {description && <p className="text-sm text-gray-500">{description}</p>}
              {warning && <p className="text-sm text-yellow-600">{warning}</p>}
        </div>
      )}
    </Listbox>
  )
}