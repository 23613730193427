/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useAuth } from 'hooks/useAuth';

// Utils
import { validatePassword } from 'utils/validate';

// API
import { resetPassword, validateResetPasswordToken } from 'api/auth';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error, Heading } from 'components/core/typo'
import { Navigate, useSearchParams } from 'react-router-dom';
import { Spinner } from 'components/core/spinner';
import { FiAlertTriangle } from 'react-icons/fi';

export function ResetPasswordPage({namespace="reset-password"}){
  const { t } = useTranslation(["auth"]);
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const {setUser}= useAuth();
  const [searchParams] = useSearchParams();
  const params = useMemo(()=>({token: searchParams.get("t")}), [searchParams]);
  const [isValidToken, validateTokenRequest] = useAPI(validateResetPasswordToken, params);
  const [result, {loading, error, execute}] = useAPI(resetPassword, {token: searchParams.get("t"), password},
                                                  {onResult: (({user})=>{user? setUser(user): null}),
                                                  immediate: false});
  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (validateTokenRequest.loading){
    return <CenterViewLayout><Spinner/></CenterViewLayout>
  }

  if (!isValidToken && !validateTokenRequest.loading){
    return <CenterViewLayout>
                <div className='space-y-6'>
                    <div className='relative h-36 w-36 bg-orange-100 text-orange-700 p-8 rounded-full'>
                        <FiAlertTriangle className="w-full h-full"/>
                    </div>
                    <Heading title={t(`${namespace}.invalid-token.title`)} description={t(`${namespace}.invalid-token.description`)}/>
                    <Button color="inlineLink" size="link" to="/login">
                        {t("reset-password.back-to-login")} 
                    </Button>
                </div>
            </CenterViewLayout>
  }
  if (result && namespace==="activate-account"){
    // Show success message
    return <CenterViewLayout>
            <img src="/assets/logo_10.jpg" className=" mx-auto h-auto max-h-24 max-w-full"/>
            <Heading title={t(`${namespace}.success.title`)} description={t(`${namespace}.success.description`)}/>
            <Button block color="active" size="xl" to="/login">
                {t("activate-account.success.back-to-login")} 
            </Button>
          </CenterViewLayout>
  }
  if (result){
    return <Navigate to="/" state={result}/>
  }
  
  return <CenterViewLayout>
            <form className="space-y-6" onSubmit={onSubmit}>
            <img src="/assets/logo_10.jpg" className=" mx-auto h-auto max-h-24 max-w-full"/>
            <Heading title={t(`${namespace}.title`)} description={t(`${namespace}.description`)}/>
            <TextInput 
                label={t("reset-password.password.label")}
                type="password" 
                hideEyeOnPassword={false}
                required
                errorOnBlur={(val)=>(!validatePassword(val)? t("reset-password.password.error"):null)}
                onChange={setPassword}/>
            <TextInput 
                label={t("reset-password.password2.label")} 
                type="password" 
                required
                hideEyeOnPassword={false}
                errorOnBlur={(val)=>((val && val!==password)? t("reset-password.password2.error"):null)}
                onChange={setPassword2} />
            {namespace==="reset-password" && <Button color="inlineLink" size="link" to="/login">
                {t("reset-password.back-to-login")} 
            </Button>}
            <Button disabled={!password || !password2 || (password!==password2) || !validatePassword(password)} color="active" block size="xl" loading={loading}  type="submit">
            {t(`${namespace}.submit`)}
            </Button>
            {error? <Error.Text {...error}/>: null}
            </form>
         </CenterViewLayout>
}
