import React from 'react';

// Hooks
import { useMemo } from 'react';
import { useAuth } from "hooks/useAuth";
import { useTranslation } from 'react-i18next';

// Components
import { LockClosedIcon, AcademicCapIcon, ClockIcon, BookOpenIcon, IdentificationIcon } from '@heroicons/react/24/outline';
import { SubNavCategory, SubNavItem } from 'components/core/sub-nav';

export function Nav() {
    const { t } = useTranslation("settings");
    const { user } = useAuth();

    const routesSecurity = useMemo(() => [
        {
            path: "/settings/security",
            Icon: LockClosedIcon,
            label: t("nav.security"),
        }
    ], []);

    const routesPreferences = useMemo(() => [
        {
            path: "/settings/info",
            Icon: IdentificationIcon,
            label: t("nav.info"),
        },
        {
            path: "/settings/availabilities",
            Icon: ClockIcon,
            label: t("nav.availabilities"),
        },
        {
            path: "/settings/schools",
            Icon: AcademicCapIcon,
            label: t("nav.school"),
        },
        {
            path: "/settings/subjects",
            Icon: BookOpenIcon,
            label: t("nav.subjects"),
        }        

    ], []);

    return <div className='p-3'>

        <SubNavCategory className="" title={t('nav.categories.portal')}>
            {routesSecurity.map((d) => <SubNavItem key={d.label} label={d.label} to={d.path} Icon={d.Icon} />)}
        </SubNavCategory>
        {
            user.role == "tutor" && <SubNavCategory className="mt-5" title={t('nav.categories.preferences')}>
                {routesPreferences.map((d) => <SubNavItem key={d.label} label={d.label} to={d.path} Icon={d.Icon} />)}
            </SubNavCategory>
        }


    </div>
}