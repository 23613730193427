import React from 'react';

// Components
import { CompactCalendarMonth } from 'components/calendar/compact_month';

// Hooks
import { useMemo, useState, useEffect, useCallback } from 'react';

// Utils
import classnames from 'classnames';
import { timeMonths, timeMonth } from 'd3-time';

const layouts = {
  "side": "grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-12 mx-auto",
  "full": "grid grid-cols-1 ",
  "flex": "flex justify-between items-start max-w-full ",
  "dropdown": "grid grid-cols-2 gap-6 ",
  "year": "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-12",
  "stacked": "grid grid-cols-1 gap-x-8 gap-y-12 mx-auto max-w-2xl ",
}

export function MonthView({date, events, interval, onDateChange, layout, minDateSelection, maxDateSelection, numMonths, onClick, sparkline, className}){
  const [leftDate, setLeftDate] = useState(date || new Date());

  const months = useMemo(()=>{
    return timeMonths(timeMonth.floor(leftDate), timeMonth.offset(timeMonth.floor(leftDate), numMonths==null? 2:numMonths));
  }, [leftDate])

  const handleDateChange = useCallback((offset)=>{
    setLeftDate(d=>timeMonth.offset(d, offset));
  }, []);

  useEffect(()=>{
    if (onDateChange) onDateChange(leftDate);
  }, [leftDate])

  return <div className={classnames(layouts[layout || "side"], className)}>
          {months.map((d,i)=><CompactCalendarMonth key={i} 
                              date={d} 
                              events={events} 
                              interval={interval}
                              onClick={onClick}
                              minDateSelection={minDateSelection}
                              maxDateSelection={maxDateSelection}
                              className=""
                              sparkline={sparkline}
                              onPreviousMonth={i===0? (e)=>{e.preventDefault(); handleDateChange(-1)}: null}
                              onNextMonth={i===months.length-1? (e)=>{e.preventDefault();handleDateChange(1)}: null} />)}
          </div>
}
