import React from 'react';

// Utils
import classnames from "classnames";

const sizes = {
    "xs": "px-2 py-2.5",
    "sm": "px-4 py-1.5 w-full",
    "md": "px-4 py-3 pr-12",
}

const activeStyles = {
    "outline" : "ring-[2pt] bg-orange-50 ring-orange-500",
    "activeFilled" : "ring-0 border-orange-300 bg-orange-50 !text-orange-500 hover:!text-orange-800 group-hover:!text-orange-800 hover:bg-orange-200 group-hover:bg-orange-200"
}

const styles = {
    "outline" : "border-gray-300 ring-1 focus:ring-[2pt] border-0 shadow-sm ring-gray-300",
    "activeFilled" : ""
}


export function TextInputTrailing({ id, label, trailingText, placeholder, value, onChange, onBlur, size = "md", color="outline", inputClassName, active, ...props}){
    let defaultStyles = "block w-full font-medium text-slate-700 border-gray-200 leading-6 text-sm rounded-md placeholder:text-gray-400 focus:border-orange-500 focus:ring-orange-500 focus:border-0 focus:ring-2 focus:outline-none ";

    return <>

          <div className="relative flex rounded-md group">
            <label htmlFor={id} className="absolute text-gray-900 -top-7">
              {label}
            </label>
            <input
                {...props}
              type="text"
              name={id}
              id={id}
              onBlur={ (e)=> onBlur && onBlur(e.target.value)}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              className={classnames(sizes[size], styles[color],active && activeStyles[color], defaultStyles, inputClassName)}
              placeholder={placeholder}
              aria-describedby="length-unit"
            />
            <div className="absolute inset-y-0 right-0 flex items-center h-full pr-3 pointer-events-none">
              <span className={classnames(active && activeStyles[color],"text-gray-500 sm:text-sm !ring-0")} id="length-unit">
                {trailingText}
              </span>
            </div>
          </div>
    </>
  }