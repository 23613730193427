import React from "react";

// Components
import { Hero } from 'components/core/typo';
import { Button } from "components/core/button";
import { ModalBasic, Modal } from "components/core/modal";

// Utils
import { getCurrentPeriod } from "utils/period";

// Icons
import { FiBell as BellIcon } from "react-icons/fi";

// API
import { tutorReinscription } from "api/tutor";

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useAPI } from "hooks/useAPI";

export function TutorAccountActivation({onActivation}) {
    const { t } = useTranslation("common");
    const period = getCurrentPeriod();
    const [open, setOpen] = useState(false);
    const [,{execute, loading}] = useAPI(tutorReinscription, {period},{immediate:false, onResult:onActivation});
    return <div className="py-12">
        <Hero 
            className={"whitespace-pre-wrap"}
            title={t("tutor-activation-status.title", {period})}
            subtitle={t("tutor-activation-status.subtitle", {period})}
            description={t("tutor-activation-status.description", {period})}
        />
        <div className="flex justify-center mt-5">
            <Button onClick={()=> setOpen(true)} color="active" size="md">{t("tutor-activation-status.activate", {period})}</Button>
        </div>
        <Modal open={open} setOpen={setOpen} >
            <ModalBasic 
                Icon={BellIcon} 
                description={t("tutor-activation-status.confirm", {period})} 
                submitLabel={t("tutor-activation-status.confirm-button")}
                onClick={execute}
                loading={loading}
                disabled={loading}
            />
        </Modal>
    </div>;
}