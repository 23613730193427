
import React, { useContext} from 'react'
import { EmptyState } from 'components/core/empty';
import { Skeleton } from 'components/core/skeleton';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom'
import { locale, parseWithTimeZone } from 'utils/locale';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { Button } from 'components/core/button';
import { FiList, FiX } from 'react-icons/fi';
import classNames from 'classnames';
import { EventsContext, EventsContextProvider } from 'contexts/events';

function EventsList(){
    const {t} = useTranslation();
    const {events, markAsRead, loading} = useContext(EventsContext);
    const pastEvents = (events && events.filter(e => new Date(e.date) < new Date()));
    const upcomingEvents = (events && events.filter(e => new Date(e.date) >= new Date()));
    return <div className=' hidden md:block md:w-1/4 md:min-w-[300px] max-w-[350px] border-r h-full overflow-auto relative'>
        <h2 className='text-lg font-semibold p-2'>{t("play-time.events-list")}</h2>
        {loading && <>
            <Skeleton className='h-8 my-2 mx-2'/>
            <Skeleton className='h-8 my-2 mx-2'/>
            <Skeleton className='h-8 my-2 mx-2'/>
        </>}
        {events  && events.length===0 && <EmptyState
            title={t("play-time.empty.title")}
            description={t("play-time.empty.description")}
            />}

        {upcomingEvents && <div className='sticky top-0 bg-gray-200 px-3 h-8 border-y flex items-center'>{t("events.upcoming")}</div>}
        {(upcomingEvents||[]).map((c) => 
        <NavLink to={`/play-time/${c.id}`} key={c.id} 
        onClick={()=>markAsRead(c.id)}
        className={({ isActive}) =>
            [
              "w-full transition-all border-b  p-2 flex items-center justify-between",
              c.has_unseen_messages && "font-semibold bg-orange-50",
              isActive ? "bg-gray-100 hover:bg-gray-50 " : "hover:bg-gray-100",
            ].join(" ")
          }>
            <div className='w-full'>

                <div className='flex items-center justify-between'>
                    <p className='text-black'>{locale.format("%d %B %Y")(parseWithTimeZone(c && c.date))}</p>
                    {c.conversation && c.transmitted?<p className='text-blue-500'>{t("is-transmitted")}</p>:
                        // <p className='text-sm bg-yellow-100 rounded-md px-2 text-yellow-800'>{t("not-transmitted")}</p>
                        null
                    }
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-sm'>{c && c.event_type && c.event_type.name}</p>
                    <div className='flex items-center justify-end gap-2'>
                    <p className={classNames('text-sm', c.has_unseen_messages? "text-black": " text-gray-500")}>{c && c.team && c.team.name}</p>
                    {c.has_unseen_messages&& <div className='rounded-full h-2 w-2 bg-orange-500'/>}
                    </div>
                </div>
            </div>
        </NavLink>)}
        {pastEvents && <div className='sticky top-0 bg-gray-200 px-3 h-8 border-y flex items-center'>{t("events.past")}</div>}
        {(pastEvents||[]).map((c) => 
        <NavLink to={`/play-time/${c.id}`} key={c.id} 
        onClick={()=>markAsRead(c.id)}
        className={({ isActive}) =>
            [
              "w-full transition-all border-b  p-2 flex items-center justify-between",
              c.has_unseen_messages && "font-semibold bg-orange-50",
              isActive ? "bg-gray-100 hover:bg-gray-200 " : "hover:bg-gray-100",
            ].join(" ")
          }>
            <div className='w-full'>

                <div className='flex items-center justify-between'>
                    <p className='text-black'>{locale.format("%d %B %Y")(parseWithTimeZone(c && c.date))}</p>
                    {c.conversation && c.transmitted?<p className='text-blue-500'>{t("is-transmitted")}</p>:
                        // <p className='text-sm bg-yellow-100 rounded-md px-2 text-yellow-800'>{t("not-transmitted")}</p>
                        null
                    }
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-sm'>{c && c.event_type && c.event_type.name}</p>
                    <div className='flex items-center justify-end gap-2'>
                    <p className={classNames('text-sm', c.has_unseen_messages? "text-black": " text-gray-500")}>{c && c.team && c.team.name}</p>
                    {c.has_unseen_messages&& <div className='rounded-full h-2 w-2 bg-orange-500'/>}
                    </div>
                </div>
            </div>
        </NavLink>)}
    </div>
}

function EventsListMobile(){
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const {events, markAsRead, loading} = useContext(EventsContext);
    const pastEvents = (events && events.filter(e => new Date(e.date) < new Date()));
    const upcomingEvents = (events && events.filter(e => new Date(e.date) >= new Date()));
    return <>
    <div className='block md:hidden p-3'>
        <Button color={"default"} size="md" onClick={() => setIsOpen(true)}><FiList className='mr-2'/> {t("play-time.open-dialog")}</Button>
    </div>
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-4xl bg-white w-full rounded border shadow relative overflow-auto max-h-[calc(100vh-100px)]">
            <div className='bg-gray-100 px-2 py-1 border-b font-medium flex items-center justify-between sticky top-0 w-full'>
                <p>{t("play-time.select")}</p>
                <FiX className='float-right cursor-pointer' onClick={() => setIsOpen(false)}/>
            </div>
            {loading && <>
            <Skeleton className='h-8 my-2 mx-2'/>
            <Skeleton className='h-8 my-2 mx-2'/>
            <Skeleton className='h-8 my-2 mx-2'/>
        </>}
        {events  && events.length===0 && <EmptyState
            title={t("play-time.empty.title")}
            description={t("play-time.empty.description")}
            />}

        {upcomingEvents && <div className='sticky top-0 bg-gray-200 px-3 h-8 border-y flex items-center'>{t("events.upcoming")}</div>}
        {(upcomingEvents||[]).map((c) => 
        <NavLink to={`/play-time/${c.id}`} key={c.id} 
        onClick={()=>markAsRead(c.id)}
        className={({ isActive}) =>
            [
              "w-full transition-all border-b  p-2 flex items-center justify-between",
              c.has_unseen_messages && "font-semibold bg-orange-50",
              isActive ? "bg-gray-100 hover:bg-gray-50 " : "hover:bg-gray-100",
            ].join(" ")
          }>
            <div className='w-full'>

                <div className='flex items-center justify-between'>
                    <p className='text-black'>{locale.format("%d %B %Y")(parseWithTimeZone(c && c.date))}</p>
                    {c.conversation && c.transmitted?<p className='text-blue-500'>{t("is-transmitted")}</p>:
                        // <p className='text-sm bg-yellow-100 rounded-md px-2 text-yellow-800'>{t("not-transmitted")}</p>
                        null
                    }
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-sm'>{c && c.event_type && c.event_type.name}</p>
                    <div className='flex items-center justify-end gap-2'>
                    <p className={classNames('text-sm', c.has_unseen_messages? "text-black": " text-gray-500")}>{c && c.team && c.team.name}</p>
                    {c.has_unseen_messages&& <div className='rounded-full h-2 w-2 bg-orange-500'/>}
                    </div>
                </div>
            </div>
        </NavLink>)}
        {pastEvents && <div className='sticky top-0 bg-gray-200 px-3 h-8 border-y flex items-center'>{t("events.past")}</div>}
        {(pastEvents||[]).map((c) => 
        <NavLink to={`/play-time/${c.id}`} key={c.id} 
        onClick={()=>markAsRead(c.id)}
        className={({ isActive}) =>
            [
              "w-full transition-all border-b  p-2 flex items-center justify-between",
              c.has_unseen_messages && "font-semibold bg-orange-50",
              isActive ? "bg-gray-100 hover:bg-gray-200 " : "hover:bg-gray-100",
            ].join(" ")
          }>
            <div className='w-full'>

                <div className='flex items-center justify-between'>
                    <p className='text-black'>{locale.format("%d %B %Y")(parseWithTimeZone(c && c.date))}</p>
                    {c.conversation && c.transmitted?<p className='text-blue-500'>{t("is-transmitted")}</p>:
                        // <p className='text-sm bg-yellow-100 rounded-md px-2 text-yellow-800'>{t("not-transmitted")}</p>
                        null
                    }
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-sm'>{c && c.event_type && c.event_type.name}</p>
                    <div className='flex items-center justify-end gap-2'>
                    <p className={classNames('text-sm', c.has_unseen_messages? "text-black": " text-gray-500")}>{c && c.team && c.team.name}</p>
                    {c.has_unseen_messages&& <div className='rounded-full h-2 w-2 bg-orange-500'/>}
                    </div>
                </div>
            </div>
        </NavLink>)}
        </DialogPanel>
        </div>
    </Dialog>
  </>
}
function ConversationsListPage() {
    return <EventsContextProvider>
            <div className='h-[calc(100vh)] w-full overflow-hidden md:flex  '>
                <EventsList/>
                <EventsListMobile/>
                <Outlet/>
                </div>
        </EventsContextProvider>
}
export {ConversationsListPage}