// @flow
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { AuthContextProvider } from 'contexts/auth';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
// Style
import "./index.scss";

// Translations
import "./i18n"

const helmetContext = {};

import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a987e863e73d4c9a9768fc22d605374c@o4505568103038976.ingest.sentry.io/4505568104939520",
  });
}

import ReactGa from 'react-ga4'

ReactGa.initialize("GTM-K7VQPHT")

const container = document.getElementById("root");
const root = createRoot(container); 
if (container) {
  root.render(
    // <React.StrictMode>
        <BrowserRouter>
          <HelmetProvider context={helmetContext}>
            <AuthContextProvider>
              <App/>
            </AuthContextProvider>
          </HelmetProvider>
        </BrowserRouter>
    // </React.StrictMode>
  )
} else {
  throw new Error("Could not find root element to mount to!");
}

