import React, {  useMemo, useState } from 'react';

// Components
import { SelectWeeklyAvailabilities } from 'components/tutor-form/select-dispo';
import Markdown from 'markdown-to-jsx';
import { Error } from 'components/core/typo';
import { SelectQuestion } from 'components/tutor-form/select-extra';

// Utils
import { options } from 'mdx';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useTranslation, Trans } from 'react-i18next';

// Const
import { retrieveTutor, updateTutor } from 'api/tutor';
import { Skeleton } from 'components/core/skeleton';

const markdown = 
`Vous pouvez inscrire des disponibilités entre 11h00 et 13h15 et entre 15h45 et 20h. **Nous n'offrons pas le tutorat en ligne le midi** (entre 11h00 et 13h15). Les séances en ligne sont uniquement faites après l'école entre 15h45 et 20h.

Les disponibilités des élèves-athlètes varient d'une école à l'autre. Voici les disponibilités des élèves-athlètes selon l'école:

| École     | Midi        | Soir   | 
|------|---------------|---------------|
| Vanier | 11h30 à 12h30 |15h45 à 20h00 |
| Camaradière | 12h00 à 13h00 | 16h15 à 20h00 |
| Charlesbourg | 12h15 à 13h15 | 16h30 à 20h00 |
| Roger-Comtois | 12h15 à 13h15 | 16h30 à 20h00 |
| Pointe-Lévy | 11h00 à 13h15 | 16h30 à 20h00 |

`


export function SelectDispoWithContext({defaultValue}){
    const [value, setValue] = useState(defaultValue);

    const onSetValue = (scheduleAvailabilities)=>{
        setValue(scheduleAvailabilities);
        updateTutor({scheduleAvailabilities});
    }

    return <>
            <Markdown options={options}>{markdown}</Markdown>
            <SelectWeeklyAvailabilities value={value} setValue={onSetValue}/>
       </>
}

export function SelectDispo(){
    const fields = useMemo(()=>({fields: ["ni", "schedule_availabilities"]}), []);
    const [tutor, {loading, error}] = useAPI(retrieveTutor, fields);
    return <div>
            {!tutor && loading && <Skeleton className={"h-42"}/>}
            {tutor && <SelectDispoWithContext defaultValue={tutor.schedule_availabilities}/>}
            {error && <Error.Text {...error}/>}
        </div>
}

export function SelectDispoFormat(){
    const { t } = useTranslation("tutor-form");
    const fields = useMemo(()=>({fields: ["ni", "online_preference", "on_site_preference", "hybrid_preference"]}), []);
    const [tutor, {error}] = useAPI(retrieveTutor, fields);
    const [value, setValue] = useState("");
    const options = t("prefered-session-format.options");
    useMemo(()=>{
        if (tutor && tutor.online_preference) {
            setValue(options.find((d)=>d.slug === "online"))
            return
        }
        else if (tutor && tutor.on_site_preference){
            setValue(options.find((d)=>d.slug === "person"))
            return
        }
        else if (tutor && tutor.hybrid_preference){
            setValue(options.find((d)=>d.slug === "hybrid"))
            return
        }
    }, [tutor]);
    
    const handleChange = (v) => {
        if (!v || !v.slug) return;        
        updateTutor({preferedFormat: v.slug});
        setValue(v);
    }
    
    return <>
        <SelectQuestion
            className="pt-4 mb-10"
            value={value}
            setValue={handleChange}
            label={<Trans t={t} i18nKey="prefered-session-format.label" components={{ul: <ul className='list-disc '/>, li: <li className='mb-1.5 first:mt-1.5 ml-4'/> ,b:<b/>}}/>}
            id="prefered-session-format"/>
        { error && <Error.Text {...error}/>}
    </>
}